import React from "react";
import { Badge, Modal, Button, ModalHeader, ModalFooter, ModalBody, FormGroup, Input } from "reactstrap";

export default class Step0 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      modalProposal: false,
      tab0: true,
      step0: false,
    };
    this.toggleProposal = this.toggleProposal.bind(this);
  }

  componentDidMount() {
    this.setState({
      tab0: this.props.tab0,
      step0: this.props.step0,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tab0: nextProps.tab0,
      step0: nextProps.step0,
    });
  }

  toggleProposal() {
    this.setState((prevState) => ({
      modalProposal: !prevState.modalProposal,
    }));
  }

  render() {
    return (
      <div className="card">
        <div className="card-header" id="heading0">
          <h5 className="mb-0">
            <Badge color={this.state.step0 ? "primary" : "danger"} pill>
              &#10148;
            </Badge>{" "}
            <button
              className={this.state.step0 ? "btn btn-link text-primary" : "btn btn-link text-danger"}
              type="button"
              data-toggle="collapse"
              data-target="#collapse0"
              aria-expanded="true"
              aria-controls="collapse0"
              onClick={() => {
                this.setState({
                  tab0: !this.state.tab0,
                });
              }}
            >
              {this.props.list.mediaplan.media_type != "sponsor" ? (
                <span>Подготовить рекламные ролики</span>
              ) : (
                <span>Подготовить спонсорские материалы - заполните заявку</span>
              )}
            </button>
          </h5>
        </div>
        <div
          id="collapse1"
          className={!this.state.tab0 ? "collapse show" : "collapse"}
          aria-labelledby="heading0"
          data-parent="#accordion0"
        >
          <div className="card-body">
            {this.props.list.mediaplan.media_type != "sponsor" ? (
              <div>
                <b>Есть ли у вас рекламные ролики?</b> Если нет, то мы{" "}
                <a href="/static/pdf/video_howto.pdf" target="_blank" rel="noopener noreferrer">
                  поможем создать ролики профессионального качества.
                </a>
                <p>
                  Если есть готовые ролики – проверьте их на соответствие техническим требованиям и переходите к
                  следующему шагу. Для расчёта бюджета кампании важен только хронометраж предполагаемого к размещению
                  ролика: количество секунд в поле ниже.
                </p>
              </div>
            ) : (
              <div>
                <p>Отправьте нам: логотип, слоган, образцы корпоративных цветов и то, что сочтете важным.</p>
                <p>
                  Изготовление рекламных материалов с учетом законодательных, технических и других требований мы берем
                  на себя.
                </p>
              </div>
            )}
            <p className="pt-2">
              <Button color="success" onClick={this.toggleProposal} size="sm">
                Если у вас нет ролика, мы поможем вам его создать
              </Button>
            </p>
            <Modal isOpen={this.state.modalProposal} toggle={this.toggleProposal}>
              <ModalHeader toggle={this.toggleProposal}>Нужна помощь в создании ролика?</ModalHeader>
              <ModalBody>
                {this.props.list.mediaplan.media_type == "sponsor" ? (
                  <p>
                    <p>
                      Оставьте вашу контактную информацию, и мы поможем вам создать качественные рекламные материалы для
                      вашей спонсорской кампании.
                    </p>
                    <ul>
                      <li>
                        Email: <a href="mailto:buyads.online.team@gmail.com">buyads.online.team@gmail.com</a>
                      </li>
                      <li>
                        WhatsApp: <a href="https://wa.me/77771234567">https://wa.me/77771234567</a>
                      </li>
                    </ul>
                  </p>
                ) : (
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="text"
                      onChange={(event) => {
                        this.setState({
                          description: event.target.value,
                        });
                      }}
                      style={{
                        height: "100px",
                      }}
                      id="descriptionText"
                      placeholder="Укажите Ваше имя и номер телефона, по которому мы сможем связаться с Вами по поводу создания ролика для Вашей рекламной кампании."
                    />
                  </FormGroup>
                )}
              </ModalBody>
              {this.props.list.mediaplan.media_type != "sponsor" ? (
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      var forms = {
                        description: this.state.description,
                      };
                      this.props.onSetProposal(this.props.list.mediaplan.id, forms);
                      this.toggleProposal();
                    }}
                  >
                    Отправить менеджеру
                  </Button>{" "}
                </ModalFooter>
              ) : null}
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
