import React from "react";
import { Field, reduxForm } from "redux-form";
import { reactLocalStorage } from "reactjs-localstorage";
import { browserHistory } from "react-router";
import { Card, Col, Row, CardTitle, CardBody, Button, FormGroup, Label, Input } from "reactstrap";
import { NotificationManager } from "react-notifications";

import API from "../../api/list";
import { renderField } from "../../components/renderField";

class LoginForm extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      password: false,
      save: false,
      email: false,
    };
    this.initState = this.initState.bind(this);
  }

  initState() {
    this.setState({
      loading: false,
      password: false,
      email: false,
    });
  }

  componentDidMount() {
    if (reactLocalStorage.get("save") != undefined) {
      browserHistory.push("/mediaplans");
    }
  }

  submit(values) {
    this.setState({
      loading: true,
    });
    API.login(values).then((response) => {
      this.initState();
      switch (response.status) {
        case 200:
          response.json().then((data) => {
            reactLocalStorage.set("token", data.key);
            reactLocalStorage.set("username", data.user.username);
            if (this.state.save) {
              reactLocalStorage.set("save", this.state.save);
            }
            browserHistory.push("/mediaplans");
          }, this);
          break;
        case 400:
          response.json().then((data) => {
            Object.keys(data).forEach(function (element) {
              let obj = {};
              obj[element] = true;
              this.setState(obj);
              NotificationManager.error(data[element], "", 15000);
            }, this);
          }, this);
          break;
      }
    }, this);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Card className="mb-5">
        <CardBody>
          <Row>
            <Col>Вход в систему</Col>
            <Col className="text-right">
              Нет логина? <a href="/registration">Зарегистрируйтесь</a>
            </Col>
          </Row>
          <hr />
          <form onSubmit={handleSubmit(this.submit.bind(this))} noValidate>
            <Row>
              <Col className="text-center">
                <img width="300px" src="/static/images/style/sign-in.png" className="img-fluid" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="email"
                  type="email"
                  label="Email"
                  error={this.state.email}
                  component={renderField}
                  placeholder="Ваш Email"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="password"
                  type="password"
                  label="Пароль"
                  error={this.state.password}
                  component={renderField}
                  placeholder="Ваш пароль"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      defaultValue={this.state.save}
                      onChange={() => {
                        this.setState({
                          save: !this.state.save,
                        });
                      }}
                    />{" "}
                    Сохранить пароль
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <small>
                    Забыли пароль? <a href="/forget">Нажмите тут</a>
                  </small>
                </p>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <Button color="primary">Войти в систему</Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default reduxForm({
  form: "loginForm",
})(LoginForm);
