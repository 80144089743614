import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { NotificationContainer } from "react-notifications";

import Header from "../containers/header";

export default class ResetDoneScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid={true}>
        <NotificationContainer />
        <Header check={false} />
        <Row>
          <Col md="3" />
          <Col md="6" className="text-center">
            <Card>
              <CardBody>
                <CardTitle>Ваш пароль установлен</CardTitle>
                <div>
                  <img width="300px" src="/static/images/style/success.png" className="img-fluid" />
                </div>
                <Button href="/login" color="primary">
                  Войти в систему
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col md="3" />
        </Row>
      </Container>
    );
  }
}
