import React from "react";
import MaskedInput from "react-text-mask";
import Loadable from "react-loading-overlay";
import { Button, Modal, ModalHeader, ModalBody, Row, Col, ModalFooter } from "reactstrap";
import ToggleDisplay from "react-toggle-display";
import Toggle from "react-toggle";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";

import { mediaplanTypeFromObj } from "../mediaplanTypes";

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: "btn btn-success",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false,
});

export default class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "contract",
      modal: false,
      signature: null,
      signatureErrorDisplay: false,
      company: null,
      companyErrorDisplay: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    this.props.onGetCurrencies();
    this.props.onGetCountries();
    this.props.onGetOrder(this.props.mediaplan);
  }

  render() {
    const disabled = this.props.item.mediaplan.status_type !== 1 || this.props.loading;
    // TODO: check
    if (!this.props.item.mediaplan || !this.props.item.mediaplan.media_type) return <div></div>;
    const mediaplanType = mediaplanTypeFromObj(this.props.item);
    if (!mediaplanType.validToViewCheckoutPage()) {
      window.location.href = "/mediaplans";
    }

    const prevCompany = this.props.item.company || this.props.user.company;
    const company = typeof this.state.company === "string" ? this.state.company : prevCompany;
    const prevSignature = this.props.item.signature || this.props.user.signature;
    const signature = typeof this.state.signature === "string" ? this.state.signature : prevSignature;

    return (
      <Loadable className="pb-5" active={this.props.loading} spinner text="Загрузка данных">
        {this.props.finalizedOrder ? (
          <Row>
            <Col md="2" />
            <Col md="8" className="text-center">
              <h2>Ваш заказ оформлен успешно!</h2>
              <p>
                Выбранные Вами рекламные ресурсы зарезервированы на 72 часа, в течение которых вам необходимо произвести
                оплату.
              </p>
              {this.props.finalizedOrder.contractRenewed && (
                <p>
                  Ваш договор был автоматически продлён. Новая дата договора: {this.props.finalizedOrder.contract_date}
                </p>
              )}
              <Button color="primary" href="/mediaplans/">
                Вернуться в медиапланы
              </Button>
            </Col>
            <Col md="2" />
          </Row>
        ) : (
          <React.Fragment>
            {this.props.item.mediaplan ? (
              <Row>
                <Col md="12">
                  <Button color="secondary" outline={true} size="sm" href={mediaplanType.getLink()}>
                    &larr; Вернуться в медиаплан
                  </Button>
                </Col>
              </Row>
            ) : null}
            <hr />
            <div className="row pb-5">
              <div className="col-md-4 order-md-2 mb-4">
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-muted">Выбрано</span>
                </h4>
                <ul className="list-group mb-3">
                  {(mediaplanType.fullCheckoutData() || []).map(({ label, value, parameters, strong }, i) => (
                    <li key={i} className="list-group-item d-flex justify-content-between lh-condensed">
                      <div>
                        <h6 className="my-0">{strong ? <strong>{label}</strong> : label}</h6>
                        {(parameters || []).map(({ label }, j) => (
                          <div key={j}>
                            <small className="text-muted">{label}</small>
                          </div>
                        ))}
                      </div>
                      <span className="text-muted">
                        <h6 className="my-0">{strong ? <strong>{value || ""}</strong> : value || ""}</h6>
                        {(parameters || []).map(({ value }, j) => (
                          <div key={j}>
                            <small className="text-muted">{value}</small>
                          </div>
                        ))}
                      </span>
                    </li>
                  ))}
                </ul>
                <Button
                  className="w-100"
                  color="primary"
                  size="sm"
                  target="_blank"
                  outline={true}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.getDownloadToken({
                      path: `/order/invoice/${this.props.item.mediaplan.id}/`,
                      blank: true,
                    });
                  }}
                >
                  Скачать PDF файл {this.props.item.mediaplan.status_type === 1 && " (черновик)"}
                </Button>
              </div>
              <div className="col-md-8 order-md-1">
                <h2 className="text-right">
                  Номер медиаплана <span className="badge badge-secondary">{this.props.mediaplan}</span>
                </h2>
                <h4 className="mb-3">Ваши реквизиты:</h4>
                <div>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="resident">Страна резиденства</label>
                      <select
                        onChange={(event) => {
                          if (event.target.value == 1) {
                            var forms = {
                              resident: event.target.value,
                              exchange: 1,
                            };
                          } else {
                            var forms = {
                              resident: event.target.value,
                            };
                          }
                          forms.company = company;
                          forms.signature = signature;
                          this.props.onUpdateOrder(this.props.mediaplan, forms);
                        }}
                        defaultValue={this.props.item.resident}
                        name="resident"
                        disabled={this.props.item.status_type > 1 || this.props.item.mediaplan.media_type === "outdoor"}
                        className="custom-select d-block w-100"
                        id="resident"
                      >
                        {this.props.countries.map((country, i) => {
                          return (
                            <option selected={this.props.item.resident == country.value} value={country.value}>
                              {country.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="currency">Валюта сделки</label>
                      <select
                        onChange={(event) => {
                          var forms = {
                            exchange: event.target.value,
                          };
                          forms.company = company;
                          forms.signature = signature;
                          this.props.onUpdateOrder(this.props.mediaplan, forms);
                        }}
                        className="custom-select d-block w-100"
                        id="currency"
                        disabled={
                          this.props.item.resident === 1 ||
                          this.props.item.get_stats.status_type > 1 ||
                          (this.props.currencies.length === 1 && this.props.item.get_exchange.currency)
                        }
                      >
                        {this.props.currencies.map((item, i) => {
                          return (
                            <option selected={this.props.item.get_exchange.currency == item.currency} value={item.id}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3" />
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="firstName">
                        <strong>
                          Плательщик <span className="text-danger">*</span>
                        </strong>
                      </label>
                      <input
                        disabled={disabled}
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="company"
                        placeholder=""
                        onChange={(e) => {
                          this.setState({ company: e.target.value });
                        }}
                        onBlur={(_) => {
                          /*if (company === prevCompany) return;
                          this.props.onUpdateOrder(this.props.mediaplan, {
                            company
                          });*/
                        }}
                        value={company}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="signature">
                        <strong>
                          ФИО руководителя <span className="text-danger">*</span>
                        </strong>
                      </label>
                      <input
                        disabled={disabled}
                        type="text"
                        className="form-control"
                        id="signature"
                        name="signature"
                        onChange={(e) => {
                          this.setState({ signature: e.target.value });
                        }}
                        onBlur={(_) => {
                          /*if (signature === prevSignature) return;
                          this.props.onUpdateOrder(this.props.mediaplan, {
                            signature
                          });*/
                        }}
                        value={signature}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="numberContract">Номер договора</label>
                      <input
                        type="text"
                        className="form-control disabled"
                        id="numberContract"
                        disabled={true}
                        defaultValue={this.props.user.contract_id}
                      />
                      <input type="hidden" name="contract" value={this.props.item.contract_id} />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="contractDate">Дата договора</label>
                      <input
                        type="text"
                        className="form-control disabled"
                        id="contractDate"
                        disabled={true}
                        defaultValue={this.props.item.contract_date}
                      />
                      <input type="hidden" name="contractdate" value={this.props.item.contract_date} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6  mb-3">
                      <label htmlFor="email">
                        Email <span className="text-muted">(необязательное поле)</span>
                      </label>
                      <input
                        disabled={disabled}
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        onChange={(event) => {
                          this.props.item.email = event.target.value;
                        }}
                        defaultValue={this.props.item.email}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="phone">Телефон</label>
                      <MaskedInput
                        disabled={disabled}
                        mask={[
                          "+",
                          /\d/,
                          " ",
                          "(",
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ")",
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        className="form-control"
                        placeholder=""
                        guide={false}
                        onChange={(event) => {
                          this.props.item.phone = event.target.value;
                        }}
                        defaultValue={this.props.item.phone}
                        id="phone-input-id"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="state">Город</label>
                      <input
                        type="text"
                        disabled={disabled}
                        className="form-control"
                        placeholder=""
                        onChange={(event) => {
                          this.props.item.city = event.target.value;
                        }}
                        defaultValue={this.props.item.city}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="zip">Индекс</label>
                      <input
                        type="text"
                        disabled={disabled}
                        className="form-control"
                        id="zip"
                        placeholder=""
                        onChange={(event) => {
                          this.props.item.zip = event.target.value;
                        }}
                        defaultValue={this.props.item.zip}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="address">Адрес</label>
                      <input
                        type="text"
                        disabled={disabled}
                        className="form-control"
                        id="address"
                        placeholder=""
                        onChange={(event) => {
                          this.props.item.address = event.target.value;
                        }}
                        defaultValue={this.props.item.address}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="address2">
                      Продолжение адреса <span className="text-muted">(необязательное поле)</span>
                    </label>
                    <input
                      type="text"
                      disabled={disabled}
                      className="form-control"
                      id="address2"
                      placeholder=""
                      defaultValue={this.props.item.address2}
                      onChange={(event) => {
                        this.props.item.address2 = event.target.value;
                      }}
                    />
                  </div>
                  <hr className="mb-4" />
                  <h4 className="mb-3">Оплата</h4>
                  <div className="d-block my-3">
                    <div className="custom-control custom-radio">
                      <input
                        id="debit"
                        disabled={disabled}
                        name="paymentMethod"
                        type="radio"
                        onClick={() => {
                          this.setState({
                            type: "contract",
                          });
                        }}
                        checked={this.state.type == "contract"}
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="debit">
                        Безналичный расчет
                      </label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="contract">Налоговые и банковские реквизиты</label>
                    <input
                      type="text"
                      disabled={disabled}
                      onChange={(event) => {
                        this.props.item.tax = event.target.value;
                      }}
                      defaultValue={this.props.item.tax}
                      className="form-control"
                      id="contract"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="contract">Дополнительно</label>
                    <input
                      type="text"
                      disabled={disabled}
                      onChange={(event) => {
                        this.props.item.additionally = event.target.value;
                      }}
                      defaultValue={this.props.item.additionally}
                      className="form-control"
                      id="contract"
                    />
                  </div>
                  {this.props.item.mediaplan ? (
                    <ToggleDisplay show={!this.props.item.mediaplan.order_submitted}>
                      {this.props.item.mediaplan.get_ready_for_order ? (
                        <label>
                          <Toggle
                            defaultChecked={this.props.item.get_user.user_agreements}
                            onChange={() => {
                              this.props.onLoading();
                              this.props.onUpdateProfile(this.props.mediaplan, {
                                user_agreements: !this.props.item.get_user.user_agreements,
                              });
                            }}
                          />
                          <Button
                            onClick={() => {
                              this.setState({
                                modal: true,
                              });
                            }}
                            style={{
                              marginTop: "-15px",
                            }}
                            color="link"
                          >
                            Пользовательское соглашение
                          </Button>
                        </label>
                      ) : null}
                      {this.props.item.mediaplan.get_ready_for_order ? (
                        <div>
                          <div>
                            <button
                              disabled={!this.props.item.get_user.user_agreements}
                              onClick={() => {
                                let error = false;
                                if (!company) {
                                  NotificationManager.error("Поле «Плательщик» обязательно для заполнения", "", 5000);
                                  error = true;
                                }
                                if (!signature) {
                                  error = true;
                                  NotificationManager.error(
                                    "Поле «ФИО руководителя» обязательно для заполнения",
                                    "",
                                    5000
                                  );
                                }
                                if (error) return;
                                this.props.onLoading();
                                this.props.onFinalizeOrder(this.props.mediaplan, {
                                  ...this.props.item,
                                  company,
                                  signature,
                                });
                              }}
                              className="btn btn-primary btn-lg btn-block"
                            >
                              Оформить заказ
                            </button>
                          </div>
                          <div className="pt-5">
                            <button
                              onClick={() => {
                                swalWithBootstrapButtons
                                  .fire({
                                    title: "Вы уверены?",
                                    text: "Эта операция необратима",
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Да!",
                                    cancelButtonText: "Нет",
                                    reverseButtons: true,
                                  })
                                  .then((result) => {
                                    if (result.value) {
                                      this.props.onDeleteMediaplan(this.props.item.mediaplan.id);
                                      swalWithBootstrapButtons.fire(
                                        "Успешное завершение",
                                        "Ваш медиаплан был отменен",
                                        "success"
                                      );
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      swalWithBootstrapButtons.fire(
                                        "Операция остановлена",
                                        "Ваш медиаплан в безопасности",
                                        "error"
                                      );
                                    }
                                  });
                              }}
                              className="btn btn-danger btn-lg btn-block"
                            >
                              Удалить
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => {
                              this.props.onLoading();
                              this.props.onUpdateOrder(this.props.mediaplan, this.props.item);
                            }}
                            className="btn btn-primary btn-lg btn-block"
                          >
                            Сохранить
                          </button>
                          <p className=" text-center">
                            <small className="text-muted">
                              Для оформления заказа необходимо, чтобы в медиаплане были выбраны выходы и ролики (только
                              на государственном языке, либо две языковые версии).
                            </small>
                          </p>
                        </div>
                      )}
                      {this.props.item.mediaplan.get_ready_for_order ? (
                        <p className=" text-center">
                          <small className="text-muted">
                            Внимание! После оформления заказа изменения в медиаплане не допускаются.
                          </small>
                        </p>
                      ) : null}
                    </ToggleDisplay>
                  ) : null}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle} className={this.props.className}>
              <ModalHeader toggle={this.toggle}>Пользовательское соглашение</ModalHeader>
              <ModalBody>
                Продолжая оформление заказа, вы подтверждаете, что ознакомлены со всеми пунктами Соглашения (
                <a href="/pages/legal/" target="_blank" rel="noopener noreferrer">
                  полный текст здесь
                </a>
                ), и безусловно принимаете их.
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  Закрыть
                </Button>
              </ModalFooter>
            </Modal>
          </React.Fragment>
        )}
      </Loadable>
    );
  }
}
