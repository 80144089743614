import { LIST_MONTHS_AND_CHANNELS } from "./types";

import API from "../api/list";

export const getMonthsAndChannels = (callback) => {
  return (dispatch) => {
    API.getMonthsAndChannels().then(function (list) {
      if (callback) callback(list);
      dispatch(listMonthsAndChannels(list));
    });
  };
};

export const listMonthsAndChannels = (list) => ({
  type: LIST_MONTHS_AND_CHANNELS,
  payload: {
    list: list,
  },
});
