import React from "react";
import { browserHistory } from "react-router";
import { NotificationContainer, NotificationManager } from "react-notifications";
import {
  Row,
  Col,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Currency from "react-currency-formatter";
import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";
import Sidebar from "react-sidebar";
import { Slide } from "react-slideshow-image";
import moment from "moment";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  onChange: (oldIndex, newIndex) => {},
};

export default class Outdoor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      point: null,
      modal: false,
    };
  }

  componentDidMount() {
    this.props.onGetScrollReservation(this.props.mediaplan);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      point: !this.state.modal ? null : this.state.point,
    });
  }

  render() {
    const disabled = this.props.scroll_reservation.mediaplan_status_type !== 1;
    return (
      <div>
        <Row>
          <Col md="12">
            <Button color="secondary" outline={true} size="sm" href="/mediaplans">
              &larr; Вернуться в медиапланы
            </Button>
          </Col>
        </Row>
        {this.state.point ? (
          <Sidebar
            sidebar={
              <Container
                style={{
                  width: "500px",
                }}
              >
                <Row>
                  <Col className="p-3">
                    <Button
                      onClick={() => {
                        this.setState({
                          point: null,
                        });
                      }}
                      className="float-right"
                      outline
                    >
                      x
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <b>
                      #{this.state.point.nrr_id} - {this.state.point.district}, {this.state.point.address}
                    </b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ListGroup>
                      {this.state.point.get_images.length > 0 ? (
                        <ListGroupItem>
                          <Slide {...properties}>
                            {this.state.point.get_images.map((item, index) => {
                              return (
                                <div className="each-slide">
                                  <div
                                    style={{
                                      backgroundImage: `url(${item.image})`,
                                    }}
                                  ></div>
                                </div>
                              );
                            }, this)}
                          </Slide>
                        </ListGroupItem>
                      ) : null}
                      <ListGroupItem>Ориентация: {this.state.point.direction}</ListGroupItem>
                      <ListGroupItem>Размеры, м: {this.state.point.size} </ListGroupItem>
                      <ListGroupItem>{this.state.point.description ? this.state.point.description : "-"}</ListGroupItem>
                    </ListGroup>
                    <hr />
                    <b>Выберите число скроллов по месяцам:</b>
                    <Table>
                      {this.state.point.get_scrolls.map((scroll, index) => {
                        if (scroll.get_avalaible_scrolls.length == 0) {
                          return null;
                        }
                        if (moment(scroll.date_available).day() <= 25 && moment() < moment(scroll.date_available)) {
                          var scrolls = 0;
                        } else {
                          return null;
                        }
                        return (
                          <tr>
                            <td>
                              <div>{scroll.get_date}</div>
                            </td>
                            <td>
                              <select
                                name="scroll"
                                onChange={(event) => {
                                  scrolls = event.target.value;
                                }}
                              >
                                <option key={0} selected={true} value={0}>
                                  0
                                </option>
                                {scroll.get_avalaible_scrolls.map(function (i) {
                                  return (
                                    <option key={i} selected={i == scrolls ? true : false} value={i}>
                                      {i}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                              <Button
                                disabled={scroll.get_avalaible_scrolls.length == 0}
                                size="sm"
                                outline={true}
                                onClick={() => {
                                  if (parseInt(scrolls) > 0) {
                                    this.props.onAddToScrollReservation(this.props.mediaplan, {
                                      outdoor: this.state.point.id,
                                      scroll: scroll.id,
                                      scrolls: parseInt(scrolls),
                                    });
                                    NotificationManager.success("Спасибо!", "Ваша заявка добавлена");
                                    this.setState({
                                      point: null,
                                    });
                                  } else {
                                    NotificationManager.error("Ошибка!", "Должен быть выбран хотя бы один скролл");
                                  }
                                }}
                                color="primary"
                              >
                                Добавить
                              </Button>
                            </td>
                          </tr>
                        );
                      }, this)}
                    </Table>
                  </Col>
                </Row>
              </Container>
            }
            open={this.state.point}
            styles={{ sidebar: { background: "white" } }}
          ></Sidebar>
        ) : null}

        <YMaps>
          <Map
            defaultState={{ center: [43.2238689, 76.8403213], zoom: 12 }}
            style={{
              position: "absolute",
              left: 0,
              top: "140px",
              width: "100%",
              height: "calc(100vh - 140px)",
            }}
          >
            <Clusterer
              options={{
                preset: "islands#invertedRedClusterIcons",
                groupByCoordinates: false,
              }}
            >
              {this.props.list.map((item, index) => {
                return (
                  <Placemark
                    options={{
                      preset:
                        this.props.scroll_reservation.points.indexOf(item.id) >= 0
                          ? "islands#blueIcon"
                          : "islands#redIcon",
                    }}
                    geometry={[item.latitude, item.longitude]}
                    onClick={() => {
                      if (!disabled)
                        this.setState({
                          point: item,
                        });
                    }}
                  />
                );
              }, this)}
            </Clusterer>
          </Map>
        </YMaps>
        {this.props.scroll_reservation.data.length > 0 ? (
          <Button
            onClick={this.toggle.bind(this)}
            style={{
              zIndex: 999,
              position: "absolute",
              bottom: "100px",
              right: "15px",
            }}
            color="primary"
          >
            Выбрано: {this.props.scroll_reservation.data.length}
          </Button>
        ) : null}
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle.bind(this)}>
          <ModalHeader toggle={this.toggle.bind(this)}>Выбрано</ModalHeader>
          <ModalBody>
            <table className="table m-0">
              <tr>
                <th>ID</th>
                <th>Адрес</th>
                <th>Месяц</th>
                <th>Кол-во</th>
                <th style={{ width: "3rem" }} />
              </tr>
              {this.props.scroll_reservation.data.map((item, index) => {
                return (
                  <tr>
                    <td>{item.get_construction.id}</td>
                    <td>{item.get_construction.address}</td>
                    <td>{item.get_scroll.get_date}</td>
                    <td>{item.scrolls}</td>
                    <td>
                      <Button
                        disabled={disabled}
                        onClick={() => {
                          this.props.onDestroyScrollReservation(this.props.mediaplan, item.id);
                        }}
                        color="danger"
                        size="sm"
                        outline
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                );
              }, this)}
              <tr>
                <th colSpan="5">
                  <div className="d-flex align-items-center justify-content-end w-100">
                    <div className="mx-1">Итого к оплате:</div>
                    <Currency
                      disabled={this.props.scroll_reservation.data.length == 0}
                      quantity={parseInt(this.props?.scroll_reservation?.stats?.amount_to_pay_kzt)}
                      symbol="KZT"
                      currency="KZT"
                      locale="ru_RU"
                      group=" "
                    />
                  </div>
                </th>
              </tr>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={this.toggle.bind(this)}>
              Отмена
            </Button>
            <Button
              color="primary"
              onClick={() => {
                browserHistory.push(`/checkout/${this.props.mediaplan}`);
              }}
            >
              {!disabled ? "Перейти к оформлению" : "Посмотреть заказ"}{" "}
            </Button>
          </ModalFooter>
        </Modal>
        <NotificationContainer />
      </div>
    );
  }
}
