import React from "react";
import { Button } from "reactstrap";
import Select from "react-select";
import "moment/locale/ru";
import update from "immutability-helper";
import API from "./api/list";
import Swal from "sweetalert2";
import { Menu } from "./components/selectMedia";

const MAX_BANNERS = 10;

const validateImageFile = (input) => {
  return new Promise((resolve, reject) => {
    const URL = window.URL || window.webkitURL;
    const file = input.files[0];

    if (file) {
      const image = new Image();
      image.onload = function () {
        if (this.width) {
          //console.log('Image has width, I think it is real image');
          resolve(null);
        }
      };
      image.onerror = function () {
        resolve(`Файл ${file.name} не является изображением`);
      };
      image.src = URL.createObjectURL(file);
    }
  });
};

class InternetAdType {
  constructor(item, props) {
    this.instance = item;
    this.props = props;
    this.the_type = this.constructor.INTERNET_AD_TYPE;
  }
}

class Video extends InternetAdType {
  static MAX_MEDIA_PER_INTERNET_MEDIAPLAN = 5;
  static INTERNET_AD_TYPE = "video";

  static initialState() {
    return {
      media: [null],
    };
  }

  uploadComponent() {
    const that = this;
    return ({ state, setState, disabled, all_media, onSetMedia, params, onAddMedia }) => {
      const setMediaCallback = (resp) => setState(resp);
      const setMedia = (media, doUpdate) =>
        setState({ media }, (_) => doUpdate && onSetMedia(params.mediaplan, media, setMediaCallback));

      const all_media_values = all_media.map(({ id, title }) => {
        return { value: id, label: title };
      });

      return (
        <React.Fragment>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <h4 className="mb-0 mr-2">Выбор медиа</h4>
          </div>
          {state.media.map((theMedia, i) => {
            const getMedia = (m) =>
              typeof theMedia === "number" ? all_media_values.filter(({ value }) => m === value)[0] : m;
            const media = getMedia(theMedia);
            return (
              <div className="d-flex w-100 mb-2">
                <div className="mr-2 text-left" style={{ flex: 1 }}>
                  <Select
                    isDisabled={disabled}
                    className="w-100"
                    placeholder={`Выберите ролик из медиатеки`}
                    aria-label={`Выберите ролик из медиатеки`}
                    aria-labelledby={`Выберите ролик из медиатеки`}
                    noOptionsMessage={(inputValue) => "Нет роликов"}
                    value={media}
                    options={all_media_values.filter(
                      ({ value }) =>
                        (media && media.value === value) ||
                        !new Set(state.media.filter((x) => x).map(({ value }) => value)).has(value)
                    )}
                    onChange={(value) => setMedia(update(state.media, { [i]: { $set: getMedia(value) } }), true)}
                    components={{ Menu }}
                    onAdd={(event) => {
                      const inputElement = document.createElement("input");
                      inputElement.setAttribute("type", "file");
                      inputElement.click();
                      inputElement.addEventListener("change", function (e) {
                        that.props.onAddMedia(
                          {
                            mediaplan: params.mediaplan,
                            media_list: update(state.media, { [i]: { $set: "__new__" } }),
                            url: e.target.files[0],
                          },
                          setMediaCallback
                        );
                      });
                    }}
                  />
                </div>
                <Button
                  outline
                  disabled={disabled}
                  color="danger"
                  onClick={(_) => setMedia(update(state.media, { $splice: [[i, 1]] }), !!state.media[i])}
                >
                  <svg
                    style={{ width: 12, height: 12 }}
                    xmlns="http://www.w3.org/2000/svg"
                    ariaHidden="true"
                    className="svg-inline--fa fa-times fa-w-11"
                    data-icon="times"
                    data-prefix="fas"
                    viewBox="0 0 352 512"
                  >
                    <path
                      fill="currentColor"
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                    ></path>
                  </svg>
                </Button>
              </div>
            );
          })}
          {state.media.length < that.constructor.MAX_MEDIA_PER_INTERNET_MEDIAPLAN && (
            <Button
              outline
              color="primary"
              className="w-100"
              disabled={(state.media.length && !state.media[state.media.length - 1]) || disabled}
              onClick={(_) => setState({ media: [...state.media, null] })}
            >
              <svg
                style={{ width: 15, height: 15 }}
                xmlns="http://www.w3.org/2000/svg"
                ariaHidden="true"
                className="svg-inline--fa fa-plus fa-w-14"
                data-icon="plus"
                data-prefix="fas"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                ></path>
              </svg>
              &nbsp;Добавить
            </Button>
          )}
        </React.Fragment>
      );
    };
  }
}

class Banner extends InternetAdType {
  static INTERNET_AD_TYPE = "banner";

  static initialState() {
    return {
      banners: [],
    };
  }

  uploadComponent() {
    return ({ state, setState, disabled, all_media, onSetMedia, params, ...props }) => {
      return (
        <React.Fragment>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h4 className="mb-0 mr-2 mb-3">Выбор баннера</h4>
            <p>
              Отправьте нам готовый баннер или: логотип, слоган, образцы корпоративных цветов и то, что сочтете важным.
            </p>
            <p>
              Изготовление рекламных материалов с учетом законодательных, технических и других требований мы берем на
              себя.
            </p>
            <ul className="text-left">
              <li>
                Email: <a href="mailto:buyads.online.team@gmail.com">buyads.online.team@gmail.com</a>
              </li>
              <li>
                WhatsApp: <a href="https://wa.me/77013017618">https://wa.me/77013017618</a>
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-center align-items-center mb-4" style={{ flexWrap: "wrap" }}>
            {state?.banners.map(({ image, id }, i) => {
              const filename = window.decodeURIComponent(image.split("/")[image.split("/").length - 1]);
              return (
                <div className="card m-3" style={{ width: "10rem" }}>
                  <img src={image} className="card-img-top" alt="" />
                  <div className="d-flex justify-content-between p-2">
                    <span
                      title={filename}
                      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                      {filename}
                    </span>
                    <a
                      href="#"
                      onClick={async (_) => {
                        const [scrollX, scrollY] = [window.scrollX, window.scrollY];
                        const banners = await API.deleteInternetBanner(id);
                        //console.log('banners', banners);
                        if (banners.status !== 200) return;
                        setState({
                          banners: banners.data,
                        });
                        window.scrollTo(scrollX, scrollY);
                      }}
                      style={{
                        width: 24,
                        height: 24,
                        fontSize: 20,
                        display: "block",
                        flexShrink: 0,
                        lineHeight: 1,
                        padding: 1,
                        textAlign: "center",
                      }}
                      className="btn btn-outline-danger"
                    >
                      ×
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
          {state?.banners?.length < MAX_BANNERS && (
            <div className="text-center">
              <label tabIndex="0" id="bannerUploadLabel" htmlFor="bannerUpload" className="btn btn-primary px-5">
                <input
                  id="bannerUpload"
                  type="file"
                  accept="image/*"
                  onClick={(_) => (document.getElementById("bannerUpload").value = "")}
                  onChange={async (e) => {
                    e.persist();
                    if (!e.target.files) return;
                    const error = await validateImageFile(e.target);
                    if (error) {
                      Swal.fire("Ошибка!", error, "error");
                      return;
                    }
                    let banners = null;
                    try {
                      banners = await API.newInternetBanner({
                        image: e.target.files[0],
                        id: props.mediaplans.item.id,
                      });
                    } catch (e) {
                      const error = e.response?.data?.image?.join(" ") || "Неизвестная ошибка";
                      Swal.fire("Ошибка", error, "error");
                      return;
                    }
                    // hack -- a dict with a key 'image' means error response
                    setState({
                      banners: banners.data,
                    });
                  }}
                ></input>
                Загрузить баннер...{/*(осталось {MAX_BANNERS - state?.banners?.length})*/}
              </label>
            </div>
          )}
        </React.Fragment>
      );
    };
  }
}

class NullType extends InternetAdType {
  static initialState() {
    return {};
  }

  uploadComponent() {
    return (_) => <div></div>;
  }
}

const internetAdTypes = [Video, Banner];

// obj is really the JSON data, which has at least `id` and `media_type`
const internetAdTypeFromObj = (props, ir) => {
  const item = props.mediaplans.item;
  if (!item.get_channel.get_internet_resources) return new NullType();
  if (!ir) return new NullType();
  if (ir === null) throw Error(`InternetResource not specified in mediaplan's ${item.id} internet ad`);
  for (let t of internetAdTypes) {
    if (t.INTERNET_AD_TYPE === ir.category) {
      return new t(item, props);
    }
  }
  throw Error(`InternetAdType for \`${ir.category}\` not found`);
};

export { internetAdTypes, internetAdTypeFromObj };
