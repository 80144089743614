import {
  GET_PLAYLISTS,
  ADD_MEDIAPLAN_MEDIA_START,
  ADD_MEDIAPLAN_MEDIA_PROGRESS,
  ADD_MEDIAPLAN_MEDIA_STOP,
} from "./types";
import { NotificationManager } from "react-notifications";
import { browserHistory } from "react-router";
import { addNotificationToStack } from "../notificationStack";

import API from "../api/list";

export const setMediaplanMedia = (item, media, type) => {
  return (dispatch) => {
    var forms = {
      mediaplan: item,
      media: media,
      type: type,
    };
    API.setMediaplanMedia(forms)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((data) => {
        switch (data.status) {
          case 200:
            NotificationManager.success("Сохранено", "", 15000);
            API.getPlaylists(item.mediaplan).then(function (list) {
              dispatch(listPlaylist(list));
            });
            break;
          case 400:
            NotificationManager.error(data.body.result, "", 15000);
            break;
        }
      });
  };
};

export const addMediaPlanMediaStart = () => {
  return {
    type: ADD_MEDIAPLAN_MEDIA_START,
  };
};

export const addMediaPlanMediaProgress = (progress) => ({
  type: ADD_MEDIAPLAN_MEDIA_PROGRESS,
  payload: {
    progress,
  },
});

export const addMediaPlanMediaStop = () => ({
  type: ADD_MEDIAPLAN_MEDIA_STOP,
});

export const addMediaplanMedia = (forms) => {
  return (dispatch) => {
    dispatch(addMediaPlanMediaStart());
    API.addMediaplanMedia(forms, {
      uploadFn: (progress) => {
        dispatch(addMediaPlanMediaProgress(progress));
      },
    })
      .then((resp) => {
        dispatch(addMediaPlanMediaStop());
        switch (resp.status) {
          case 200:
            NotificationManager.success("Сохранено", "", 15000);
            API.getPlaylists(forms.mediaplan).then(function (list) {
              dispatch(listPlaylist(list));
            });
            break;
          default:
            NotificationManager.error(`Неизвестная ошибка. Статус ошибки: ${resp.status}`, "", 15000);
            break;
        }
      })
      .catch((err) => {
        dispatch(addMediaPlanMediaStop());
        NotificationManager.error(`${err?.response?.data?.result || "Неизвестная ошибка"}`, "", 15000);
      });
  };
};

export const setProposal = (mediaplan, forms) => {
  return (dispatch) => {
    API.mediaHelpRequest(mediaplan, forms)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((data) => {
        switch (data.status) {
          case 200:
            NotificationManager.success("Заявка отправлена. Перезвоним в ближайшее время.", "", 15000);
            break;
        }
      });
  };
};

export const updateMediaplan = (mediaplan, forms) => {
  return (dispatch) => {
    API.updateMediaPlan(mediaplan, forms)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((data) => {
        switch (data.status) {
          case 200:
            NotificationManager.success("Сохранено", "", 15000);
            API.getPlaylists(mediaplan).then(function (list) {
              dispatch(listPlaylist(list));
            });
            break;
          case 400:
            NotificationManager.error("Ошибка при сохранении данных", "", 15000);
            break;
        }
      });
  };
};

// FIXME not found: LIST_MEDIAPLANS_Media
/*export const listMediaplansMedia = (media) => ({
  type: LIST_MEDIAPLANS_Media,
  payload: {
    media: media,
  },
});*/

export const getPlaylists = (mediaplan) => {
  return (dispatch) => {
    API.getPlaylists(mediaplan).then(function (list) {
      dispatch(listPlaylist(list));
    });
  };
};

export const listPlaylist = (list) => ({
  type: GET_PLAYLISTS,
  payload: {
    list: list,
  },
});

export const deleteMediaplan = (id) => {
  return (dispatch) => {
    API.deleteMediaPlan(id).then(function () {
      window.location.reload();
    });
  };
};

export const cancelMediaplan = (id) => {
  return (dispatch) => {
    API.cancelMediaPlan(id).then(function () {
      browserHistory.push("/mediaplans/");
    });
  };
};

export const autoGenerateMediaplan = (id, params) => {
  return (dispatch) => {
    API.autoGenerateMediaplan(id, params).then(function () {
      addNotificationToStack({ type: "success", params: ["Медиаплан сохранён", "", 15000] });
      window.location.reload();
    });
  };
};
