import React from "react";
import { Container, Row, Col } from "reactstrap";
import { NotificationContainer } from "react-notifications";

import Header from "../containers/header";

export default class DashboardScreen extends React.Component {
  render() {
    return (
      <Container fluid={true}>
        <NotificationContainer />
        <Header check={true} />
        <Row>
          <Col md="1" />
          <Col md="10">{this.props.children}</Col>
          <Col md="1" />
        </Row>
      </Container>
    );
  }
}
