import { connect } from "react-redux";
import {
  addMedia,
  getMediaList,
  getChannelListForMediaUpload,
  getMyChannelListForMediaUpload,
  editMedia,
  deleteMedia,
  loadingStart,
  getMyBrands,
  newBrand,
  getMyCategories,
  getCategories,
  editMediaByDoc,
  //setMediaplanVideo,
} from "../actions/media";

import Media from "../components/media";

const mapDispatchToProps = (dispatch) => {
  return {
    onSetMediaplanMedia: (forms) => {
      // FIXME
      //return dispatch(setMediaplanVideo(forms));
    },
    onAddMedia: (file) => {
      return dispatch(addMedia(file));
    },
    onNewBrand: (forms) => {
      return dispatch(newBrand(forms));
    },
    onListMyBrands: () => {
      return dispatch(getMyBrands());
    },
    onListMyCategories: () => {
      return dispatch(getMyCategories());
    },
    onListCategories: () => {
      return dispatch(getCategories());
    },
    onListChannels: () => {
      return dispatch(getChannelListForMediaUpload());
    },
    onListMyChannels: () => {
      return dispatch(getMyChannelListForMediaUpload());
    },
    onListMedia: () => {
      return dispatch(getMediaList());
    },
    onDeleteMedia: (id, cb) => {
      return dispatch(deleteMedia(id, cb));
    },
    onEditMedia: (id, forms) => {
      return dispatch(editMedia(id, forms));
    },
    onEditMediaByDoc: (id, forms, key) => {
      return dispatch(editMediaByDoc(id, forms, key));
    },
    onLoading: () => {
      return dispatch(loadingStart());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    channels: state.media.channels,
    myChannels: state.media.myChannels,
    media: state.media.list,
    myBrands: state.media.myBrands,
    myCategories: state.media.myCategories,
    categories: state.media.categories,
    loading: state.media.loading,
    uploadProgress: state.media.uploadProgress,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Media);
