import { connect } from "react-redux";
import Cross from "../components/cross";
import { getChannelsAndParams, calcCross, resetCross } from "../actions/cross";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetChannelsAndParams: (callback) => {
      return dispatch(getChannelsAndParams(callback));
    },
    onCalcCross: (mediaplanIds) => {
      return dispatch(calcCross(mediaplanIds));
    },
    onResetCross: () => {
      return dispatch(resetCross());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    channelsAndParams: state.cross.channelsAndParams,
    data: state.cross.crossResult,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cross);
