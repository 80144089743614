import {
  ADD_INTERNET_MEDIAPLAN_MEDIA_START,
  ADD_INTERNET_MEDIAPLAN_MEDIA_PROGRESS,
  ADD_INTERNET_MEDIAPLAN_MEDIA_STOP,
} from "../actions/types";

const initialState = {
  loading: true,
  media_loading: false,
  media_loading_percent: 0,
};

export default function editInternetReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_INTERNET_MEDIAPLAN_MEDIA_START:
      return {
        ...state,
        media_loading: true,
        media_loading_percent: 0,
      };
    case ADD_INTERNET_MEDIAPLAN_MEDIA_PROGRESS:
      return {
        ...state,
        media_loading_percent: action.payload.progress,
      };
    case ADD_INTERNET_MEDIAPLAN_MEDIA_STOP:
      return {
        ...state,
        media_loading: false,
      };
    default:
      return state;
  }
}
