import React from "react";
import classNames from "classnames";

export const renderField = ({
  input,
  label,
  placeholder,
  type,
  defaultValue,
  error,
  id = Math.random().toString(36).substring(7),
  disabled = false,
  flex = false,
}) => (
  <div className={classNames("form-group", { "d-flex": flex })}>
    <label>{label}</label>
    <input
      {...input}
      disabled={disabled}
      id={id}
      className={error ? "form-control is-invalid" : "form-control"}
      placeholder={placeholder}
      type={type}
      value={defaultValue}
    />
  </div>
);

export const renderFieldFlex = (params) => renderField({ ...params, flex: true });
