import React from "react";
import ToggleDisplay from "react-toggle-display";

import Format from "./format";
import Types from "./types";
import Order from "../modal/order";
import Steps from "../../containers/steps";

export default class OnBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: 1,
      media_type: "tv",
      tv_pricing_model: "minutes",
      type_sponsor: "none",
      modal: false,
    };
  }

  componentDidMount() {
    this.props.onGetConfig();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  setStates(state) {
    this.setState(state);
  }

  render() {
    return (
      <div>
        <ToggleDisplay show={this.state.screen == 1}>
          <div className="text-center pb-1">
            <img width="300px" src="/static/images/style/start.png" className="img-fluid" />
          </div>
          <Types
            types={this.props.types}
            media_type={this.state.media_type}
            screen={this.state.screen}
            setStates={this.setStates.bind(this)}
            toggle={this.toggle.bind(this)}
          />
        </ToggleDisplay>
        <ToggleDisplay show={this.state.screen == 2}>
          <div className="text-center pb-1">
            <img width="300px" src="/static/images/style/start.png" className="img-fluid" />
          </div>
          <Format screen={this.state.screen} setStates={this.setStates.bind(this)} toggle={this.toggle.bind(this)} />
        </ToggleDisplay>
        <ToggleDisplay show={this.state.screen == 3}>
          <Steps
            media_type={this.state.media_type}
            type_sponsor={this.state.type_sponsor}
            tv_pricing_model={this.state.tv_pricing_model}
          />
        </ToggleDisplay>
        <Order modal={this.state.modal} media_type={this.state.media_type} toggle={this.toggle.bind(this)} />
      </div>
    );
  }
}
