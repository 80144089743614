import { connect } from "react-redux";
import { getInternetChannels } from "../actions/createInternet";
import { addMediaplan } from "../actions/mediaplans";
import CreateInternet from "../components/createInternet";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetInternetChannels: () => {
      return dispatch(getInternetChannels());
    },
    onAddMediaplan: (forms) => {
      return dispatch(addMediaplan(forms));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    internetChannels: state.createInternet.internetChannels,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInternet);
