import { LIST_CONFIG } from "./types";

import API from "../api/list";

export const getConfig = () => {
  return (dispatch) => {
    API.getConfigList().then(function (data) {
      dispatch(listConfig(data));
    });
  };
};

export const listConfig = (data) => ({
  type: LIST_CONFIG,
  payload: {
    types: data.types,
  },
});
