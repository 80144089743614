import React from "react";
import { Badge } from "reactstrap";
import Swal from "sweetalert2";
import { playlistsAreEmpty, mediaplanLackingMedia, playlistsLackingOtherLang } from "./commons";

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: "btn btn-success",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false,
});

export default class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab3: true,
      step3: false,
    };
    this.warnAboutPlaylistsBeingOnlyInNationalLang = this.warnAboutPlaylistsBeingOnlyInNationalLang.bind(this);
  }

  componentDidMount() {
    this.setState({
      tab3: this.props.tab3,
      step3: this.props.step3,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tab3: nextProps.tab3,
      step3: nextProps.step3,
    });
  }

  makeUserUploadMedia() {
    Swal.fire({
      title: "Ошибка",
      text: "Пожалуйста, загрузите ролики и запланируйте выходы прежде чем продолжить",
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((_) => {
      // tab2 is inverted
      this.props.parentSetState({ tab0: false, tab1: false, tab2: false, tab3: true });
    });
  }

  makeUserPlanPlaylistItems() {
    Swal.fire({
      title: "Ошибка",
      text: "Пожалуйста, запланируйте выходы прежде чем продолжить",
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((_) => {
      // tab2 is inverted
      this.props.parentSetState({ tab0: true, tab1: true, tab2: true, tab3: true });
    });
  }

  warnAboutPlaylistsBeingOnlyInNationalLang() {
    swalWithBootstrapButtons
      .fire({
        title: "Подтверждение",
        text: "Все выбранные выходы - на государственном языке. Возможно, вы хотели сделать часть выходов на другом языке?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Вернуться в редактор",
        cancelButtonText: "Продолжить оформление заказа",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          window.location.href = `/editor/${this.props.mediaplan}`;
        } else {
          window.location.href = `/checkout/${this.props.mediaplan}`;
        }
      });
  }

  render() {
    return (
      <div className="card">
        <div className="card-header" id="heading3">
          <h5 className="mb-0">
            <Badge color={this.state.step3 ? "primary" : "danger"} pill>
              &#10148;
            </Badge>{" "}
            <button
              className={this.state.step3 ? "btn btn-link text-primary" : "btn btn-link text-danger"}
              type="button"
              data-toggle="collapse"
              data-target="#collapse3"
              aria-expanded="true"
              aria-controls="collapse3"
              onClick={() => {
                this.setState({
                  tab3: !this.state.tab3,
                });
              }}
            >
              Оформить заказ
            </button>
          </h5>
        </div>
        <div
          id="collapse3"
          className={!this.state.tab3 ? "collapse show" : "collapse"}
          aria-labelledby="heading3"
          data-parent="#accordion3"
        >
          <div className="card-body">
            <p>
              Введите реквизиты, ознакомьтесь с условиями оферты, и отправьте заказ на обработку. Затем оплатите заказ
              согласно сформированным документам.
              <p>
                Убедитесь, что ваши ролики прошли проверку на соответствие требованиям законодательства, и что ваш
                платёж прошел. По окончании кампании ознакомьтесь с отчетом по реально произошедшим выходам роликов в
                эфир.
              </p>
              <p>
                <a
                  className="btn btn-sm btn-success"
                  onClick={(e) => {
                    if (mediaplanLackingMedia(this.props.list.mediaplan)) {
                      e.preventDefault();
                      this.makeUserUploadMedia();
                    } else if (playlistsAreEmpty(this.props.list.playlists)) {
                      e.preventDefault();
                      this.makeUserPlanPlaylistItems();
                    } else if (playlistsLackingOtherLang(this.props.list.mediaplan, this.props.list.playlists)) {
                      e.preventDefault();
                      this.warnAboutPlaylistsBeingOnlyInNationalLang();
                    }
                  }}
                  href={`/checkout/${this.props.mediaplan}`}
                >
                  Ввести реквизиты
                </a>
              </p>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
