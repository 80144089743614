import React from "react";
import { Row, Col, CardHeader, CardBody, Input } from "reactstrap";
import ToggleDisplay from "react-toggle-display";

import AbstractSteps from "./abstractSteps";

export default class Steps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      channels: [],
      default_video: 30,
      channel: null,
      media_type: this.props.media_type,
      tv_pricing_model: this.props.tv_pricing_model,
      type_sponsor: this.props.type_sponsor,
      region: 0,
      channelItem: null,
      regionItem: {},
      year: 0,
      month: 0,
      results: [],
      range: [],
    };
    this.done = this.done.bind(this);
  }

  componentDidMount() {
    this.props.onGetRegions(this.props.media_type, this.props.tv_pricing_model, this.props.type_sponsor);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.region == 0 && nextProps.regions.length > 0) {
      this.setState({
        regions: nextProps.regions,
        region: nextProps.regions[0].id,
        channels: nextProps.regions[0].get_channels,
        regionItem: nextProps.regions[0],
        channel: null,
      });
    }

    if (this.state.media_type != nextProps.media_type) {
      this.setState(
        {
          regions: [],
          channels: [],
          default_video: 30,
          channel: null,
          region: 0,
          regionItem: {},
          year: 0,
          media_type: nextProps.media_type,
          month: 0,
          results: [],
          range: [],
        },
        () => {
          this.props.onGetRegions(nextProps.media_type, nextProps.tv_pricing_model, nextProps.type_sponsor);
        }
      );
    }

    if (this.state.type_sponsor != nextProps.type_sponsor) {
      this.setState(
        {
          type_sponsor: nextProps.type_sponsor,
        },
        () => {
          this.props.onGetRegions(nextProps.media_type, nextProps.tv_pricing_model, nextProps.type_sponsor);
        }
      );
    }

    if (this.state.tv_pricing_model != nextProps.tv_pricing_model) {
      this.setState(
        {
          tv_pricing_model: nextProps.tv_pricing_model,
        },
        () => {
          this.props.onGetRegions(nextProps.media_type, nextProps.tv_pricing_model, nextProps.type_sponsor);
        }
      );
    }
  }

  done() {
    this.props.onAddMediaplan(this.state);
  }

  render() {
    const displayChannelPred = (channel) => {
      if (!channel.active) {
        return false;
      }
      if (this.props.tv_pricing_model === "grp") {
        const base_price_per_grp = parseFloat(channel.base_price_per_grp);
        if (base_price_per_grp === 0 || isNaN(base_price_per_grp)) {
          return false;
        }
      }
      return true;
    };

    const that = this;

    return (
      <AbstractSteps
        regions={this.props.regions}
        currentRegion={this.state.region}
        displayChannelPred={displayChannelPred}
        currentChannel={this.state.channel}
        done={this.done}
        doChange={function (s) {
          that.setState(s);
        }}
        media_type={this.props.media_type}
      >
        <ToggleDisplay show={this.state.channels.length > 0}>
          <Row></Row>
          {this.state.channel && (
            <Row>
              <Col md="12">
                <CardHeader className="border-top">Выберите месяц проведения рекламной кампании:</CardHeader>
                <CardBody>
                  <Input
                    className="w-50"
                    onChange={(e) => {
                      this.setState({
                        year: parseInt(e.target.value.split(".")[1]),
                        month: parseInt(e.target.value.split(".")[0]),
                      });
                    }}
                    type="select"
                  >
                    {this.props.regions
                      .find((r) => r.id === this.state.region)
                      .get_channels.find((c) => c.id === this.state.channel)
                      .get_months.map((item, index) => {
                        return (
                          <option key={index} value={item.key}>
                            {item.value}
                          </option>
                        );
                      })}
                  </Input>
                </CardBody>
              </Col>
            </Row>
          )}
        </ToggleDisplay>
      </AbstractSteps>
    );
  }
}
