export const LIST_COMMENTS = "LIST_COMMENTS";
export const LIST_MEDIA = "LIST_MEDIA";
export const LIST_CHANNEL = "LIST_CHANNEL";
export const LIST_CHANNELS_FOR_MEDIA_UPLOAD = "LIST_CHANNELS_FOR_MEDIA_UPLOAD";
export const LIST_MY_CHANNELS_FOR_MEDIA_UPLOAD = "LIST_MY_CHANNELS_FOR_MEDIA_UPLOAD";
export const LIST_INTERNET_CHANNELS = "LIST_INTERNET_CHANNELS";
export const LIST_MEDIAPLANS = "LIST_MEDIAPLANS";
export const GET_MEDIAPLAN = "GET_MEDIAPLAN";
export const DELETE_MEDIAPLAN = "DELETE_MEDIAPLAN";
export const DO_MEDIAPLAN_CHECK = "DO_MEDIAPLAN_CHECK";
export const START_MEDIAPLAN_CHECK = "START_MEDIAPLAN_CHECK";
export const DID_MEDIAPLAN_CHECK = "DID_MEDIAPLAN_CHECK";
export const CONTINUE_AFTER_MEDIAPLAN_CHECK = "CONTINUE_AFTER_MEDIAPLAN_CHECK";
export const GET_PLAYLISTS = "GET_PLAYLISTS";
export const LIST_CHANNELS_AND_PARAMS = "LIST_CHANNELS_AND_PARAMS";
export const LIST_MONTHS_AND_CHANNELS = "LIST_MONTHS_AND_CHANNELS";
export const CALC_CROSS = "CALC_CROSS";
export const RESET_CROSS = "RESET_CROSS";
export const GET_USER = "GET_USER";
export const GET_USER_BALANCE = "GET_USER_BALANCE";
export const UPDATE_USER = "UPDATE_USER";
export const LIST_EDITOR_DATA = "LIST_EDITOR_DATA";
export const LOADING_START = "LOADING_START";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const ADD_MEDIAPLAN_MEDIA_START = "ADD_MEDIAPLAN_MEDIA_START";
export const ADD_MEDIAPLAN_MEDIA_PROGRESS = "ADD_MEDIAPLAN_MEDIA_PROGRESS";
export const ADD_MEDIAPLAN_MEDIA_STOP = "ADD_MEDIAPLAN_MEDIA_STOP";
export const ADD_INTERNET_MEDIAPLAN_MEDIA_PROGRESS = "ADD_INTERNET_MEDIAPLAN_MEDIA_PROGRESS";
export const ADD_INTERNET_MEDIAPLAN_MEDIA_STOP = "ADD_INTERNET_MEDIAPLAN_MEDIA_STOP";
export const ADD_INTERNET_MEDIAPLAN_MEDIA_START = "ADD_INTERNET_MEDIAPLAN_MEDIA_START";
export const LOADING_STOP = "LOADING_STOP";
export const LIST_REGIONS = "LIST_REGIONS";
export const LIST_MY_BRANDS = "LIST_MY_BRANDS";
export const LIST_CATEGORIES = "LIST_CATEGORIES";
export const LIST_COUNTRIES = "LIST_COUNTRIES";
export const LIST_CURRENCIES = "LIST_CURRENCIES";
export const LIST_MY_CATEGORIES = "LIST_MY_CATEGORIES";
export const GET_ORDER = "GET_ORDER";
export const ORDER_FINALIZED = "ORDER_FINALIZED";
export const LIST_REPORTS = "LIST_REPORTS";
export const GOT_DOWNLOAD_TOKEN = "GOT_DOWNLOAD_TOKEN";
export const LIST_CONFIG = "LIST_CONFIG";
export const LIST_OUTDOOR = "LIST_OUTDOOR";
export const SCROLL_RESERVATION = "SCROLL_RESERVATION";
