import { GET_ORDER, ORDER_FINALIZED, GET_USER, LOADING_START, LIST_COUNTRIES, LIST_CURRENCIES } from "../actions/types";

const initialState = {
  item: {
    get_playlists: [],
    mediaplan: {
      media_type: "",
    },
    get_stats: {
      // TODO optimize?
      count: 0,
      duration: 0,
      duration1: 0,
      duration2: 0,
      resident: false,
      currency: "KZT",
      pretax_amount: 0,
      total_price_kzt: 0,
      exchange_rate: 0,
      amount_to_pay: 0,
      amount_to_pay_kzt: 0,
      vat_rate: 0,
      vat_amount: 0,
    },
    name: "",
    signature: "",
    email: "",
    phone: "",
    get_exchange: {
      id: 0,
      name: "",
      index: 0,
      manual: false,
      currency: "KZT",
      rate: 0,
    },
    city: "",
    contract_id: "0",
    contract_date: "",
    resident: "",
    status_type: 1,
    order_submitted: false,
    address: "",
  },
  user: {},
  currencies: [{ label: "Тенге", currency: "KZT", price: 1, index: 0 }],
  countries: [],
  finalizedOrder: null,
  loading: true,
};

export default function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_COUNTRIES:
      return {
        ...state,
        countries: action.payload.countries,
      };
    case LIST_CURRENCIES:
      return {
        ...state,
        currencies: action.payload.currencies,
      };
    case GET_ORDER:
      return {
        ...state,
        loading: false,
        item: action.payload.item,
      };
    case ORDER_FINALIZED:
      // console.log("ORDER_FINALIZED", action.payload);
      return {
        ...state,
        loading: false,
        finalizedOrder: action.payload,
      };
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case GET_USER:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
      };
    default:
      return state;
  }
}
