import React from "react";
import Currency from "react-currency-formatter";
import { UncontrolledPopover, PopoverBody } from "reactstrap";

class MediaplanType {
  vat_name = "Сумма";
  constructor(item) {
    this.instance = item;
  }
  getLink() {
    throw Error(`Not Implemented for type ${this.constructor.MEDIA_TYPE}`);
  }
  validToViewCheckoutPage() {
    return true;
  }
  checkoutData() {
    throw Error(`Not Implemented for type ${this.constructor.MEDIA_TYPE}`);
  }
  additionalTaxes() {
    return [];
  }
  fullCheckoutData() {
    return [
      ...this.checkoutData(),
      this.showVATRelatedInfo() && {
        label: (
          <React.Fragment>
            {this.vat_name} НДС ({this.instance.get_stats.vat_rate}%)
          </React.Fragment>
        ),
        value: (
          <Currency
            quantity={this.instance.get_stats.vat_amount.toFixed(2)}
            currency={this.instance.get_stats.currency}
            symbol={this.instance.get_stats.currency}
            locale="ru_RU"
            decimal=","
            group=" "
          />
        ),
      },
      this.instance.get_stats.currency != "KZT" && {
        label: "Курс обмена",
        parameters: [
          {
            label: "Курс (тенге за валюту)",
            value: this.instance.get_stats.exchange_rate.toFixed(2),
          },
          {
            label: "Валюта",
            value: this.instance.get_stats.currency,
          },
        ],
      },
      ...this.additionalTaxes(),
      {
        strong: true,
        label: "ИТОГО К ОПЛАТЕ",
        value: (
          <Currency
            quantity={this.instance.get_stats.amount_to_pay.toFixed(2)}
            currency={this.instance.get_stats.currency}
            symbol={this.instance.get_stats.currency}
            locale="ru_RU"
            decimal=","
            group=" "
          />
        ),
      },
    ].filter((x) => x);
  }
  showVATRelatedInfo() {
    return this.instance.resident === 1;
  }
}

class TVLike extends MediaplanType {
  getLink() {
    return `/result/${this.instance.mediaplan.id}/`;
  }
  checkoutData() {
    return [
      {
        label: (
          <TooltipDisplay id="get_playlists__length" text={<>Рекламные выходы</>}>
            <PopoverBody>Количество рекламных выходов</PopoverBody>
          </TooltipDisplay>
        ),
        value: this.instance.get_playlists.length,
      },
      {
        label: "Всего секунд",
        value: this.instance.get_stats.duration1 + this.instance.get_stats.duration2,
      },
      this.showVATRelatedInfo() && {
        label: (
          <TooltipDisplay id="amount_to_pay" text={<>Сумма</>}>
            <PopoverBody>Сумма за рекламные блоки</PopoverBody>
          </TooltipDisplay>
        ),
        value: (
          <Currency
            quantity={this.instance.get_stats.amount_to_pay.toFixed(2)}
            symbol={this.instance.get_stats.currency}
            currency={this.instance.get_stats.currency}
            locale="ru_RU"
            decimal=","
            group=" "
          />
        ),
      },
    ];
  }
}

class TV extends TVLike {
  static MEDIA_TYPE = "tv";
}

class Radio extends TVLike {
  static MEDIA_TYPE = "radio";
}

class Sponsor extends TVLike {
  static MEDIA_TYPE = "sponsor";
}

class Internet extends MediaplanType {
  static MEDIA_TYPE = "internet";

  getLink() {
    return `/internet/${this.instance.mediaplan.id}/`;
  }

  validToViewCheckoutPage() {
    return !(this.instance.mediaplan.media_type === "internet" && !this.instance.mediaplan.get_internet_ad);
  }

  checkoutData() {
    return [
      {
        label: "Количество дней",
        value: this.instance.mediaplan.get_internet_ad.selected_days.length,
      },
      {
        label: (
          <TooltipDisplay id="views_per_day" text={<>Рекламные показы</>}>
            <PopoverBody>Количество показов за сутки</PopoverBody>
          </TooltipDisplay>
        ),
        value: this.instance.mediaplan.get_internet_ad.views_per_day,
      },
      this.showVATRelatedInfo() && {
        label: (
          <TooltipDisplay id="amount" text={<>Сумма</>}>
            <PopoverBody>Cумма за размещение</PopoverBody>
          </TooltipDisplay>
        ),
        value: (
          <Currency
            quantity={this.instance.mediaplan.get_stats.pretax_amount}
            currency="KZT"
            symbol="KZT"
            locale="ru_RU"
            decimal=","
            group=" "
          />
        ),
      },
    ];
  }
}

const TooltipDisplay = ({ id, text, children }) => {
  return (
    <>
      <span
        id={id}
        href={`#${id}`}
        style={{ borderBottom: "1px dotted var(--gray)" }}
        onClick={(e) => e.preventDefault()}
      >
        {text} <i className="fas fa-question-circle" /> <sup style={{ color: "var(--gray)" }}>[&#xff1f;]</sup>
      </span>
      <UncontrolledPopover trigger="hover" placement="auto" target={id}>
        {children}
      </UncontrolledPopover>
    </>
  );
};

class Outdoor extends MediaplanType {
  static MEDIA_TYPE = "outdoor";
  vat_name = "В том числе";

  getLink() {
    return `/outdoor/${this.instance.mediaplan.id}/`;
  }

  checkoutData() {
    return [
      {
        label: "Всего конструкций",
        value: this.instance.get_stats.total_constr,
      },
      {
        label: "Всего скроллов",
        value: this.instance.get_stats.total_scrolls,
      },
      this.showVATRelatedInfo() && {
        label: (
          <TooltipDisplay
            id="pretax_amount"
            text={
              <>
                Услуги по размещению
                <br />
                наружной рекламы
              </>
            }
          >
            <PopoverBody>
              <ul style={{ paddingInlineStart: 20 }}>
                <li>ВКЛЮЧЕНА стоимость аренды конструкции, монтаж-демонтаж, согласование и корректировка эскиза</li>
                <li>НЕ ВКЛЮЧЕНЫ работы по изготовлению рекламных материалов</li>
              </ul>
            </PopoverBody>
          </TooltipDisplay>
        ),
        value: (
          <Currency
            quantity={this.instance.get_stats.pretax_amount}
            currency="KZT"
            symbol="KZT"
            locale="ru_RU"
            decimal=","
            group=" "
          />
        ),
      },
    ];
  }

  additionalTaxes() {
    return [
      {
        label: (
          <>
            Возмещение платы за
            <br />
            размещение наружной
            <br />
            (визуальной) рекламы
          </>
        ),
        value: (
          <Currency
            quantity={this.instance.get_stats.land_tax_amount}
            currency="KZT"
            symbol="KZT"
            locale="ru_RU"
            decimal=","
            group=" "
          />
        ),
      },
    ];
  }
}

const mediaplanTypes = [TV, Radio, Sponsor, Internet, Outdoor];

// obj is really the JSON data, which has at least `id` and `media_type`
const mediaplanTypeFromObj = (item) => {
  for (let t of mediaplanTypes) {
    if (t.MEDIA_TYPE === item.mediaplan.media_type) {
      return new t(item);
    }
  }
  throw Error(`MediaplanType for \`${item.mediaplan.media_type}\` not found`);
};

export { MediaplanType, mediaplanTypeFromObj };
