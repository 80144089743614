import React from "react";
import { Button } from "reactstrap";

import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: "btn btn-success",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false,
});

export default class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab4: false,
      step4: false,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    this.setState({
      tab4: nextProps.tab4,
      step4: nextProps.step4,
    });
  }

  deleteMediaPlan(mediaplan) {
    switch (mediaplan.status_type) {
      case 1:
        swalWithBootstrapButtons
          .fire({
            title: "Вы уверены?",
            text: "Эта операция необратима",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Да!",
            cancelButtonText: "Нет",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              this.props.onDeleteMediaplan(mediaplan.id);
              swalWithBootstrapButtons.fire("Успешное завершение", "Ваш медиаплан был отменен", "success");
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire("Операция остановлена", "Ваш медиаплан в безопасности", "error");
            }
          });
        break;
      case 2:
        swalWithBootstrapButtons
          .fire({
            title: "Вы уверены?",
            text: "При отмене медиаплана применяются штрафные санкции. Продолжить?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Да!",
            cancelButtonText: "Нет",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              this.props.onCancelMediaplan(mediaplan.id);
              swalWithBootstrapButtons.fire(
                "Успешное завершение",
                `Рекламная кампания будет остановлена с ${mediaplan.get_stats.now}, сумма штрафа ${mediaplan.get_stats.penalty} KZT`,
                "success"
              );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire("Операция остановлена", "Ваш медиаплан в безопасности", "error");
            }
          });
        break;
      case 3:
        swalWithBootstrapButtons
          .fire({
            title: "Вы уверены?",
            text: "Эта операция необратима",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Да!",
            cancelButtonText: "Нет",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              this.props.onDeleteMediaplan(mediaplan.id);
              swalWithBootstrapButtons.fire("Успешное завершение", "Ваш медиаплан удален", "success");
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire("Операция остановлена", "Ваш медиаплан в безопасности", "error");
            }
          });
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          {this.props.list.mediaplan.status_type == 1 || this.props.list.mediaplan.status_type == 2 ? (
            <div>
              <Button
                size="sm"
                onClick={this.deleteMediaPlan.bind(this, this.props.list.mediaplan)}
                outline={true}
                color="danger"
              >
                {this.props.list.mediaplan.status_type == 1 ? "Отменить черновик" : "Отменить со штрафом"}
              </Button>
            </div>
          ) : null}{" "}
        </div>{" "}
      </div>
    );
  }
}
