import React from "react";
import { Row, Col, Button } from "reactstrap";
import moment from "moment";
import "moment/locale/ru";

import Step0 from "./steps/step0";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Loadable from "react-loading-overlay";
import { CenteredPercentArc } from "./common";
import { playlistsAreEmpty } from "./steps/commons";
import History from "./steps/history";

export default class Result extends React.Component {
  constructor() {
    super();
    this.state = {
      step0: true,
      step1: true,
      step2: true,
      step3: true,
      step4: true,
      tab0: true,
      tab1: true,
      tab2: false,
      tab3: true,
      tab4: true,
    };
  }

  componentDidMount() {
    this.props.onGetPlaylists(this.props.mediaplan);
  }

  componentWillReceiveProps(nextProps) {
    if (!new Set(["tv", "radio", "sponsor"]).has(nextProps.list.mediaplan.media_type)) {
      window.location.href = "/mediaplans";
    }
    nextProps.list.playlists.map(
      function (item) {
        item.start_time = moment(`${item.m}-${item.d}-${item.y} 00:00`, "MM-DD-YYYY HH:mm");
        item.end_time = moment(`${item.m}-${item.d}-${item.y} 23:59`, "MM-DD-YYYY HH:mm");
        return item;
      }.bind(this)
    );
    this.setState({
      step0: nextProps.list.mediaplan.get_media_attached,
      step1: nextProps.list.mediaplan.get_media_attached,
      step2: !playlistsAreEmpty(nextProps.list.playlists),
      step3: !nextProps.list.mediaplan.get_is_checkout,
      step4: !(nextProps.list.mediaplan.status_type === 1),
      tab0: nextProps.list.mediaplan.get_media_attached,
      tab1: nextProps.list.mediaplan.get_media_attached,
      tab2: false,
      tab3: !nextProps.list.mediaplan.get_is_checkout,
      tab4: !(nextProps.list.mediaplan.status_type === 1),
    });
  }

  render() {
    if (this.props.list.start_date == null || this.props.list.end_date == null) {
      return false;
    }
    return (
      <Loadable
        active={this.props.media_loading}
        text={<CenteredPercentArc percent={this.props.media_loading_percent} />}
      >
        <div>
          {this.props.list.mediaplan != null ? (
            <Row className="pb-2">
              <Col md="12">
                <Button color="secondary" outline={true} class="pb-3" size="sm" href={`/mediaplans`}>
                  &larr; Список медиапланов
                </Button>
              </Col>
              <Col md="12">
                <hr />
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <th width="64px" scope="row">
                        <b>#{this.props.list.mediaplan.id}</b>
                      </th>
                      <td width="64px">
                        {" "}
                        <img src={this.props.list.mediaplan.get_channel.logo} width="64px" height="64px" />
                      </td>
                      <td>
                        <div>{this.props.list.mediaplan.get_channel.full_name}</div>
                        <div>
                          <small>{this.props.list.mediaplan.get_region.region}</small>
                        </div>
                        <div>
                          <small>{this.props.list.mediaplan.get_stats.duration}</small>
                        </div>
                      </td>
                      <td align="center">
                        {" "}
                        <small>
                          <span className="text-muted">Кол-во выходов: </span>
                          <span>{this.props.list.mediaplan.get_stats.count}</span>
                        </small>
                      </td>

                      <td align="center">
                        <small>
                          <span className="text-muted">Тип: </span>
                          <span>
                            {this.props.list.mediaplan.media_type == "sponsor"
                              ? `${this.props.list.mediaplan.get_media_type}/${this.props.list.mediaplan.get_type_sponsor}`
                              : `${this.props.list.mediaplan.get_media_type}/${this.props.list.mediaplan.get_tv_pricing_model}`}
                          </span>
                        </small>
                      </td>
                      {this.props.list.mediaplan.media_type != "sponsor" ? (
                        <td align="center">
                          <small>
                            <span className="text-muted">Бренд: </span>
                            <span>{this.props.list.mediaplan.get_brand_1}</span>
                          </small>
                        </td>
                      ) : null}
                      <td align="center">
                        <small>
                          <span className="text-muted">Статус: </span>
                          <b>{this.props.list.mediaplan.get_status}</b>
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          ) : null}
          <div className="accordion" id="accordions">
            <Step0
              onSetProposal={this.props.onSetProposal}
              step_title={1}
              step0={this.state.step0}
              tab0={this.state.tab0}
              list={this.props.list}
              parentSetState={this.setState.bind(this)}
            />
            {this.props.list.mediaplan.media_type != "sponsor" ? (
              <Step1
                onUpdateMediaplan={this.props.onUpdateMediaplan}
                onAddMediaplanMedia={this.props.onAddMediaplanMedia}
                onSetMediaplanMedia={this.props.onSetMediaplanMedia}
                step_title={2}
                step1={this.state.step1}
                tab1={this.state.tab1}
                list={this.props.list}
                parentSetState={this.setState.bind(this)}
              />
            ) : null}
            <Step2
              autoGenerateMediaplan={this.props.autoGenerateMediaplan}
              step_title={this.props.list.mediaplan.media_type != "sponsor" ? 3 : 2}
              step2={this.state.step2}
              tab2={this.state.tab2}
              mediaplan={this.props.mediaplan}
              list={this.props.list}
              parentSetState={this.setState.bind(this)}
              getDownloadToken={this.props.getDownloadToken}
            />
            <Step3
              onDeleteMediaplan={this.props.onDeleteMediaplan}
              step_title={this.props.list.mediaplan.media_type != "sponsor" ? 4 : 3}
              step3={this.state.step3}
              tab3={this.state.tab3}
              mediaplan={this.props.mediaplan}
              list={this.props.list}
              parentSetState={this.setState.bind(this)}
            />
            <Step4
              onCancelMediaplan={this.props.onCancelMediaplan}
              onDeleteMediaplan={this.props.onDeleteMediaplan}
              step_title={this.props.list.mediaplan.media_type != "sponsor" ? 5 : 4}
              step3={this.state.step4}
              tab3={this.state.tab4}
              mediaplan={this.props.mediaplan}
              list={this.props.list}
              parentSetState={this.setState.bind(this)}
            />
            <History list={this.props.list} />
          </div>
        </div>
      </Loadable>
    );
  }
}
