import React from "react";
import { Field, reduxForm } from "redux-form";
import { Col, Row, Card, CardHeader, Button, FormGroup, CardBody, Label } from "reactstrap";
import Select from "react-select";

import { renderField } from "../../components/renderField";

import DownloadButton from "../../containers/reportDownloadButton";

class ContractForm extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
    };
  }

  componentWillReceiveProps(props) {
    delete props.data["certificate"];
    this.setState({
      data: props.data,
    });
  }

  submit() {
    this.props.updateProfile(this.state.data);
  }

  render() {
    return (
      <div className="pt-3">
        <Card>
          <CardHeader>Редактирование данных контракта</CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <Row>
                  <Col>
                    <Field
                      name="company"
                      type="text"
                      label="Компания (юридическое лицо)"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.company = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                      error={this.state.company}
                      defaultValue={this.state.data.company}
                      component={renderField}
                      placeholder=""
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="signature"
                      type="text"
                      label="В лице руководителя (должность, фамилия, инициалы)"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.signature = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                      error={this.state.signature}
                      defaultValue={this.state.data.signature}
                      component={renderField}
                      placeholder="например, директора Иванова И. И."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="contract1"
                      type="text"
                      label="Действующего на основании"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.contract1 = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                      error={this.state.contract1}
                      defaultValue={this.state.data.contract1}
                      component={renderField}
                      placeholder="например, устава"
                    />
                  </Col>
                </Row>
                {this.state.data.get_country ? (
                  <Row>
                    <Col>
                      <Label>Страна резидентства</Label>
                      <Select
                        className="pb-2"
                        placeholder="Страна резидентства"
                        aria-label="Страна резидентства"
                        aria-labelledby="Страна резидентства"
                        noOptionsMessage={(inputValue) => "Нет результатов"}
                        defaultValue={this.state.data.get_country}
                        options={this.props.countries}
                        onChange={(event) => {
                          var data = this.state.data;
                          data.country = event.value;
                          this.setState({
                            data: data,
                          });
                          this.props.updateProfile(data);
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}
                <Row form>
                  <Col md={5}>
                    <FormGroup>
                      <Field
                        name="city"
                        type="text"
                        label="Город"
                        onChange={(e) => {
                          var data = this.state.data;
                          data.city = e.target.value;
                          this.setState({
                            data: data,
                          });
                        }}
                        error={this.state.city}
                        defaultValue={this.state.data.city}
                        component={renderField}
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Field
                        name="region"
                        type="text"
                        label="Область"
                        onChange={(e) => {
                          var data = this.state.data;
                          data.region = e.target.value;
                          this.setState({
                            data: data,
                          });
                        }}
                        error={this.state.region}
                        defaultValue={this.state.data.region}
                        component={renderField}
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Field
                        name="zip"
                        type="text"
                        label="Индекс"
                        onChange={(e) => {
                          var data = this.state.data;
                          data.zip = e.target.value;
                          this.setState({
                            data: data,
                          });
                        }}
                        error={this.state.zip}
                        defaultValue={this.state.data.zip}
                        component={renderField}
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field
                        name="address"
                        type="text"
                        label="Юридический адрес"
                        onChange={(e) => {
                          var data = this.state.data;
                          data.address = e.target.value;
                          this.setState({
                            data: data,
                          });
                        }}
                        error={this.state.address}
                        defaultValue={this.state.data.address}
                        component={renderField}
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Field
                            name="bank_info_iik"
                            type="text"
                            label="ИИК"
                            onChange={(e) => {
                              var data = this.state.data;
                              data.bank_info_iik = e.target.value;
                              this.setState({
                                data: data,
                              });
                            }}
                            error={this.state.bank_info_iik}
                            defaultValue={this.state.data.bank_info_iik}
                            component={renderField}
                            placeholder=""
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Field
                            name="bank_name"
                            type="text"
                            label="Наименование банка"
                            onChange={(e) => {
                              var data = this.state.data;
                              data.bank_name = e.target.value;
                              this.setState({
                                data: data,
                              });
                            }}
                            error={this.state.bank_name}
                            defaultValue={this.state.data.bank_name}
                            component={renderField}
                            placeholder=""
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Field
                            name="bik"
                            type="text"
                            label="БИК"
                            onChange={(e) => {
                              var data = this.state.data;
                              data.bik = e.target.value;
                              this.setState({
                                data: data,
                              });
                            }}
                            error={this.state.bik}
                            defaultValue={this.state.data.bik}
                            component={renderField}
                            placeholder=""
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Field
                            name="bin_or_iin"
                            type="text"
                            label="БИН или ИИН"
                            onChange={(e) => {
                              var data = this.state.data;
                              data.bin_or_iin = e.target.value;
                              this.setState({
                                data: data,
                              });
                            }}
                            error={this.state.bin_or_iin}
                            defaultValue={this.state.data.bin_or_iin}
                            component={renderField}
                            placeholder=""
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row className="text-center pb-5">
                  <Col>
                    <Button onClick={this.submit.bind(this)} color="primary">
                      Обновить данные контракта
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="align-top">
                      <CardBody>
                        <h5>Требуется подписанный договор:</h5>
                        <div className="pt-2">
                          Пожалуйста, ознакомьтесь с текстом,{" "}
                          <b>
                            <a target="_blank" href="/order/contract/" class="text-success">
                              подпишите договор и поставьте печать
                            </a>
                          </b>
                          . Затем просим отправить нам отсканированный подписанный Договор для скорейшего запуска
                          кампании.
                        </div>
                        <div className="pt-3">
                          Распечатанный вариант ждем по адресу:
                          <br />
                          Республика Казахстан, A15E2Y1, г.Алматы, пр. Аль-Фараби, д.5, блок 1А, офис 603
                          <br />
                          <br />
                          Телефоны: +7 (727) 311-2444 или +7 (701) 301-7618
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Card className="align-top mb-5">
                  <CardBody>
                    <div className="text-center">
                      ДОГОВОР №{" "}
                      <i>
                        <b>{this.state.data.contract_id}</b>
                      </i>
                    </div>
                    <div class="row pt-2 pb-2">
                      <div class="col-md-6">г. Алматы</div>
                      <div class="col-md-6">
                        <div className="text-center">
                          <i>
                            <b>{this.state.data.contract_date}</b>
                          </i>
                        </div>
                        <div className="text-center">
                          <small>
                            (дата договора соответствует дате отправления договора после первого заказа пользователя)
                          </small>
                        </div>
                      </div>
                    </div>
                    <div>
                      ТОО «TV Media Advertising Agency» (ТВ Медиа Адвертайзинг Адженси)», именуемое в дальнейшем
                      «Исполнитель», в лице директора Сафонова В.В., действующего на основании Устава, с одной стороны,
                      и{" "}
                      <i>
                        <b>{this.state.data.company == "" ? "_______________" : this.state.data.company}</b>
                      </i>{" "}
                      , именуемое в дальнейшем «Заказчик», в лице{" "}
                      <i>
                        <b>{this.state.data.signature == "" ? "_______________" : this.state.data.signature}</b>
                      </i>{" "}
                      , действующего на основании{" "}
                      <i>
                        <b>{this.state.data.contract1 == "" ? "_______________" : this.state.data.contract1}</b>
                      </i>{" "}
                      со второй стороны, совместно именуемые «Стороны», а по отдельности «Сторона», заключили настоящий
                      Договор о нижеследующем:
                    </div>
                    <div>
                      <ul>
                        <li>Предмет договора</li>
                        <li>Обязаности и права сторон</li>
                        <li>Порядок расчетов и приемка услуг</li>
                        <li>Особые условия размещения</li>
                        <li>Отвественность сторон</li>
                        <li>Обстоятельства непреодолимой силы (форс-мажор)</li>
                        <li>Конфиденциальность</li>
                        <li>Применимое права и порядок разрешения споров</li>
                        <li>Срок действия договора</li>
                        <li>Дополнительные условия</li>
                        <li>Реквизиты и подписи сторон</li>
                      </ul>
                    </div>
                    <div class="row pt-2">
                      <div class="col-md-6">
                        <div>
                          <b>Заказчик:</b>
                        </div>
                        <div>
                          <i>
                            <b>{this.state.data.company == "" ? "_______________" : this.state.data.company}</b>
                          </i>
                        </div>
                        <div>
                          {this.state.data.zip}{" "}
                          {[
                            this.state.data.region ? this.state.data.region : "",
                            this.state.data.city ? this.state.data.city : "",
                            this.state.data.address,
                          ]
                            .filter((x) => x && x.length)
                            .join(", ")}
                        </div>
                        <div>{this.state.data.bank_info_iik ? `ИИК ${this.state.data.bank_info_iik}` : ""}</div>
                        <div>{this.state.data.bank_name}</div>
                        <div>{this.state.data.bik ? `БИК ${this.state.data.bik}` : ""}</div>
                        <div>{this.state.data.bin_or_iin ? `БИН или ИИН ${this.state.data.bin_or_iin}` : ""}</div>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <b>Исполнитель:</b>
                        </div>
                        <div>
                          <b>
                            ТОО «TV Media Advertising Agency»
                            <br />
                            (ТВ Медиа Адвертайзинг Адженси)
                          </b>
                        </div>
                        <div>Республика Казахстан,</div>
                        <div>A15E2Y1, г.Алматы, Бостандыкский район,</div>
                        <div>пр. Аль-Фараби, д.5, блок 1А, офис 603</div>
                        <div>ИИК KZ588562203129215832 (KZT)</div>
                        <div>АО «Банк ЦентрКредит» г.Алматы</div>
                        <div>БИК KCJBKZKX</div>
                        <div>БИН 071040004334</div>
                      </div>
                    </div>
                    <div class="row pt-2">
                      <div class="col-md-6">
                        <div>
                          <b>Заказчик:</b>
                        </div>
                        <div>
                          <b>____________________</b>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <b>Исполнитель:</b>
                        </div>
                        <div>
                          <b>В.В. Сафонов</b>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-5">
                      <div class="col-md-12 text-center">
                        <DownloadButton path="/order/contract/" color="primary" blank>
                          Скачать договор
                        </DownloadButton>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default reduxForm({
  form: "contractForm",
})(ContractForm);
