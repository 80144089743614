import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Badge,
  ListGroup,
  ModalFooter,
  UncontrolledTooltip,
  ButtonGroup,
  ListGroupItem,
} from "reactstrap";

import Currency from "react-currency-formatter";
import Loadable from "react-loading-overlay";
import ToggleDisplay from "react-toggle-display";

export default class SpotModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var blocks = [];
    return (
      <Modal size="lg" isOpen={this.props.modal} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          {this.props.data.length > 0 ? this.props.days[this.props.data[0].day] : null}
        </ModalHeader>
        <ModalBody>
          <Loadable active={this.props.loading} wrapper text="Сохранение">
            {this.props.data.map(function (item) {
              blocks.push(item.id);
              var error = false;
              if (this.props.chunks.hasOwnProperty(item.key)) {
                if (this.props.chunks[item.key]["duration1"] < this.props.chunks[item.key]["duration2"]) {
                  if (this.props.media_type != "sponsor") {
                    error = true;
                  }
                }
              }
              var color = "secondary";
              if (item.active) {
                color = "success";
              }
              return (
                <span>
                  <ToggleDisplay show={!item.disabled}>
                    <ListGroup className="pb-2">
                      <ListGroupItem className="text-center">{item.program_name}</ListGroupItem>
                      <ListGroupItem>
                        <div className="text-center">
                          {error ? <status-indicator id={`tooltip-danger-modal-${item.id}`} negative pulse /> : null}{" "}
                          {error ? (
                            <UncontrolledTooltip placement="top" target={`tooltip-danger-modal-${item.id}`}>
                              Хронометраж рекламы на государственном языке меньше, чем хронометраж на других языках.
                              Увеличьте долю роликов на государственном языке.
                            </UncontrolledTooltip>
                          ) : null}
                          <Badge color={color} className="mr-2">
                            {item.program_date}
                          </Badge>{" "}
                          <Badge color={color} className="mr-2">
                            {item.block_datetime}
                          </Badge>{" "}
                          <Badge color={color} className="mr-2">
                            {item.block_rating}
                          </Badge>{" "}
                          {this.props.media_type != "sponsor" ? (
                            <Badge color={color} className="mr-2">
                              {item.block_duration} сек
                            </Badge>
                          ) : null}
                          <Badge color={color} className="mr-2">
                            <Currency
                              quantity={item.price}
                              currency="KZT"
                              symbol="KZT"
                              locale="ru_RU"
                              decimal=","
                              group=" "
                            />
                          </Badge>
                          {item.sale_procent > 0 ? <Badge color="success">-{item.sale_procent}%</Badge> : null}
                        </div>
                        <div className="text-center p-2">
                          <ButtonGroup size="sm" className="mr-3">
                            <Button
                              onClick={() => {
                                this.props.onLoadingStart();
                                this.props.onUpdatePlaylist(
                                  this.props.mediaplan,
                                  item.id,
                                  "plus",
                                  this.props.durations.duration1,
                                  1,
                                  this.props.weekend,
                                  this.props.weekdays,
                                  this.props.search,
                                  this.props.rate,
                                  this.props.only_sales
                                );
                              }}
                              disabled={
                                item.block_duration -
                                  item.playlist.duration2 -
                                  item.playlist.duration1 -
                                  this.props.durations.duration1 <
                                0
                              }
                              outline
                              color={color}
                            >
                              +
                            </Button>
                            <Button disabled outline color={color}>
                              {this.props.media_type != "sponsor" ? (
                                <span>
                                  Казахский <b>({item.playlist.duration1} сек)</b>
                                </span>
                              ) : (
                                <span>{item.playlist.duration1 > 0 ? "Выбрано" : "Свободно"}</span>
                              )}
                            </Button>
                            <Button
                              onClick={() => {
                                this.props.onLoadingStart();
                                this.props.onUpdatePlaylist(
                                  this.props.mediaplan,
                                  item.id,
                                  "minus",
                                  this.props.durations.duration1,
                                  1,
                                  this.props.weekend,
                                  this.props.weekdays,
                                  this.props.search,
                                  this.props.rate,
                                  this.props.only_sales
                                );
                              }}
                              disabled={item.playlist.duration1 == 0}
                              outline
                              color={color}
                            >
                              -
                            </Button>
                          </ButtonGroup>
                          {!item.only_national_lang ? (
                            <ButtonGroup size="sm">
                              <Button
                                onClick={() => {
                                  this.props.onLoadingStart();
                                  this.props.onUpdatePlaylist(
                                    this.props.mediaplan,
                                    item.id,
                                    "plus",
                                    this.props.durations.duration2,
                                    2,
                                    this.props.weekend,
                                    this.props.weekdays,
                                    this.props.search,
                                    this.props.rate,
                                    this.props.only_sales
                                  );
                                }}
                                disabled={
                                  item.block_duration -
                                    item.playlist.duration2 -
                                    item.playlist.duration1 -
                                    this.props.durations.duration2 <
                                  0
                                }
                                outline
                                color={color}
                              >
                                +
                              </Button>
                              <Button disabled outline color={color}>
                                {this.props.media_type != "sponsor" ? (
                                  <span>
                                    Русский <b>({item.playlist.duration2} сек)</b>
                                  </span>
                                ) : (
                                  <span>{item.playlist.duration2 > 0 ? "Выбрано" : "Свободно"}</span>
                                )}
                              </Button>
                              <Button
                                onClick={() => {
                                  this.props.onLoadingStart();
                                  this.props.onUpdatePlaylist(
                                    this.props.mediaplan,
                                    item.id,
                                    "minus",
                                    this.props.durations.duration2,
                                    2,
                                    this.props.weekend,
                                    this.props.weekdays,
                                    this.props.search,
                                    this.props.rate,
                                    this.props.only_sales
                                  );
                                }}
                                outline
                                disabled={item.playlist.duration2 == 0}
                                color={color}
                              >
                                -
                              </Button>
                            </ButtonGroup>
                          ) : null}
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  </ToggleDisplay>
                  <ToggleDisplay show={item.disabled}>
                    <ListGroup
                      className="pb-2"
                      style={{
                        opacity: 0.5,
                      }}
                    >
                      <ListGroupItem className="text-center">
                        <s>{item.program_name}</s>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div className="text-center">
                          {error ? <status-indicator id={`tooltip-danger-modal-${item.id}`} negative pulse /> : null}{" "}
                          {error ? (
                            <UncontrolledTooltip placement="top" target={`tooltip-danger-modal-${item.id}`}>
                              Хронометраж рекламы на государственном языке меньше, чем хронометраж на других языках.
                              Увеличьте долю роликов на государственном языке.
                            </UncontrolledTooltip>
                          ) : null}
                          <Badge color={color} className="mr-2">
                            {item.program_date}
                          </Badge>{" "}
                          <Badge color={color} className="mr-2">
                            {item.block_datetime}
                          </Badge>{" "}
                          <Badge color={color} className="mr-2">
                            {item.block_rating}
                          </Badge>{" "}
                          {this.props.media_type != "sponsor" ? (
                            <Badge color={color} className="mr-2">
                              {item.block_duration} сек
                            </Badge>
                          ) : null}
                          <Badge color={color}>
                            <Currency
                              quantity={item.price}
                              currency="KZT"
                              symbol="KZT"
                              locale="ru_RU"
                              decimal=","
                              group=" "
                            />
                          </Badge>
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  </ToggleDisplay>
                </span>
              );
            }, this)}
          </Loadable>
        </ModalBody>
        <ModalFooter>
          <div className="container">
            <div className="d-flex justify-content-between w-100">
              <span className="float-left">
                <Button onClick={this.props.toggle} outline color="secondary">
                  Закрыть
                </Button>
              </span>
              {this.state.status_type == 1 ? (
                <span className="float-left">
                  <Button
                    onClick={() => {
                      this.props.onLoadingStart();
                      this.props.onCancelPlaylist(
                        this.props.mediaplan,
                        blocks,
                        this.props.weekend,
                        this.props.weekdays,
                        this.props.search,
                        this.props.rate,
                        this.props.only_sales
                      );
                    }}
                    outline
                    color="danger"
                  >
                    Отмена выходов
                  </Button>
                </span>
              ) : null}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
