import { connect } from "react-redux";
import { getRegions } from "../actions/steps";
import { addMediaplan } from "../actions/mediaplans";
import Steps from "../components/outdoorSteps";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetRegions: () => {
      return dispatch(getRegions("outdoor_advertising"));
    },
    onAddMediaplan: (forms) => {
      return dispatch(addMediaplan(forms));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    regions: state.steps.regions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
