import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as reduxFormReducer } from "redux-form";
import mediaReducer from "./mediaReducer";
import stepsReducer from "./stepsReducer";
import createInternetReducer from "./createInternetReducer";
import mediaplanReducer from "./mediaplanReducer";
import playlistsReducer from "./playlistsReducer";
import usersReducer from "./usersReducer";
import editorReducer from "./editorReducer";
import checkoutReducer from "./checkoutReducer";
import transactionReducer from "./transactionReducer";
import reportsReducer from "./reportsReducer";
import crossReducer from "./crossReducer";
import airtimeReportsReducer from "./airtimeReportsReducer";
import outdoorReducer from "./outdoorReducer";
import editInternetReducer from "./editInternetReducer";

const mainStore = combineReducers({
  routing: routerReducer,
  form: reduxFormReducer,
  media: mediaReducer,
  steps: stepsReducer,
  createInternet: createInternetReducer,
  mediaplans: mediaplanReducer,
  playlists: playlistsReducer,
  users: usersReducer,
  editor: editorReducer,
  checkout: checkoutReducer,
  transaction: transactionReducer,
  reports: reportsReducer,
  cross: crossReducer,
  airtime_reports: airtimeReportsReducer,
  outdoor: outdoorReducer,
  editInternet: editInternetReducer,
});

export default mainStore;
