import React from "react";

import ProfileForm from "../screens/forms/profile";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        signature: "",
        balance: "",
        company: "",
        adress: "",
        region: "",
        mobile_phone: "",
        zip: "",
        city: "",
        contract_date: "",
        contract_id: "",
      },
    };
  }
  s;
  componentDidMount() {
    this.props.onGetCountries();
    this.props.onGetUserBalance();
  }

  updateProfile(forms) {
    this.props.onUpdateProfile(forms);
  }

  updateCertificate(forms) {
    this.props.updateCertificate(forms);
  }

  render() {
    return (
      <div>
        <ProfileForm
          data={this.props.user}
          userBalance={this.props.userBalance}
          countries={this.props.countries}
          updateProfile={this.updateProfile.bind(this)}
          updateCertificate={this.updateCertificate.bind(this)}
        />
      </div>
    );
  }
}
