import { LIST_OUTDOOR, SCROLL_RESERVATION, LOADING_START } from "../actions/types";

const initialState = {
  list: [],
  scroll_reservation: {
    data: [],
    points: [],
  },
  loading: true,
};

export default function outdoorReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_OUTDOOR:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
      };
    case SCROLL_RESERVATION:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        scroll_reservation: action.payload.scroll_reservation,
      };
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
