import React from "react";
import {
  Card,
  CardTitle,
  Button,
  CardBody,
  CardText,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { connect } from "react-redux";
import { addMediaplan } from "../../actions/mediaplans";
import { browserHistory } from "react-router";

const descriptions = {
  tv: (
    <React.Fragment>
      <PopoverHeader>Реклама на ТВ</PopoverHeader>
      <PopoverBody>
        <p>
          Самое эффективное и самое недорогое по стоимости контакта со зрителем медиа. Видеоролики размещаются в
          рекламных блоках в эфире республиканских и/или региональных телеканалов в определённых временных промежутках
          среди качественных программ, созданных профессионалами.
        </p>
      </PopoverBody>
    </React.Fragment>
  ),
  radio: (
    <React.Fragment>
      <PopoverHeader>Реклама на радио</PopoverHeader>
      <PopoverBody>
        <p>
          Размещение рекламных аудиосообщений в эфире республиканских и/или региональных радиостанций. Радио можно
          слушать, занимаясь практически любой деятельностью в любом месте: в транспорте, дома, в офисе, на пикнике.
          Низкая стоимость и широкий охват - самые сильные стороны этого медиа.
        </p>
      </PopoverBody>
    </React.Fragment>
  ),
  internet: (
    <React.Fragment>
      <PopoverHeader>Что такое реклама в Интернете?</PopoverHeader>
      <PopoverBody>
        <p>Реклама в Интернете — самый современный вид рекламы, имеющий свои особенности.</p>
      </PopoverBody>
    </React.Fragment>
  ),
  outdoor: (
    <React.Fragment>
      <PopoverHeader>Что такое наружная реклама?</PopoverHeader>
      <PopoverBody>
        <p>
          Наружная реклама - рекламные материалы, которые размещаются на стационарных конструкциях, расположенных на
          открытой местности, снаружи.
        </p>
        <p>
          Скроллеры формата «сити борд» 2,4 Х 3,2 м с внутренней светодиодной подсветкой. Расположены вдоль проезжей
          части.
        </p>
        <p>
          <img src="/static/images/billboard.png" className="img-fluid" />
        </p>
        <p>
          Смена пяти рекламных постеров в окне скроллера привлекает дополнительное внимание – интерес к каждому
          следующему изображению повышает эффективность восприятия динамично сменяющейся информации примерно в два раза
          по сравнению с неподвижным рекламным объектом.
        </p>
      </PopoverBody>
    </React.Fragment>
  ),
};

const descriptionLabels = {
  tv: "Что это такое?",
  radio: "Что это такое?",
  internet: "Что это такое?",
};

class Types extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card className="text-center">
        <CardBody>
          <CardTitle>Какие СМИ Вы хотите использовать для проведения рекламной кампании?</CardTitle>
          <CardText>
            <ul className="list-inline">
              {this.props.types.map((item, i) => {
                return (
                  <li className="list-inline-item">
                    <Button
                      outline
                      className="mr-1"
                      onClick={() => {
                        switch (item.type) {
                          case "other":
                            this.props.setStates({
                              modal: true,
                            });
                            return true;
                          case "tv":
                            this.props.setStates({
                              screen: 2,
                            });
                            return true;
                          case "outdoor":
                            browserHistory.push("/outdoor/create");
                            return true;
                          case "internet":
                            browserHistory.push("/internet/create");
                            return true;
                          case "radio":
                            this.props.setStates({
                              screen: 3,
                              media_type: "radio",
                            });
                            return true;
                          default:
                            return true;
                        }
                      }}
                      disabled={!item.value}
                      color="primary"
                    >
                      {item.label}
                    </Button>
                    <div>
                      {(() => {
                        const description = descriptions[item.type];
                        return description ? (
                          <small>
                            {" "}
                            <a
                              id={`help-${item["type"]}`}
                              href={`#help-${item["type"]}`}
                              style={{
                                borderBottom: "1px dotted",
                                textDecoration: "none",
                              }}
                              color="secondary"
                              onClick={(e) => e.preventDefault()}
                            >
                              {descriptionLabels[item.type] || "Что это такое?"}
                            </a>
                            <UncontrolledPopover trigger="focus" placement="left" target={`help-${item["type"]}`}>
                              {description}
                            </UncontrolledPopover>
                          </small>
                        ) : (
                          <br />
                        );
                      })()}
                    </div>
                  </li>
                );
              }, this)}
            </ul>
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddMediaplan: (forms) => {
      return dispatch(addMediaplan(forms));
    },
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Types);
