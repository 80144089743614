import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { browserHistory } from "react-router";
import { verifyRecaptcha } from "../recaptcha";


var API = {
  login: function (forms) {
    return fetch("/api/v1/rest-auth/login/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(forms),
    });
  },
  registration: function (forms) {
    return fetch("/api/v1/rest-auth/registration/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(forms),
    });
  },
  forget: function (forms) {
    return fetch("/api/v1/rest-auth/password/reset/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(forms),
    });
  },
  getProfile: function () {
    return fetch("/api/v1/cabinet/user/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getUserBalance: function () {
    return fetch("/api/v1/cabinet/user-balance/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getAnonymous: function () {
    return fetch("/api/v1/cabinet/anonymous/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
  getPoints: function () {
    return fetch("/api/v1/outdoor/list/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  updateProfile: function (forms) {
    return fetch("/api/v1/cabinet/user/", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    });
  },
  updateCertificate: function (file) {
    let formData = new FormData();
    formData.append("certificate", file.certificate);
    const headers = {
      Accept: "application/json",
      Authorization: "Token " + reactLocalStorage.get("token"),
    };
    return fetch("/api/v1/cabinet/user/", {
      headers,
      method: "PATCH",
      body: formData,
    });
  },
  passwordChange: function (forms) {
    return fetch("/api/v1/rest-auth/password/change/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    });
  },
  resetConfirm: function (forms) {
    return fetch("/api/v1/rest-auth/password/reset/confirm/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(forms),
    });
  },
  getMediaPlans: function () {
    return fetch("/api/v1/playlist/media-plan/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            return Promise.resolve([]);
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  doMediaPlanCheck: function () {
    return fetch("/api/v1/playlist/media-plan-check/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            return Promise.resolve([]);
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getReports: function () {
    return fetch("/api/v1/playlist/reports/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getReportsDownloadToken: function (params) {
    return fetch("/order/reports/token/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(params),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return { ...params, params: { ...params.params, ...json } };
      });
  },
  scroll_reservation: function (mediaplan) {
    return fetch(`/api/v1/outdoor/scroll_reservation/${mediaplan}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getMediaplanMedia: function () {
    return fetch("/api/v1/playlist/media-plan/media/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  countries: function () {
    return fetch("/api/v1/cabinet/countries/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  currencies: function () {
    return fetch("/api/v1/cabinet/currency/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  addToScrollReservation: function (mediaplan, forms) {
    return fetch(`/api/v1/outdoor/scroll_reservation/${mediaplan}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  addMediaplanMedia: function ({ mediaplan, url, type }, { uploadFn }) {
    let formData = new FormData();
    formData.append("mediaplan", JSON.stringify(mediaplan));
    formData.append("type", JSON.stringify(type));
    formData.append("url", url);
    const headers = {
      Accept: "application/json",
      Authorization: "Token " + reactLocalStorage.get("token"),
    };
    return axios.post("/api/v1/playlist/media-plan/media/", formData, {
      headers,
      onUploadProgress: function (progressEvent) {
        const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadFn && uploadFn(uploadProgress);
      },
    });
  },
  setMediaplanMedia: function (forms) {
    return fetch("/api/v1/playlist/media-plan/media/", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    });
  },
  getChannels: function () {
    return fetch("/api/v1/grid/channels/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getChannelListForMediaUpload: function () {
    return fetch("/api/v1/playlist/channels-for-media-upload/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getMyChannelListForMediaUpload: function () {
    return fetch("/api/v1/playlist/channels-for-media-upload/?my=1", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getInternetChannels: function () {
    return fetch("/api/v1/internet/channels", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getChannelsAndParams: function () {
    return fetch("/api/v1/playlist/channels-for-cross/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  calcCross: function (ids) {
    const ids_arg = ids.join(",");
    return fetch(`/api/v1/playlist/calc-cross/${ids_arg}/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getMonthsAndChannels: function () {
    return fetch("/api/v1/playlist/months-and-channels/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getMyBrands: function () {
    return fetch("/api/v1/playlist/my/brands/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getMyCategories: function () {
    return fetch("/api/v1/playlist/my/categories/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  newBrand: function (forms) {
    return fetch("/api/v1/playlist/my/brands/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getCategories: function () {
    return fetch("/api/v1/playlist/categories/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getDashboardList: function (type, pricing, type_sponsor) {
    return fetch(`/api/v1/grid/dashboard/list/?type=${type}&pricing=${pricing}&type_sponsor=${type_sponsor}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  getConfigList: function () {
    return fetch("/api/v1/grid/config/list/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  newMediaPlan: function (forms) {
    return fetch("/api/v1/playlist/media-plan/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  editorData: function (id, weekend, weekdays, search, rate, only_sales) {
    let filter = "";
    filter += "weekend=" + weekend;
    filter += "&weekdays=" + weekdays;
    if (search.length > 0) {
      filter += "&search=" + search;
    }
    filter += "&rate=" + rate;
    filter += "&only_sales=" + only_sales;
    return fetch(`/api/v1/playlist/editor-data/${id}/?` + filter, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      })
      .catch(function (err) {
        browserHistory.push("/mediaplans");
      });
  },
  getMediaPlan: function (id) {
    return fetch(`/api/v1/playlist/media-plan/${id}/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  saveInternetMediaPlan: function (mediaplan, forms) {
    return fetch(`/api/v1/internet/media-plan/${mediaplan}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    }).then(function (res) {
      /*switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();*/
      return res;
    });
    /*.then(function (json) {
        return json;
      })*/
  },
  addInternetMediaPlanMedia: function (data, { uploadFn }) {
    let formData = new FormData();
    formData.append("media_list", JSON.stringify(data.media_list));
    formData.append("url", data.url);
    const headers = {
      Accept: "application/json",
      Authorization: "Token " + reactLocalStorage.get("token"),
    };
    return axios.post(`/api/v1/internet/add-internet-mediaplan-media/${data.mediaplan}`, formData, {
      headers,
      onUploadProgress: function (progressEvent) {
        const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadFn && uploadFn(uploadProgress);
      },
    });
  },
  setInternetMediaPlanMedia: function (mediaplan, media) {
    return fetch(`/api/v1/internet/mediaplan-media/${mediaplan}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify({ media }),
    });
  },
  calendarClick: function (mediaplan, day) {
    return fetch(`/api/v1/internet/calendar/${mediaplan}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify({ day: day }),
    }).then(function (res) {
      /*switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();*/
      return res;
    });
    /*.then(function (json) {
        return json;
      })*/
  },
  targetingChange: function (mediaplan, data) {
    return fetch(`/api/v1/internet/targeting/${mediaplan}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify({ data: data }),
    }).then(function (res) {
      return res;
    });
  },
  getRegions: function () {
    return fetch("/api/v1/internet/regions", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    }).then(function (res) {
      return res.json();
    });
  },
  getOrder: function (id) {
    return fetch(`/order/${id}/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  updateOrder: function (id, forms) {
    return fetch(`/order/${id}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    });
  },
  finalizeOrder: function (id, forms) {
    return fetch(`/order/finalize/${id}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  updateMediaPlan: function (id, forms) {
    return fetch(`/api/v1/playlist/media-plan/${id}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    });
  },
  deleteMediaPlan: function (id) {
    return fetch(`/api/v1/playlist/media-plan/${id}/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    });
  },
  autoGenerateMediaplan: function (id, params) {
    return fetch(`/api/v1/playlist/media-plan/autogenerate/${id}/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(params),
    });
  },
  deleteScrollReservation: function (id) {
    return fetch(`/api/v1/outdoor/scroll_reservation/destroy/${id}/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    }).then(function (res) {
      switch (res.status) {
        case 401:
          reactLocalStorage.clear();
          browserHistory.push("/login");
          break;
        case 404:
          browserHistory.push("/mediaplans");
          break;
      }
      return res.text();
    });
  },
  cancelMediaPlan: function (id) {
    return fetch(`/api/v1/playlist/media-plan/cancel/${id}/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    });
  },
  updatePlaylist: function (forms) {
    return fetch("/api/v1/playlist/create/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    });
  },
  cancelPlaylist: function (id, blocks) {
    return fetch(`/api/v1/playlist/cancel/${id}/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify({
        blocks: blocks,
      }),
    });
  },
  mediaHelpRequest: function (mediaplan, forms) {
    return fetch(`/api/v1/cabinet/media-help-request/${mediaplan}/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    });
  },
  getPlaylists: function (mediaplan) {
    return fetch(`/api/v1/playlist/list/${mediaplan}/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        switch (res.status) {
          case 401:
            reactLocalStorage.clear();
            browserHistory.push("/login");
            break;
          case 404:
            browserHistory.push("/mediaplans");
            break;
        }
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  newMedia: function (file, { uploadFn }) {
    let formData = new FormData();
    formData.append("url", file.url);
    formData.append("channel", file.channel);
    formData.append("doc1", file.doc1);
    formData.append("doc2", file.doc2);
    formData.append("doc3", file.doc3);
    formData.append("doc4", file.doc4);
    formData.append("doc5", file.doc5);
    formData.append("lang", file.lang);
    formData.append("brand", file.brand);
    formData.append("type_advert_media", file.type_advert_media);
    formData.append("product_category", file.product_category);
    formData.append("name_extra", file.name_extra);
    formData.append("target_url", file.target_url);
    formData.append("pixel_1", file.pixel_1);
    formData.append("pixel_2", file.pixel_2);
    if (file.media_type) {
      formData.append("media_type", file.media_type);
    }
    const headers = {
      Accept: "application/json",
      Authorization: "Token " + reactLocalStorage.get("token"),
    };
    return axios.post("/api/v1/playlist/media/list/", formData, {
      headers,
      onUploadProgress: function (progressEvent) {
        const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadFn && uploadFn(uploadProgress);
      },
    });
  },
  updateMedia: function (id, forms) {
    return fetch(`/api/v1/playlist/media/${id}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify(forms),
    });
  },
  newInternetBanner: function (params, additional) {
    const { uploadFn } = additional || {};
    let formData = new FormData();
    formData.append("image", params.image);
    const headers = {
      Accept: "application/json",
      Authorization: "Token " + reactLocalStorage.get("token"),
    };
    return axios.post(`/api/v1/internet/banners-for-internetad/${params.id}`, formData, {
      headers,
      onUploadProgress: function (progressEvent) {
        const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadFn && uploadFn(uploadProgress);
      },
    });
  },
  deleteInternetBanner: function (id) {
    return axios.delete(`/api/v1/internet/banners-for-internetad/${id}/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    });
  },
  updateMediaByDoc: function (id, forms, key) {
    let formData = new FormData();
    formData.append(key, forms[key]);
    const headers = {
      Accept: "application/json",
      Authorization: "Token " + reactLocalStorage.get("token"),
    };
    return fetch(`/api/v1/playlist/media/${id}/`, {
      headers,
      method: "PATCH",
      body: formData,
    });
  },
  getMediaList: function (params) {
    const { channel_id } = params || {};
    let qs = "";
    if (channel_id) {
      qs = "?channel_id=" + channel_id;
    }
    return fetch("/api/v1/playlist/media/list/" + qs, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  deleteMedia: function (id) {
    return fetch(`/api/v1/playlist/media/${id}/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
    });
  },
  removeOldMediaplans: function (expiration_threshold) {
    return fetch(`/api/v1/playlist/remove-old-mediaplans/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + reactLocalStorage.get("token"),
      },
      body: JSON.stringify({ expiration_threshold }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        return json;
      });
  },
  returnCallFormSubmit: function (form) {
    const authToken = reactLocalStorage.get("token");
    return verifyRecaptcha().then((recaptchaToken) => {
      return fetch("/api/v1/feedback/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...(authToken ? { Authorization: "Token " + authToken } : {}),
        },
        body: JSON.stringify({
          name: form.name ? form.name : "-",
          phone: form.phone ? form.phone : "-",
          email: form.email ? form.email : "-",
          channel: form.channel ? form.channel : "-",
          recaptchaToken,
        }),
      });
    })
    .then(function (res) {
      return res.json();
    })
    .then(function (json) {
      return json;
    });
  },
};

export { API as default };
