import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { NotificationManager } from "react-notifications";

export default class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  send() {
    NotificationManager.success("Ваш заказ отправлен успешно!", "", 15000);
    this.props.toggle();
  }

  render() {
    return (
      <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Отправьте вашу заявку</ModalHeader>
        <ModalBody>
          {this.props.media_type == "sponsor" ? (
            <div>
              <p>
                Оставьте вашу контактную информацию, и мы поможем вам создать качественные рекламные материалы для вашей
                спонсорской кампании.
              </p>
              Другие виды спонсорства:
              <ul>
                <li>Спецпроект</li>
                <li>Интеграция в программу</li>
                <li>Электронное спонсорство</li>
                <li>Сюжет в информационной программе</li>
              </ul>
            </div>
          ) : null}
          <Form>
            <FormGroup>
              <Label>ФИО</Label>
              <Input type="text" name="name" placeholder="Укажите ваше имя и фамилию" />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input type="email" name="email" placeholder="Укажите ваш email" />
            </FormGroup>
            <FormGroup>
              <Label>Телефон</Label>
              <Input type="text" name="phone" placeholder="Укажите ваш телефон для связи" />
            </FormGroup>
          </Form>
          {this.props.media_type == "sponsor" ? (
            <div>
              <hr />
              <a href="http://tvmedia.kz/sponsorship">tvmedia.kz/sponsorship</a>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>
            Закрыть
          </Button>{" "}
          <Button onClick={this.send.bind(this)} color="primary">
            Отправить
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
