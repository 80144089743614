import React from "react";
import { Container } from "reactstrap";
import { NotificationContainer } from "react-notifications";

import Header from "../containers/header";
import Mediaplans from "../containers/mediaplans";

export default class MediaplansScreen extends React.Component {
  render() {
    return (
      <div>
        <Container fluid={true}>
          <NotificationContainer />
          <Header check={true} />
          <Mediaplans />
        </Container>
      </div>
    );
  }
}
