import React from "react";
import { Card, Col, Row, CardHeader, CardBody } from "reactstrap";

import API from "../../api/list";

import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: "btn btn-success",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false,
});

class AdditionalInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      expiration_threshold_manual: 10,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.runRemoveOldMediaplans = this.runRemoveOldMediaplans.bind(this);
  }

  async runRemoveOldMediaplans(expiration_threshold_manual) {
    // Note: this alert will be "overriden" by other Swal afterwards
    // thus no need to explicitly close
    swalWithBootstrapButtons.fire({
      title: "Подождите",
      text: "Идёт удаление старых черновиков медиапланов",
      allowEscapeKey: false,
      allowOutsideClick: false,
      //timer: 2000,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    return await API.removeOldMediaplans(expiration_threshold_manual);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.expiration_threshold_manual) return;

    swalWithBootstrapButtons
      .fire({
        title: "Вы уверены?",
        text: `Вы действительно хотите удалить медиапланы старее, чем ${this.state.expiration_threshold_manual} дней?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Да",
        cancelButtonText: "Нет",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          (async () => {
            const { count } = await this.runRemoveOldMediaplans(this.state.expiration_threshold_manual);
            if (typeof count === "number") {
              swalWithBootstrapButtons.fire("Успешное завершение", `Было удалено медиапланов: ${count}`, "success");
            } else {
              swalWithBootstrapButtons.fire("Ошибка", `Ошибка удаления медиапланов`, "error");
            }
          })();
        }
      });
  }

  render() {
    //const { handleSubmit } = this.props;

    return (
      <div className="pt-3">
        <Card>
          <div>
            <CardHeader>Старые черновики медиапланов</CardHeader>
          </div>
          <CardBody>
            {/*<form onSubmit={handleSubmit(this.submit.bind(this))} noValidate>
              <Row>
                <Col>
                  <label htmlFor="do_remove_older_than">Удалить медиапланы старее, чем</label>
                </Col>
                <Col>
                  <input
                    id="do_remove_older_than"
                    className={false ? "form-control is-invalid" : "form-control"}
                    type="text"
                    value={''}
                  />
                </Col>
                <Col>
                  дней{" "}
                </Col>
                <Col>
                  <Button color="primary">Удалить сейчас</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        defaultValue={this.state.remove_automatically}
                        onChange={() => {
                          this.setState({
                            remove_automatically: !this.state.remove_automatically,
                          });
                        }}
                      />{" "}
                      Удалять автоматически
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <Button color="primary">Удалить сейчас</Button>
                </Col>
              </Row>
            </form>*/}
            <Row>
              <Col>
                <form class="form-inline" onSubmit={this.handleSubmit} noValidate>
                  <label for="expiration_threshold_manual">Удалить черновики медиапланов старее, чем</label>
                  <input
                    type="number"
                    class="form-control m-3"
                    id="expiration_threshold_manual"
                    min="1"
                    max="366"
                    value={this.state.expiration_threshold_manual}
                    onChange={(e) => {
                      if (e.target.value && parseInt(e.target.value) > 366) return;
                      this.setState({ expiration_threshold_manual: e.target.value });
                    }}
                  />
                  <label for="expiration_threshold_manual">дней</label>
                  <button
                    disabled={!this.state.expiration_threshold_manual}
                    type="submit"
                    class="btn btn-primary btn-sm m-3"
                  >
                    Удалить сейчас
                  </button>
                </form>
              </Col>
            </Row>
            {/*<Row>
                <Col>
									<form class="form-inline" onSubmit={this.handleSubmit} noValidate>
										<div class="form-group mb-2">
											<label for="expiration_threshold_automatic">Медиапланы старее, чем</label>
										</div>
										<div class="form-group mx-sm-3 mb-2">
											<input
												type="number" class="form-control" id="expiration_threshold_automatic"
												placeholder="30"
											/>
										</div>
										<div class="form-group mb-2 mr-3">
											<label for="expiration_threshold_automatic">дней</label>
										</div>
										<div class="form-group mb-2">
											<label for="expiration_threshold_automatic">удалять автоматически</label>
										</div>
									</form>
                </Col>
              </Row>*/}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default AdditionalInfo;
