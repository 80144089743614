import React from "react";
import AbstractSteps from "./abstractSteps";
export default class Steps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      channels: [],
      default_video: 30,
      channel: null,
      media_type: this.props.media_type,
      tv_pricing_model: this.props.tv_pricing_model,
      type_sponsor: this.props.type_sponsor,
      region: 0,
      channelItem: null,
      regionItem: {},
      year: 0,
      month: 0,
      results: [],
      range: [],
    };
    this.createOutdoorsMediaplan = this.createOutdoorsMediaplan.bind(this);
    this.getRegions = this.getRegions.bind(this);
  }

  createOutdoorsMediaplan() {
    const data = {
      regions: [],
      channels: [],
      default_video: 30,
      channel: null,
      media_type: "outdoor",
      region: 0,
      channelItem: null,
      regionItem: {},
      year: 0,
      month: 0,
      results: [],
      range: [],
    };
    this.props.onAddMediaplan({ ...data, ...this.state });
  }

  componentDidMount() {
    this.props.onGetRegions();
  }

  getRegions(coll) {
    let src = this.props;
    if (coll) src = coll;
    return (src?.regions || []).filter((region) => region?.get_channels?.length);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.region == 0 && nextProps.regions.length > 0) {
      const nextRegions = this.getRegions(nextProps);
      console.log("regions!", nextRegions);
      this.setState({
        regions: nextRegions,
        region: nextRegions?.[0].id,
        channel: null,
      });
    }

    if (this.state.media_type != nextProps.media_type) {
      this.setState(
        {
          regions: [],
          default_video: 30,
          channel: null,
          region: 0,
          year: 0,
          media_type: nextProps.media_type,
          month: 0,
          results: [],
          range: [],
        },
        () => {
          this.props.onGetRegions(nextProps.media_type, nextProps.tv_pricing_model, nextProps.type_sponsor);
        }
      );
    }

    if (this.state.type_sponsor != nextProps.type_sponsor) {
      this.setState(
        {
          type_sponsor: nextProps.type_sponsor,
        },
        () => {
          this.props.onGetRegions(nextProps.media_type, nextProps.tv_pricing_model, nextProps.type_sponsor);
        }
      );
    }

    if (this.state.tv_pricing_model != nextProps.tv_pricing_model) {
      this.setState(
        {
          tv_pricing_model: nextProps.tv_pricing_model,
        },
        () => {
          this.props.onGetRegions(nextProps.media_type, nextProps.tv_pricing_model, nextProps.type_sponsor);
        }
      );
    }
  }

  render() {
    const displayChannelPred = (channel) => {
      if (!channel.active) {
        return false;
      }
      if (this.props.tv_pricing_model === "grp") {
        const base_price_per_grp = parseFloat(channel.base_price_per_grp);
        if (base_price_per_grp === 0 || isNaN(base_price_per_grp)) {
          return false;
        }
      }
      return true;
    };

    const that = this;

    return (
      <AbstractSteps
        regions={this.getRegions()}
        currentRegion={this.state.region}
        displayChannelPred={displayChannelPred}
        currentChannel={this.state.channel}
        done={this.createOutdoorsMediaplan}
        doChange={function (s) {
          that.setState(s);
        }}
        media_type={"outdoor_advertising"}
        selectWhat="оператора"
        whatCount="Операторов"
        availableWhat="ресурсов"
      ></AbstractSteps>
    );
  }
}
