import { connect } from "react-redux";
import Contract from "../components/contract";
import { getUser, updatePofile, getCountries } from "../actions/users";

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateProfile: (forms) => {
      return dispatch(updatePofile(forms));
    },
    onGetCountries: () => {
      return dispatch(getCountries());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    countries: state.users.countries,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
