import { connect } from "react-redux";
import AirtimeReports from "../components/airtimeReports";
import { getMonthsAndChannels } from "../actions/airtime_reports";
import { getDownloadToken } from "../actions/reports";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetMonthsAndChannels: (callback) => {
      return dispatch(getMonthsAndChannels(callback));
    },
    getDownloadToken: (params) => {
      return dispatch(getDownloadToken(params));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    monthsAndChannels: state.airtime_reports.monthsAndChannels,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AirtimeReports);
