import React from "react";

export function PercentArc({ percent }) {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      {(() => {
        const p = Math.max(Math.min(percent, 99.99), 0);
        const strokeWidth = 10;
        const rr = 75;
        const r = rr - strokeWidth;
        const aStartDeg = -90;
        const aEffective = (p * 360) / 100;
        const aDeg = aStartDeg + aEffective;

        const d2r = (deg) => (deg * Math.PI) / 180;
        const a = d2r(aDeg);
        const c2l = (a, r) => [Math.cos(a) * r, Math.sin(a) * r];
        const [sx, sy] = c2l(d2r(aStartDeg), r);
        const [ex, ey] = c2l(a, r);
        const [ox, oy] = [r + strokeWidth / 2, r + strokeWidth / 2];
        return (
          <React.Fragment>
            <circle cx={70} cy={70} r={65} fill="none" stroke-width={strokeWidth} stroke="#333333"></circle>
            <path
              d={`M ${sx + ox},${sy + oy} A ${r},${r} ${a} ${aEffective > 180 ? 1 : 0},1 ${ex + ox},${ey + oy}`}
              fill="none"
              stroke-width={strokeWidth}
              stroke="#FFFFFF"
            />
            ;
          </React.Fragment>
        );
      })()}
      <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" fill="#FFFFFF" fontSize="35px">
        {percent}%
      </text>
    </svg>
  );
}

export const CenteredPercentArc = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PercentArc {...props} />
    </div>
  );
};
