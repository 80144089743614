import { LIST_CHANNELS_AND_PARAMS, CALC_CROSS, RESET_CROSS } from "../actions/types";

const initialState = {
  loading: false,
  channelsAndParams: [],
  crossResult: null,
};

export default function crossReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_CHANNELS_AND_PARAMS:
      return {
        ...state,
        loading: false,
        channelsAndParams: action.payload.list,
      };
    case CALC_CROSS:
      return {
        ...state,
        loading: false,
        crossResult: action.payload.data,
      };
    case RESET_CROSS:
      return {
        ...state,
        loading: false,
        crossResult: null,
      };
    default:
      return state;
  }
}
