import { connect } from "react-redux";
import Reports from "../components/reports";
import { getReports, getDownloadToken } from "../actions/reports";

const mapDispatchToProps = (dispatch) => {
  return {
    onListReports: () => {
      return dispatch(getReports());
    },
    getDownloadToken: (params) => {
      return dispatch(getDownloadToken(params));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    mediaplans: state.reports.list,
    loading: state.reports.loading,
    downloadToken: state.reports.downloadToken,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
