import React from "react";
import { LIST_MEDIAPLANS, GET_MEDIAPLAN, START_MEDIAPLAN_CHECK, DID_MEDIAPLAN_CHECK } from "./types";
import { browserHistory } from "react-router";
import { NotificationManager } from "react-notifications";

import API from "../api/list";

export const addMediaplan = (mediaplan) => {
  return (dispatch) => {
    API.newMediaPlan(mediaplan).then(function (item) {
      if (item.media_type === "outdoor") {
        browserHistory.push(`/outdoor/${item.id}`);
      } else if (item.media_type === "internet") {
        browserHistory.push(`/internet/${item.id}`);
      } else {
        browserHistory.push(`/result/${item.id}`);
      }
    });
  };
};

export const getMediaPlans = () => {
  return (dispatch) => {
    API.getMediaPlans().then(function (list) {
      dispatch(listMediaplans(list));
    });
  };
};

export const getMediaPlan = (id, callback) => {
  return (dispatch) => {
    API.getMediaPlan(id).then(function (item) {
      dispatch(getMediaplan(item));
      callback(item);
    });
  };
};

export const listMediaplans = (list) => ({
  type: LIST_MEDIAPLANS,
  payload: {
    list: list,
  },
});

export const getMediaplan = (item) => ({
  type: GET_MEDIAPLAN,
  payload: {
    item: item,
  },
});

export const startMediaplanCheck = () => {
  return { type: START_MEDIAPLAN_CHECK };
};

export const didMediaplanCheck = () => {
  return { type: DID_MEDIAPLAN_CHECK };
};

export const continueAfterMediaplanCheck = (result, callback) => {
  return (dispatch) => {
    if (result.deleted_info) {
      const { mediaplans_deleted, blocks_deleted } = result.deleted_info;
      if (mediaplans_deleted) {
        NotificationManager.warning(
          <div className="d-flex align-items-center">
            <i className="fa fa-times"></i>
            <svg
              style={{ width: 50 }}
              xmlns="http://www.w3.org/2000/svg"
              ariaHidden="true"
              className="svg-inline--fa fa-times-circle fa-w-16"
              data-icon="times-circle"
              data-prefix="far"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
              ></path>
            </svg>
            <div className="ml-2">{`Удалено устаревших медиапланов: ${mediaplans_deleted}`}</div>
          </div>,
          null,
          0
        );
      }
    }
    dispatch(didMediaplanCheck());
    callback();
  };
};

export const doMediaplanCheck = (callback) => {
  return (dispatch) => {
    dispatch(startMediaplanCheck());
    API.doMediaPlanCheck().then(function (result) {
      dispatch(continueAfterMediaplanCheck(result, callback));
    });
  };
};
