import React from "react";
import { Field, reduxForm } from "redux-form";
import { reactLocalStorage } from "reactjs-localstorage";
import { Card, Col, Row, CardTitle, CardBody, Button, UncontrolledTooltip } from "reactstrap";
import { NotificationManager } from "react-notifications";

import API from "../../api/list";
import { renderField } from "../../components/renderField";

class RegistrationForm extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultCountry: null,
      loading: false,
      password1: false,
      password2: false,
      email: false,
    };

    this.initState = this.initState.bind(this);
  }

  initState() {
    this.setState({
      loading: false,
      password1: false,
      password2: false,
      email: false,
    });
  }

  submit(values) {
    this.setState({
      loading: true,
    });
    API.registration(values).then((response) => {
      this.initState();
      switch (response.status) {
        case 201:
          response.json().then((data) => {
            reactLocalStorage.set("token", data.key);
            reactLocalStorage.set("username", data.user.username);
            window.location.href = "/mediaplans";
          }, this);
          break;
        case 400:
          response.json().then((data) => {
            Object.keys(data).forEach(function (element) {
              let obj = {};
              obj[element] = true;
              this.setState(obj);
              NotificationManager.error(data[element], "", 15000);
            }, this);
          }, this);
          break;
      }
    }, this);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Card className="mb-5">
        <CardBody>
          <CardTitle>Регистрация в системе</CardTitle>
          <hr />
          <form onSubmit={handleSubmit(this.submit.bind(this))} noValidate>
            <div className="text-center">
              <img width="300px" src="/static/images/style/sign-up.png" className="img-fluid" />
            </div>
            <Row>
              <Col>
                <Field
                  id="inputEmail"
                  name="email"
                  type="email"
                  label="Email"
                  error={this.state.email}
                  component={renderField}
                  placeholder="Ваш Email"
                />
                <UncontrolledTooltip placement="bottom" target="inputEmail">
                  Логином должен быть ваш действующий адрес электронной почты.
                </UncontrolledTooltip>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="password1"
                  id="inputPassword1"
                  type="password"
                  label="Пароль"
                  error={this.state.password1}
                  component={renderField}
                  placeholder="Ваш пароль"
                />
                <UncontrolledTooltip placement="bottom" target="inputPassword1">
                  Пароль должен состоять минимум из 8 символов. Допускаются цифры и символы латинского алфавита.
                </UncontrolledTooltip>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="password2"
                  id="inputPassword2"
                  type="password"
                  label="Повторить пароль"
                  error={this.state.password2}
                  component={renderField}
                  placeholder="Еще раз ваш пароль"
                />
                <UncontrolledTooltip placement="bottom" target="inputPassword2">
                  Подтвердите пароль.
                </UncontrolledTooltip>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <Button color="primary">Зарегистрироваться</Button>
              </Col>
            </Row>
          </form>
        </CardBody>
        <CardBody>
          <hr />{" "}
          <Row>
            <Col>
              <p>
                Уже регистрировались? <a href="/login">Войдите</a>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default reduxForm({
  form: "registrationForm",
})(RegistrationForm);
