import React from "react";
import { Container, Row, Col } from "reactstrap";
import { NotificationContainer } from "react-notifications";
import classNames from "classnames";
import { Link } from "react-router";

import Header from "../containers/header";

export default class ProfileScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {}

  toggle(tab) {
    this.setState({
      activeTab: tab,
    });
  }

  render() {
    const tabs = [
      { url: "/edit", title: "Профайл" },
      { url: "/password", title: "Смена пароля" },
      { url: "/transactions", title: "Транзакции" },
      { url: "/report", title: "Сводный отчет" },
      { url: "/airtime-reports", title: "Эфирные справки" },
      { url: "/cross", title: "Кросс-охват" },
      { url: "/contract", title: "Договор на оказание услуг" },
      { url: "/additional", title: "Дополнительно" },
    ];
    return (
      <Container
        style={{
          height: "100%",
        }}
        fluid={true}
      >
        <NotificationContainer />
        <Header check={true} />
        <Row className="bottom-spacing">
          <Col md="1" />
          <Col md="10">
            <div className="nav nav-tabs">
              {tabs.map(({ url, title }) => {
                const fullUrl = `/profile${url}`;
                const active = fullUrl === window.location.pathname;
                return (
                  <li className="nav-item" key={fullUrl}>
                    <Link to={fullUrl} className={classNames("nav-link", { active: active })}>
                      {title}
                    </Link>
                  </li>
                );
              })}
            </div>
            <div className="tab-content">
              <div className="tab-pane active">{this.props.children}</div>
            </div>
          </Col>
          <Col md="1" />
        </Row>
      </Container>
    );
  }
}
