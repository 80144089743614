import React from "react";
import { Container } from "reactstrap";
import { NotificationContainer } from "react-notifications";

import Header from "../containers/header";
import Editor from "../containers/editor";

export default class EditorScreen extends React.Component {
  render() {
    return (
      <Container className="time-result" fluid={true}>
        <NotificationContainer />
        <Header check={true} />
        <Editor mediaplan={this.props.params.mediaplan} />
      </Container>
    );
  }
}
