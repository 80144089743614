import API from "../api/list";
import {
  ADD_INTERNET_MEDIAPLAN_MEDIA_START,
  ADD_INTERNET_MEDIAPLAN_MEDIA_PROGRESS,
  ADD_INTERNET_MEDIAPLAN_MEDIA_STOP,
} from "./types";

import { NotificationManager } from "react-notifications";

export const saveInternetMediaPlan = (mediaplan, data, callback) => {
  return (dispatch) => {
    API.saveInternetMediaPlan(mediaplan, data).then((response) => {
      switch (response.status) {
        case 200:
          if (Object.keys(data || {}).length) NotificationManager.success("Сохранено", "", 15000);
          response.json().then(callback);
          break;
        case 400:
          NotificationManager.error(data.body.result, "", 15000);
          break;
      }
    }, this);
  };
};

export const calendarClick = (mediaplan, data, callback) => {
  return (dispatch) => {
    API.calendarClick(mediaplan, data).then((response) => {
      switch (response.status) {
        case 200:
          NotificationManager.success("Сохранено", "", 150);
          response.json().then(callback);
          break;
        case 400:
          NotificationManager.error(data.body.result, "", 15000);
          break;
      }
    }, this);
  };
};

export const targetingChange = (mediaplan, data, callback) => {
  return (dispatch) => {
    API.targetingChange(mediaplan, data).then((response) => {
      switch (response.status) {
        case 200:
          NotificationManager.success("Сохранено", "", 150);
          response.json().then(callback);
          break;
        case 400:
          NotificationManager.error(data.body.result, "", 15000);
          break;
      }
    }, this);
  };
};

export const addInternetMediaPlanMediaStart = () => {
  return {
    type: ADD_INTERNET_MEDIAPLAN_MEDIA_START,
  };
};

export const addInternetMediaPlanMediaProgress = (progress) => ({
  type: ADD_INTERNET_MEDIAPLAN_MEDIA_PROGRESS,
  payload: {
    progress,
  },
});

export const addInternetMediaPlanMediaStop = () => ({
  type: ADD_INTERNET_MEDIAPLAN_MEDIA_STOP,
});

export const addInternetMediaPlanMedia = (data, callback) => {
  return (dispatch) => {
    dispatch(addInternetMediaPlanMediaStart());
    API.addInternetMediaPlanMedia(data, {
      uploadFn: (progress) => {
        dispatch(addInternetMediaPlanMediaProgress(progress));
      },
    })
      .then((resp) => {
        dispatch(addInternetMediaPlanMediaStop());
        switch (resp.status) {
          case 200:
            NotificationManager.success("Сохранено", "", 15000);
            callback(resp.data);
            break;
          default:
            NotificationManager.error(`Неизвестная ошибка. Статус ошибки: ${resp.status}`, "", 15000);
            break;
        }
      })
      .catch((err) => {
        dispatch(addInternetMediaPlanMediaStop());
        NotificationManager.error(`${err?.response?.data?.result || "Неизвестная ошибка"}`, "", 15000);
      });
  };
};

export const setInternetMediaPlanMedia = (mediaplan, data, callback) => {
  return (dispatch) => {
    API.setInternetMediaPlanMedia(mediaplan, data).then((response) => {
      switch (response.status) {
        case 200:
          NotificationManager.success("Сохранено", "", 150);
          response.json().then(callback);
          break;
        case 400:
          NotificationManager.error(response.body.result, "", 15000);
          break;
      }
    }, this);
  };
};
