import React from "react";
import update from "immutability-helper";
import { Card, Col, Row, CardHeader, CardBody, Button, FormGroup, Badge, UncontrolledTooltip } from "reactstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import Select from "react-select";

// begin
import { useState, useEffect } from "react";
// Usage
function ChartContainer(props) {
  const size = useWindowSize();
  const width = ((size.width - 15 * 4) / 12) * 10 - 2 * 20;
  let firstRow = {};
  if (props?.data?.length) firstRow = props.data[0];
  return (
    <LineChart
      width={width}
      height={width * 0.61803398875}
      margin={{ top: 5, right: 10, bottom: 20, left: 10 }}
      {...props}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        label={{
          value: "GRP30",
          position: "insideBottomRight",
          offset: -10,
        }}
      />
      <YAxis
        label={{
          value: "Rch%",
          angle: -90,
          position: "insideTopRight",
          offset: -5,
        }}
      />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="Rch 1+" stroke="red" strokeWidth="3" />
      {firstRow.hasOwnProperty("Rch 3+") && <Line type="monotone" dataKey="Rch 3+" stroke="green" strokeWidth="3" />}
      {firstRow.hasOwnProperty("Rch 5+") && <Line type="monotone" dataKey="Rch 5+" stroke="purple" strokeWidth="3" />}
    </LineChart>
  );
}
// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
// end

const MEDIAPLAN_STATUS_TYPES = {
  1: "Черновик",
  2: "Активный",
  3: "Архивный",
};

export default class Cross extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: [],
      ids: {},
    };
  }

  componentDidMount() {
    this.props.onGetChannelsAndParams();
  }

  render() {
    const channelsAndParams = this.props.channelsAndParams.channels?.map(({ id, full_name, ...rest }) => ({
      value: id,
      label: full_name,
      ...rest,
    }));

    const ids = [];
    for (let [k, v] of Object.entries(this.state.ids)) {
      if (v) ids.push(parseInt(k));
    }
    let year_month_selected = null;
    const channelMediaplans = {};
    for (let channel of channelsAndParams || []) {
      channelMediaplans[channel.value] = [];
      for (let [year_month, mediaplans] of Object.entries(channel.mediaplans)) {
        for (let mediaplan of mediaplans) {
          if (ids.includes(mediaplan.i)) year_month_selected = year_month;
          channelMediaplans[channel.value].push(mediaplan.i);
        }
      }
    }
    const channels = this.state.channels || [];
    const options = [];
    options.push(channelsAndParams);
    /*let current = new Set();
    for (let i = 0; i < channels.length; i++) {
      const channel = channels[i];
      if (!channel) continue;
      current.add(channel);
      const toAdd = [];
      const cAndP = channelsAndParams.find(({value}) => value === channel);
      for (let c of cAndP.cross) {
        if (current.every(x => c.includes(x))) {

        }
      }
    }*/
    let chain = [];
    for (let c of channels.filter(Boolean)) {
      chain.push(c.value);
      const opts = channelsAndParams.filter(({ value }) => {
        return this.props.channelsAndParams.cross.filter((cs) => {
          return cs.includes(value) && chain.every((x) => cs.filter((y) => y != value).includes(x));
        }).length;
      });
      if (!opts || !opts.length) break;
      options.push(opts);
    }
    if (channels.length < options.length) {
      channels.push(null);
    }
    const mediaplansForChannels = (theChannels) => {
      let result = [];
      for (let c of theChannels) {
        result = result.concat(channelMediaplans[c.value] || []);
      }
      return result;
    };
    return (
      <div className="pt-3">
        <Card>
          <CardHeader>Калькулятор кросс-охвата</CardHeader>
          <CardBody>
            <div className="font-weight-bold">О калькуляторе кросс-охвата</div>
            <p>
              Этот калькулятор позволяет рассчитать кросс-охват вашей рекламной кампании по нескольким медиаресурсам.
              Такая аналитика позволит вам лучше оценивать эффективность вашей рекламы. Этот инструмент позволяет
              проводить анализ как по оформленным медиапланам, так и по черновикам медиапланов.
            </p>
            <div className="font-weight-bold">Как пользоваться калькулятором</div>
            <p>
              Выберите первый медиаресурс (селектор слева), и Платформа подберет другие медиаресурсы, по которым можно
              просчитать кросс-охват (если таковые имеются). Затем выберите медиаплан на первом медиаресурсе, и
              совместимый для анализа медиаплан на втором ресурсе (совместимые медиапланы можно выделить галочкой, а
              несовместимые - заблокированы для выделения). При наличии дополнительных совместимых медиапланов вы можете
              добавить их в аналитический отчет. После выбора медиапланов нажмите кнопку "Рассчитать кросс-охват".
            </p>
            <Row>
              {channels.map((channel, i) => (
                <Col md="4">
                  <FormGroup>
                    <label>Медиаресурс {i + 1}</label>
                    <Select
                      isClearable
                      className="w-100"
                      placeholder={`Выберите медиаресурс`}
                      aria-label={`Выберите медиаресурс`}
                      aria-labelledby={`Выберите медиаресурс`}
                      noOptionsMessage={(inputValue) => "Нет вариантов"}
                      value={channel}
                      options={options[i]}
                      onChange={(channel) => {
                        const newChannels = channels.slice(0, i);
                        newChannels.push(channel);
                        const idsUncheck = mediaplansForChannels(channels.slice(i, channels.length).filter(Boolean));
                        // console.log(
                        //   "old channels",
                        //   channels.slice(i, channels.length).filter(Boolean),
                        //   "idsUncheck",
                        //   idsUncheck
                        // );
                        const newIds = {};
                        for (let k of ids) {
                          newIds[k] = !idsUncheck.includes(parseInt(k));
                        }
                        this.setState(
                          update(this.state, {
                            channels: { $set: newChannels },
                            ids: { $set: newIds },
                          })
                        );
                        this.props.onResetCross();
                      }}
                    />
                  </FormGroup>
                  {channel && (
                    <dl>
                      {Object.entries(channel.mediaplans).map(([year_month, mediaplans]) => (
                        <React.Fragment>
                          <dt>{year_month}</dt>
                          <dd>
                            <ul>
                              {mediaplans.map(({ i, s }) => {
                                const disabled = year_month_selected && year_month != year_month_selected;
                                return (
                                  <li key={i}>
                                    <label className={disabled ? "text-muted" : ""} htmlFor={`select-mediaplan-${i}`}>
                                      <input
                                        id={`select-mediaplan-${i}`}
                                        type="checkbox"
                                        disabled={disabled}
                                        checked={!!this.state.ids[i]}
                                        onChange={(e) => {
                                          this.setState(update(this.state, { ids: { $toggle: [i] } }));
                                          this.props.onResetCross();
                                        }}
                                      />{" "}
                                      <strong>#{i}</strong> <span>({MEDIAPLAN_STATUS_TYPES[s]})</span>
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </dd>
                        </React.Fragment>
                      ))}
                    </dl>
                  )}
                </Col>
              ))}
            </Row>
            <Button
              onClick={(_) => this.props.onCalcCross(ids)}
              disabled={ids.length < 1}
              color="primary"
              size="lg"
              block
            >
              Рассчитать кросс-охват
            </Button>
            {/* cross result */}
            {this.props.data && this.props.data.error && (
              <div className="alert alert-warning mt-4">
                <strong>Ошибка:</strong> <span dangerouslySetInnerHTML={{ __html: this.props.data.error }} />
              </div>
            )}
            {this.props.data && !this.props.data.error && (
              <React.Fragment>
                <table className="table table-warning table-bordered mt-3">
                  <tr>
                    <td align="center">
                      <small>
                        <b>Количество выходов</b>
                      </small>
                    </td>
                    <td align="center">
                      <small>
                        <b>
                          Общий <br />
                          хронометраж, сек
                        </b>
                      </small>
                    </td>
                    <td align="center">
                      <small>
                        <b>
                          Прогнозный <br />
                          объём GRP30
                        </b>
                      </small>
                    </td>
                    <td align="center">
                      <small>
                        <b>
                          Прогнозный <br />
                          Reach 1+, %
                        </b>
                      </small>
                    </td>
                    <td align="center">
                      <small>
                        <b>
                          Стоимость, <br />c НДС
                        </b>
                      </small>
                    </td>
                    <td align="center">
                      <small>
                        <b>CPP</b>{" "}
                        <Badge pill id="tooltip-cpp-title">
                          i
                        </Badge>
                      </small>
                    </td>
                    <td align="center">
                      <small>
                        <b>CPT</b>{" "}
                        <Badge pill id="tooltip-cpt-title">
                          i
                        </Badge>
                      </small>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <small>{this.props.data.count}</small>
                    </td>
                    <td align="center">
                      <small>{this.props.data.total_duration}</small>
                    </td>
                    <td align="center">
                      <small>{this.props.data.grp30}</small>
                    </td>
                    <td align="center">
                      <small>{this.props.data.rch1}</small>
                    </td>
                    <td align="center">
                      <small>{this.props.data.amount_to_pay} KZT</small>
                    </td>
                    <td align="center">
                      <small>{this.props.data.cpp} KZT</small>
                    </td>
                    <td align="center">
                      <small>{this.props.data.cpt} KZT</small>
                    </td>
                  </tr>
                </table>
                <hr />
                <ChartContainer data={this.props.data.rch} />
                <hr />
                <UncontrolledTooltip placement="top" target="tooltip-cpp-title">
                  Стоимость одного пункта рейтинга
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="top" target="tooltip-cpt-title">
                  Стоимость за тысячу контактов
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}
