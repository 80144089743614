import { LIST_OUTDOOR, LOADING_START, SCROLL_RESERVATION } from "./types";

import API from "../api/list";

export const getPoints = () => {
  return (dispatch) => {
    API.getPoints().then(function (list) {
      dispatch(listPoints(list));
    });
  };
};

export const getScrollReservation = (mediaplan) => {
  return (dispatch) => {
    API.scroll_reservation(mediaplan).then(function (list) {
      dispatch(scroll_reservation(list));
    });
  };
};

export const addToScrollReservation = (mediaplan, forms) => {
  return (dispatch) => {
    API.addToScrollReservation(mediaplan, forms).then(function () {
      API.scroll_reservation(mediaplan).then(function (list) {
        dispatch(scroll_reservation(list));
      });
    });
  };
};

export const destroyScrollReservation = (mediaplan, id) => {
  return (dispatch) => {
    API.deleteScrollReservation(id).then(function () {
      API.scroll_reservation(mediaplan).then(function (list) {
        dispatch(scroll_reservation(list));
      });
    });
  };
};

export const listPoints = (list) => ({
  type: LIST_OUTDOOR,
  payload: {
    list: list,
  },
});

export const scroll_reservation = (scroll_reservation) => ({
  type: SCROLL_RESERVATION,
  payload: {
    scroll_reservation: scroll_reservation,
    list: scroll_reservation.list,
  },
});

export const loadingStart = () => ({
  type: LOADING_START,
  payload: {},
});
