import { connect } from "react-redux";
import { getPlaylists } from "../actions/playlists";
import Playlists from "../components/playlists";

import {
  addMediaplanMedia,
  setMediaplanMedia,
  deleteMediaplan,
  cancelMediaplan,
  setProposal,
  updateMediaplan,
  autoGenerateMediaplan,
} from "../actions/playlists";
import { getDownloadToken } from "../actions/reports";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPlaylists: (mediaplan) => {
      return dispatch(getPlaylists(mediaplan));
    },
    onSetMediaplanMedia: (forms) => {
      return dispatch(setMediaplanMedia(forms));
    },
    onAddMediaplanMedia: (forms) => {
      return dispatch(addMediaplanMedia(forms));
    },
    onDeleteMediaplan: (uuid) => {
      return dispatch(deleteMediaplan(uuid));
    },
    onCancelMediaplan: (uuid) => {
      return dispatch(cancelMediaplan(uuid));
    },
    onSetProposal: (mediaplan, forms) => {
      return dispatch(setProposal(mediaplan, forms));
    },
    onUpdateMediaplan: (mediaplan, forms) => {
      return dispatch(updateMediaplan(mediaplan, forms));
    },
    autoGenerateMediaplan: (uuid, params) => {
      return dispatch(autoGenerateMediaplan(uuid, params));
    },
    getDownloadToken: (params) => {
      return dispatch(getDownloadToken(params));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    list: state.playlists.list,
    loading: state.playlists.loading,
    media_loading: state.playlists.media_loading,
    media_loading_percent: state.playlists.media_loading_percent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlists);
