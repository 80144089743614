import React from "react";
import { Button } from "reactstrap";
import "moment/locale/ru";

export default class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { path, blank, children, ...props } = this.props;
    return (
      <Button
        {...props}
        onClick={(e) => {
          e.preventDefault();
          this.props.getDownloadToken({ path, blank });
        }}
      >
        {children}
      </Button>
    );
  }
}
