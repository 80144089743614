import { connect } from "react-redux";
import Checkout from "../components/checkout";
import { getCountries } from "../actions/users";
import { getCurrencies, updateOrder, finalizeOrder, getOrder, loadingStart, updateProfile } from "../actions/checkout";
import { deleteMediaplan } from "../actions/playlists";
import { getDownloadToken } from "../actions/reports";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrder: (id) => {
      return dispatch(getOrder(id));
    },
    onLoading: () => {
      return dispatch(loadingStart());
    },
    onUpdateProfile: (mediaplan, forms) => {
      return dispatch(updateProfile(mediaplan, forms));
    },
    onDeleteMediaplan: (uuid) => {
      return dispatch(deleteMediaplan(uuid));
    },
    onGetCountries: () => {
      return dispatch(getCountries());
    },
    onUpdateOrder: (id, forms) => {
      const formsToSend = { ...forms };
      for (let k of ["mediaplan", "get_stats", "get_playlists", "get_exchange"]) {
        if (formsToSend[k]) delete formsToSend[k];
      }
      return dispatch(updateOrder(id, formsToSend));
    },
    onFinalizeOrder: (id, forms) => {
      const formsToSend = { ...forms };
      for (let k of ["mediaplan", "get_stats", "get_playlists", "get_exchange"]) {
        if (formsToSend[k]) delete formsToSend[k];
      }
      return dispatch(finalizeOrder(id, formsToSend));
    },
    onGetCurrencies: () => {
      return dispatch(getCurrencies());
    },
    getDownloadToken: (params) => {
      return dispatch(getDownloadToken(params));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    item: state.checkout.item,
    loading: state.checkout.loading,
    user: state.users.user,
    countries: state.checkout.countries,
    currencies: state.checkout.currencies,
    finalizedOrder: state.checkout.finalizedOrder,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
