import { LOADING_START, LIST_EDITOR_DATA, LOADING_STOP } from "./types";
import { NotificationManager } from "react-notifications";

import API from "../api/list";

export const updatePlaylist = (mediaplan, block, type, duration, lang, weekend, weekdays, search, rate, only_sales) => {
  return (dispatch) => {
    let forms = {
      mediaplan: mediaplan,
      block: block,
      type: type,
      duration: duration,
      lang: lang,
    };
    API.updatePlaylist(forms)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((data) => {
        switch (data.status) {
          case 200:
            NotificationManager.success("Сохранено", "", 15000);
            break;
          case 400:
            NotificationManager.error(data.body.result, "", 15000);
            break;
        }
        API.editorData(mediaplan, weekend, weekdays, search, rate, only_sales).then(function (list) {
          dispatch(listEditorData(list));
        });
      });
  };
};

export const cancelPlaylist = (mediaplan, blocks, weekend, weekdays, search, rate, only_sales) => {
  return (dispatch) => {
    API.cancelPlaylist(mediaplan, blocks)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((data) => {
        switch (data.status) {
          case 200:
            NotificationManager.success("Сохранено", "", 15000);
            API.editorData(mediaplan, weekend, weekdays, search, rate, only_sales).then(function (list) {
              dispatch(listEditorData(list));
            });
            break;
          case 400:
            NotificationManager.error("Ошибка при отмены данных", "", 15000);
            break;
        }
      });
  };
};

export const listEditorData = (list) => ({
  type: LIST_EDITOR_DATA,
  payload: {
    list: list,
    loading: false,
  },
});

export const loadingStart = () => ({
  type: LOADING_START,
  payload: {
    loading: true,
  },
});

export const loadingStop = () => ({
  type: LOADING_STOP,
  payload: {
    loading: false,
  },
});
