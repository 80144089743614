import React from "react";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className="footer position-fixed">
        <div className="container-fluid">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a className="btn btn-bot btn-link" href="/pages/about/">
                О компании и проекте
              </a>
            </li>
            <li className="list-inline-item">
              <a className="btn btn-bot btn-link" href="/pages/glossary/">
                Глоссарий
              </a>
            </li>
            <li className="list-inline-item">
              <a className="btn btn-bot btn-link" href="/pages/help/">
                Справочная информация
              </a>
            </li>
            <li className="list-inline-item">
              <a target="_blank" className="btn btn-bot btn-link" href="/static/pdf/video_howto.pdf">
                Рекламный ролик
              </a>
            </li>
            <li className="list-inline-item">
              <a className="btn btn-bot btn-link" href="/pages/technical/">
                Технические требования
              </a>
            </li>
            <li className="list-inline-item">
              <a className="btn btn-bot btn-link" href="/pages/legal/">
                Пользовательское соглашение
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}
