import { LIST_REGIONS, LIST_CONFIG } from "../actions/types";

const initialState = {
  regions: [],
  types: [],
};

export default function stepsReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_REGIONS:
      return {
        ...state,
        regions: action.payload.regions,
      };
    case LIST_CONFIG:
      return {
        ...state,
        types: action.payload.types,
      };
    default:
      return state;
  }
}
