import React from "react";
import $ from "jquery";
import { Row, Col, Button, ButtonGroup, Card, CardImg, CardText, CardBody, CardTitle } from "reactstrap";
import moment from "moment";
import "moment/locale/ru";
import Loadable from "react-loading-overlay";
import { CenteredPercentArc } from "./common";
import { internetAdTypes, internetAdTypeFromObj } from "../internetAdTypes.jsx";

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const TARGETING_DEFAULT_AGE_RANGES = [
  "От 18 до 24 лет",
  "От 25 до 34 лет",
  "От 35 до 44 лет",
  "От 45 до 54 лет",
  "55 лет и старше",
];

const TARGETING_AGE_RANGES = ["Меньше 18 лет"].concat(TARGETING_DEFAULT_AGE_RANGES);

function vpd_is_valid(ir, valueStr, VPD_STEP) {
  const value = parseInt(valueStr);
  if (isNaN(value)) return false;
  //XXX other option return (value >= ir.min_order) && (((value - ir.min_order) % VPD_STEP) === 0);
  return value >= ir.min_order && value % VPD_STEP === 0;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function nextMonth({ year, month }) {
  var d = moment()
    .year(year)
    .month(month - 1)
    .date(1)
    .add(1, "month");
  return { month: d.month() + 1, year: d.year() };
}

function MonthCalendar({ year, month, selected_days, readOnly, onChange, first_sale_date }) {
  const weeks = [];
  const start = moment()
    .year(year)
    .month(month - 1)
    .startOf("month")
    .startOf("week");
  const end = moment()
    .year(year)
    .month(month - 1)
    .endOf("month")
    .endOf("week");
  let current = start.clone();
  const isoWeekYear = (m) => m.format("GGGG.WW");
  while (isoWeekYear(current) <= isoWeekYear(end)) {
    const week = [];
    for (var i = 1; i <= 7; i++) {
      week.push(current.clone().isoWeekday(i));
    }
    weeks.push(week);
    current.add(1, "week");
  }
  return (
    <table className="the-calendar">
      <tr>
        <th colSpan="7">
          Дни показа —{" "}
          {capitalize(
            moment()
              .day(1)
              .year(year)
              .month(month - 1)
              .format("MMMM")
          )}{" "}
          {year}
        </th>
      </tr>
      <tr>
        {weeks[0].map((day) => (
          <th>{day.format("dd").toUpperCase()}</th>
        ))}
      </tr>
      {weeks.map((week) => (
        <tr>
          {week.map((day) => {
            let isOutside = false;
            const dayFormatted = day.format("YYYY-MM-DD");
            if (!readOnly && first_sale_date) {
              isOutside = dayFormatted < first_sale_date;
            }
            const isSelected = selected_days.indexOf(dayFormatted) !== -1;
            return (
              <td>
                {day.month() === month - 1 && (
                  <Button
                    className="day-button"
                    disabled={readOnly || isOutside}
                    style={isOutside ? { background: "#eee" } : null}
                    color={day.weekday() < 5 ? "primary" : "danger"}
                    outline={!isSelected}
                    onClick={(_) =>
                      onChange(
                        isSelected ? selected_days.filter((x) => x != dayFormatted) : [...selected_days, dayFormatted],
                        dayFormatted
                      )
                    }
                  >
                    {day.format("D")}
                  </Button>
                )}
              </td>
            );
          })}
        </tr>
      ))}
    </table>
  );
}

function myDebounce(f, wait) {
  // call first time - always. if it has happened after more than "delay" has passed
  // then postpone it till delay end
  // if new call comes while postponed - override with a new call
  let lastCall = 0;
  let timeoutHandle = null;

  return function () {
    const thisMoment = Date.now();
    const calledLongTimeAgo = thisMoment > lastCall + wait;
    if (calledLongTimeAgo && !timeoutHandle) {
      lastCall = thisMoment;
      f.apply(this, arguments);
    } else if (!calledLongTimeAgo && !timeoutHandle) {
      // was called, but not long time ago
      timeoutHandle = setTimeout(() => {
        lastCall = thisMoment;
        timeoutHandle = null;
        f.apply(this, arguments);
      }, wait - (thisMoment - lastCall));
    } else {
      clearTimeout(timeoutHandle);
      timeoutHandle = setTimeout(() => {
        lastCall = thisMoment;
        timeoutHandle = null;
        f.apply(this, arguments);
      }, wait - (thisMoment - lastCall));
    }
  };
}

function vpdChangeHandler(views_per_day) {
  window.MediaPlan_element.saveVPD(views_per_day);
}

const vpdChangeHandlerDebounced = myDebounce(vpdChangeHandler, 1000);

export default class MediaPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targeting_gender: null,
      targeting_age: null,
      targeting_geo: null,
      internet_resource: null,
      views_per_day: null,
      url: "",
      tns_pixel: "",
      selected_days: [],
      slidesLeft: 0,
      slidesMaxLeft: 0,
      slidesWrapperMaxWidth: 1920,
      slidesHeight: 400,
      amount: "0",
      can_checkout: false,
      months_added: 0,
      first_sale_date: null,
      ...Object.assign.apply(null, [{}, ...internetAdTypes.map((t) => t.initialState())]),
    };
    this.saveInternetMediaPlan = this.saveInternetMediaPlan.bind(this);
    this.saveVPD = this.saveVPD.bind(this);
    this.calendarClick = this.calendarClick.bind(this);
    this.slidesWrapperRef = React.createRef();
    this.slidesItemsRef = React.createRef();
    this.onResize = this.onResize.bind(this);
  }

  saveVPD(views_per_day) {
    this.saveInternetMediaPlan({ views_per_day });
  }

  onResize() {
    if (!this.slidesWrapperRef.current) return;
    let slidesWrapperMaxWidth =
      Math.floor(
        Math.min(
          (this.props.mediaplans.item.get_channel.get_internet_resources || []).length,
          (this.slidesWrapperRef.current.getBoundingClientRect().width - 25 * 2) / 340
        )
      ) *
        340 -
      40;
    const slidesMaxLeft = this.slidesItemsRef.current.getBoundingClientRect().width - slidesWrapperMaxWidth - 40;
    this.setState({
      slidesMaxLeft,
      slidesLeft: Math.min(this.state.slidesLeft, slidesMaxLeft),
      slidesWrapperMaxWidth,
    });
  }

  componentDidMount() {
    const that = this;
    this.props.onGetMediaPlan(this.props.params.mediaplan, function () {
      if (that.props.mediaplans.item.media_type !== "internet") {
        window.location.href = "/mediaplans";
      } else {
        // fetch InternetAd data
        that.onResize();
        that.setState(that.props.mediaplans.item.get_internet_ad);
        setTimeout(() => {
          that.setState({
            slidesHeight:
              Math.max.apply(null, [
                240,
                ...(Array.from($(that.slidesItemsRef.current).find(".card")).map((x) => $(x).height()) || []),
              ]) + 2,
          });
        }, 100);
        that.props.onGetMediaList({
          channel_id: that.props.mediaplans.item.channel,
        });
      }
    });
    this.props.onGetRegions();
    window.addEventListener("resize", this.onResize);
  }

  componentDidUpdate() {
    const that = this;
    const newHeight =
      Math.max.apply(null, [
        240,
        ...(Array.from($(that.slidesItemsRef.current).find(".card")).map((x) => $(x).height()) || []),
      ]) + 2;
    if (newHeight != that.state.slidesHeight) {
      // console.log(`componentDidUpdate will update height to ${newHeight}`);
      setTimeout(() => {
        that.onResize();
        that.setState({
          slidesHeight: newHeight,
        });
      }, 100);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  saveInternetMediaPlan(data) {
    const that = this;
    this.props.onSaveInternetMediaPlan(this.props.params.mediaplan, data, function (resp) {
      that.setState(resp);
    });
  }

  calendarClick(dayFormatted) {
    const that = this;
    this.props.onCalendarClick(this.props.params.mediaplan, dayFormatted, function (resp) {
      that.setState(resp);
    });
  }

  targetingChange() {
    const that = this;
    const { targeting_gender, targeting_age, targeting_geo } = this.state;
    this.props.onTargetingChange(
      this.props.params.mediaplan,
      { targeting_gender, targeting_age, targeting_geo },
      function (resp) {
        const { amount, can_checkout, first_sale_date } = resp;
        that.setState({ ...resp.targeting, amount, can_checkout, first_sale_date });
      }
    );
  }

  render() {
    const disabled = this.props.mediaplans.item.status_type !== 1;
    const { year, month } = this.props.mediaplans.item;
    let first_month = { year, month };
    if (this.state.first_sale_date) {
      const [year2, month2] = this.state.first_sale_date.split("-");
      first_month = { year: parseInt(year2), month: parseInt(month2) };
    }
    const months = [first_month];

    let existing_months = this.state.selected_days.map((m) => m.substr(0, 4 + 1 + 2).replace("-0", "-"));
    existing_months.push(first_month.year + "-" + first_month.month);

    function months_max(ms) {
      const xs = ms.map(({ year, month }) => year + "-" + month).sort();
      return xs[xs.length - 1];
    }

    const existing_months_max = existing_months.sort()[existing_months.length - 1];

    /*var x = 0;
    while (months_max(months) !== existing_months_max) {
      var last = months[months.length - 1];
      months.push(nextMonth(last));
      x++;
      if (x === 10) break;
    }*/

    for (var i = 0; i < 5; i++) {
      var last = months[months.length - 1];
      months.push(nextMonth(last));
    }

    let ir = null;
    (this.props.mediaplans.item.get_channel.get_internet_resources || []).map((item) => {
      if (item.id == this.state.internet_resource) ir = item;
    });

    const targeting_geo_set = new Set(this.state.targeting_geo || []);

    const defaultRegionIds = this.props.regions.filter(({ is_default }) => is_default).map((x) => x.id);

    const PercentBadge = ({ what }) => (
      <span className="badge badge-secondary mx-1">
        +{this.props.mediaplans.item.get_channel[`targeting_rate_${what}`].replace(/\.0$/, "")}%
      </span>
    );

    window.MediaPlan_element = this;

    const internetAdType = internetAdTypeFromObj(
      { ...this.props, internet_resource: this.state.internet_resource },
      ir
    );

    const MediaComponent = internetAdType.uploadComponent();
    const VPD_STEP = this?.state?.VPD_STEP || this.props.mediaplans?.item?.get_internet_ad?.VPD_STEP;

    return (
      <Loadable
        active={this.props.media_loading}
        text={<CenteredPercentArc percent={this.props.media_loading_percent} />}
      >
        <Row className="edit-internet">
          <Col md="12">
            <div>
              {this.props.mediaplans.item != null ? (
                <Row className="pb-2">
                  <Col md="12">
                    <Button color="secondary" outline={true} class="pb-3" size="sm" href={`/mediaplans`}>
                      &larr; Список медиапланов
                    </Button>
                  </Col>
                  <Col md="12">
                    <hr />
                    <div style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}>
                      <table class="table table-borderless">
                        <tbody>
                          <tr>
                            <th width="64px" scope="row">
                              <b>#{this.props.mediaplans.item.id}</b>
                            </th>
                            <td width="64px">
                              {" "}
                              <img src={this.props.mediaplans.item.get_channel.logo} width="64px" height="64px" />
                            </td>
                            <td>
                              <div>{this.props.mediaplans.item.get_channel.full_name}</div>
                              <div></div>
                            </td>
                            <td align="center">
                              {" "}
                              <div>
                                <small>
                                  <b>
                                    <span className="text-muted">Стоимость:</span> {this.state.amount + " KZT"}
                                  </b>
                                </small>
                              </div>
                              <div>
                                <small>
                                  <span className="text-muted">Кол-во дней: </span>
                                  <span>{(this.state.selected_days || []).length}</span>
                                </small>
                              </div>
                            </td>

                            <td align="center">
                              <small>
                                <span className="text-muted">Тип: </span>
                                <span>{this.props.mediaplans.item.get_media_type}</span>
                              </small>
                            </td>
                            <td align="center">
                              <small>
                                <span className="text-muted">Статус: </span>
                                <b>{this.props.mediaplans.item.get_status}</b>
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h5>Выберите размещение:</h5>
                    <hr />
                    <div className="the-pre-wrapper" ref={this.slidesWrapperRef}>
                      {this.state.slidesLeft !== 0 ? (
                        <a
                          className="press-left"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ slidesLeft: Math.max(0, this.state.slidesLeft - 340) });
                          }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="chevron-left"
                            class="svg-inline--fa fa-chevron-left fa-w-10"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                            ></path>
                          </svg>
                        </a>
                      ) : (
                        <div className="press-left"></div>
                      )}
                      <div
                        className="the-wrapper"
                        style={{ width: this.state.slidesWrapperMaxWidth, height: this.state.slidesHeight }}
                      >
                        <div
                          className="the-resources"
                          style={{ left: -1 * this.state.slidesLeft }}
                          ref={this.slidesItemsRef}
                        >
                          {(this.props.mediaplans.item.get_channel.get_internet_resources || []).map((ir) => (
                            <Card key={ir.id}>
                              <CardImg
                                top
                                width="100%"
                                src={ir.placement_diagram || "/static/assets/img/placeholder_1.png"}
                                alt=""
                              />
                              <CardBody>
                                <CardTitle tag="h5">{ir.name}</CardTitle>
                                <CardText>
                                  <strong>Мин. показов в день</strong>: {ir.min_order}
                                  <br />
                                  <strong>Цена за 1000 показов</strong>: {parseFloat(ir.cpm).toFixed(0)} KZT
                                  <br />
                                  <div
                                    style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                    title={ir.description}
                                  >
                                    {ir.description}
                                  </div>
                                </CardText>
                                {this.state.internet_resource === ir.id ? (
                                  <Button disabled={disabled} color="primary">
                                    Выбрано
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={disabled}
                                    color="primary"
                                    onClick={(_) => this.saveInternetMediaPlan({ internet_resource: ir.id })}
                                    outline
                                  >
                                    Выбрать
                                  </Button>
                                )}
                              </CardBody>
                            </Card>
                          ))}
                        </div>
                      </div>
                      {this.state.slidesLeft < this.state.slidesMaxLeft ? (
                        <a
                          className="press-right"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              slidesLeft: Math.min(this.state.slidesMaxLeft, this.state.slidesLeft + 340),
                            });
                          }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="chevron-right"
                            class="svg-inline--fa fa-chevron-right fa-w-10"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                            ></path>
                          </svg>
                        </a>
                      ) : (
                        <div className="press-right"></div>
                      )}
                    </div>
                    <br />
                    <br />
                    {this.state.internet_resource && (
                      <React.Fragment>
                        <hr />
                        <div className="d-flex justify-content-center align-items-center">
                          Число показов (шаг — {VPD_STEP}):
                          <input
                            style={{
                              maxWidth: 120,
                              ...(vpd_is_valid(ir, this.state.views_per_day, VPD_STEP)
                                ? {}
                                : { borderColor: "rgb(255, 193, 7)", boxShadow: "0 0 0 0.2rem rgb(255 193 7 / 25%)" }),
                            }}
                            type="number"
                            min={Math.ceil(ir.min_order / VPD_STEP) * VPD_STEP}
                            disabled={disabled}
                            className="form-control mx-2"
                            step={VPD_STEP}
                            id="cpm_value"
                            value={this.state.views_per_day || ""}
                            onChange={(e) => {
                              const views_per_day = e.target.value;
                              this.setState({ views_per_day }, () => {
                                if (vpd_is_valid(ir, views_per_day, VPD_STEP)) {
                                  vpdChangeHandlerDebounced(views_per_day);
                                }
                              });
                            }}
                            onBlur={(_) => {
                              if (!vpd_is_valid(ir, this.state.views_per_day, VPD_STEP)) {
                                vpdChangeHandlerDebounced();
                              }
                            }}
                          />
                          в день
                        </div>
                        <hr />
                        <div className="d-flex justify-content-center flex-wrap" style={{ margin: -20 }}>
                          {months.map(({ year, month }) => (
                            <div key={year + "-" + month} style={{ margin: 20 }}>
                              <MonthCalendar
                                year={year}
                                month={month}
                                selected_days={this.state.selected_days}
                                onChange={(selected_days, dayFormatted) => {
                                  this.setState({ selected_days });
                                  this.calendarClick(dayFormatted);
                                }}
                                readOnly={disabled}
                                first_sale_date={this.state.first_sale_date}
                              />
                            </div>
                          ))}
                          {/*<div className="d-flex justify-content-center align-items-center" style={{width: 364, height: 412}}>
                            <Button color="primary" outline onClick={_ => this.setState({months_added: this.state.months_added + 1})}>+</Button>
                        </div>*/}
                        </div>
                        <hr />
                        <div className="row my-4">
                          <div className="col-md-12 text-center">
                            <MediaComponent
                              key={internetAdType.constructor.INTERNET_AD_TYPE}
                              {...this.props}
                              state={this.state}
                              setState={(s, cb) => this.setState(s, cb)}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="row my-4">
                          <div className="col-sm-12 col-md-8 offset-md-2 text-left">
                            <h4 className="text-center">Таргетинг</h4>
                            <div className="mb-2 d-flex align-items-center justify-content-start">
                              <input
                                type="checkbox"
                                checked={!!this.state.targeting_gender}
                                disabled={disabled}
                                onChange={(e) =>
                                  this.setState({ targeting_gender: e.target.checked ? "M" : null }, (_) =>
                                    this.targetingChange()
                                  )
                                }
                              />
                              &nbsp; Таргетинг по полу <PercentBadge what="gender" />
                              <ButtonGroup className="ml-2">
                                {[
                                  ["M", "М"],
                                  ["F", "Ж"],
                                ].map(([k, label]) => (
                                  <Button
                                    key={k}
                                    disabled={disabled}
                                    outline={this.state.targeting_gender !== k}
                                    onClick={(_) =>
                                      this.setState(
                                        { targeting_gender: this.state.targeting_gender !== k ? k : null },
                                        (_) => this.targetingChange()
                                      )
                                    }
                                    color="primary"
                                  >
                                    {label}
                                  </Button>
                                ))}
                              </ButtonGroup>
                            </div>
                            {disabled ? (
                              <div className="mb-2">
                                <div className="d-flex align-items-center justify-content-start">
                                  <input
                                    type="checkbox"
                                    disabled
                                    checked={!!this.state.targeting_age && this.state.targeting_age.length}
                                  />
                                  &nbsp; Таргетинг по возрасту <PercentBadge what="age" />
                                </div>
                                <ul style={{ listStyle: "none", paddingLeft: 22 }}>
                                  {(this.state.targeting_age || []).map((value, i) => (
                                    <li key={value}>
                                      <label htmlFor={`targeting_age-${i}`} style={{ marginBottom: 0 }}>
                                        <input id={`targeting_age-${i}`} type="checkbox" checked disabled />
                                        <span className="ml-2">{value}</span>
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <div className="mb-2">
                                <div className="d-flex align-items-center justify-content-start">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    checked={!!this.state.targeting_age && this.state.targeting_age.length}
                                    onChange={(e) =>
                                      this.setState(
                                        { targeting_age: e.target.checked ? TARGETING_DEFAULT_AGE_RANGES : [] },
                                        (_) => this.targetingChange()
                                      )
                                    }
                                  />
                                  &nbsp; Таргетинг по возрасту <PercentBadge what="age" />
                                </div>
                                <ul style={{ listStyle: "none", paddingLeft: 22 }}>
                                  {TARGETING_AGE_RANGES.map((value, i) => (
                                    <li key={value}>
                                      <label htmlFor={`targeting_age-${i}`} style={{ marginBottom: 0 }}>
                                        <input
                                          id={`targeting_age-${i}`}
                                          type="checkbox"
                                          checked={(this.state.targeting_age || []).indexOf(value) !== -1}
                                          onChange={(e) => {
                                            let targeting_age = (this.state.targeting_age || []).slice();
                                            targeting_age = targeting_age.filter((x) => x != value);
                                            if (e.target.checked) targeting_age.push(value);
                                            targeting_age = targeting_age.filter(
                                              (x) => TARGETING_AGE_RANGES.indexOf(x) !== -1
                                            );
                                            this.setState({ targeting_age }, (_) => this.targetingChange());
                                          }}
                                        />
                                        <span className="ml-2">{value}</span>
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            <div className="mb-2">
                              <div>
                                <input
                                  id="targeting_geo"
                                  type="checkbox"
                                  disabled={disabled}
                                  checked={
                                    0 < targeting_geo_set.size &&
                                    targeting_geo_set.size < (this.props.regions || []).length
                                  }
                                  onChange={(e) =>
                                    this.setState(
                                      {
                                        targeting_geo: !e.target.checked ? [] : defaultRegionIds,
                                      },
                                      (_) => this.targetingChange()
                                    )
                                  }
                                />
                                &nbsp; Геотаргетинг <em>(без выбора — весь Казахстан)</em> <PercentBadge what="geo" />
                              </div>
                              <div style={{ marginLeft: 22 }}>
                                <table>
                                  {/*<tr>
                                  <th>
                                    <input
                                      id="targeting_geo_all"
                                      type="checkbox"
                                      checked={!targeting_geo_set.size}
                                      onChange={
                                        e => this.setState({
                                          targeting_geo: !e.target.checked ? [this.props.regions[getRandomInt(this.props.regions.length)].id] : []
                                        })
                                      } />

                                  </th>
                                  <th>
                                    <label htmlFor="targeting_geo_all">Весь Казахстан</label>
                                  </th>
                                </tr>*/}
                                  {(this.props.regions || []).map(({ id, name }) => (
                                    <tr>
                                      <td style={{ paddingRight: 5 }}>
                                        <input
                                          id={`targeting_geo_${id}`}
                                          type="checkbox"
                                          disabled={disabled}
                                          checked={targeting_geo_set.size && targeting_geo_set.has(id)}
                                          onChange={(e) => {
                                            const newSet = new Set(targeting_geo_set);
                                            newSet[e.target.checked ? "add" : "delete"](id);
                                            this.setState(
                                              {
                                                targeting_geo: Array.from(newSet),
                                              },
                                              (_) => this.targetingChange()
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <label style={{ margin: 0 }} htmlFor={`targeting_geo_${id}`}>
                                          {name}
                                        </label>
                                      </td>
                                    </tr>
                                  ))}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-12 text-center">
                            {disabled ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <a className="btn btn-sm btn-success" href={`/checkout/${this.props.params.mediaplan}`}>
                                  Посмотреть заказ
                                </a>
                                <Button
                                  disabled={!parseInt(this.state.amount)}
                                  className="ml-3"
                                  color="primary"
                                  size="sm"
                                  target="_blank"
                                  outline={true}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.getDownloadToken({
                                      path: `/order/invoice/${this.props.mediaplans.item.id}/`,
                                      blank: true,
                                    });
                                  }}
                                >
                                  Скачать PDF файл {this.props.mediaplans.item.status_type === 1 && " (черновик)"}
                                </Button>
                              </div>
                            ) : (
                              <React.Fragment>
                                Введите реквизиты, ознакомьтесь с условиями оферты, и отправьте заказ на обработку.
                                Затем оплатите заказ согласно сформированным документам.
                                <br />
                                <br />
                                <div className="d-flex justify-content-center align-items-center">
                                  {!this.state.can_checkout ? (
                                    <button disabled className="btn btn-sm btn-success">
                                      Ввести реквизиты
                                    </button>
                                  ) : (
                                    <a
                                      className="btn btn-sm btn-success"
                                      href={`/checkout/${this.props.params.mediaplan}`}
                                    >
                                      Ввести реквизиты
                                    </a>
                                  )}
                                  <Button
                                    disabled={!parseInt(this.state.amount)}
                                    className="ml-3"
                                    color="primary"
                                    size="sm"
                                    target="_blank"
                                    outline={true}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.getDownloadToken({
                                        path: `/order/invoice/${this.props.mediaplans.item.id}/`,
                                        blank: true,
                                      });
                                    }}
                                  >
                                    Скачать PDF файл {this.props.mediaplans.item.status_type === 1 && " (черновик)"}
                                  </Button>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
      </Loadable>
    );
  }
}
