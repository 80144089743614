import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export default class Cookies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      close: this.getCookie("cookies"),
    };
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return false;
  }

  toggle() {
    this.setCookie("cookies", true, 356);
    this.setState({
      close: true,
    });
  }

  render() {
    return (
      <Modal isOpen={!this.state.close}>
        <ModalHeader toggle={this.toggle.bind(this)}>Уведомление об использовании cookies</ModalHeader>
        <ModalBody>
          Наш сайт использует файлы cookie и похожие технологии, чтобы гарантировать максимальное удобство
          пользователям, предоставляя персонализированную информацию, запоминая предпочтения, а также помогая получить
          правильную информацию. При использовании данного сайта, вы подтверждаете своё согласие на использование файлов
          cookie в соответствии с настоящим уведомлением в отношении данного типа файлов.
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.toggle.bind(this)} color="primary">
            Согласен
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
