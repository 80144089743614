import React from "react";
import { NotificationManager } from "react-notifications";
import { consumeNotificationStack } from "./notificationStack";

import "bootstrap/scss/bootstrap.scss";
import "react-calendar-timeline/lib/Timeline.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "status-indicator/styles.css";
import "./style/react-datepicker.scss";
import "./style/react-day-picker.scss";
import "./style/notifications.scss";
import "./style/time-table.scss";
import "./style/animate.scss";
import "./style/custom.scss";
import "./style/toggle.scss";
import "./style/slider.scss";
import "./style/footer.scss";

const Internal = ({ children }) => children;
export default Internal;
