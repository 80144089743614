import React from "react";
import { Row, Col, Label, Button } from "reactstrap";
import Toggle from "react-toggle";
import Currency from "react-currency-formatter";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import isAfter from "date-fns/isAfter";
import moment from "moment";

registerLocale("ru", ru);

const FilterToggle = ({ id, title, parentComponent, k }) => {
  return (
    <label className="react-toggle-item">
      <Toggle
        checked={parentComponent.state[k].has(id)}
        onChange={(e) => {
          e.persist();
          var filterValue = new Set(parentComponent.state[k]);
          filterValue[e.target.checked ? "add" : "delete"](id);
          parentComponent.setState({
            [k]: filterValue,
            index: 0,
            selected: new Set(),
          });
        }}
      />
      <span>{title}</span>
    </label>
  );
};

export default class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      index: 0,
      statusTypeFilter: new Set([1, 2, 3]),
      mediaTypeFilter: new Set(["tv", "radio", "internet", "sponsor", "outdoor"]),
      startDate: "",
      endDate: "",
      selected: new Set(),
    };
  }

  componentDidMount() {
    this.props.onListReports();
  }

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    if (isAfter(startDate, endDate)) {
      endDate = startDate;
    }

    this.setState({
      index: 0,
      startDate: startDate,
      endDate: endDate,
    });
  };

  handleChangeStart = (startDate) => this.handleChange({ startDate });

  handleChangeEnd = (endDate) => this.handleChange({ endDate });

  render() {
    let ids = "";
    if (this.state.selected.size) {
      ids = Array.from(this.state.selected);
      ids.sort();
      ids = ids.join(",");
    }
    let price_totals = 0,
      counts = 0,
      durations = 0,
      ratings = 0;

    const statusTypeFilters = [
      { id: 1, title: "Черновики" },
      { id: 2, title: "Активные" },
      { id: 3, title: "Архивные" },
    ];

    const mediaTypeFilters = [
      { id: "tv", title: "ТВ" },
      { id: "radio", title: "Радио" },
      { id: "sponsor", title: "Спонсорство" },
      { id: "outdoor", title: "Наружная реклама" },
      { id: "internet", title: "Интернет" },
    ];

    return (
      <div>
        <Row className="pt-2">
          <Col md="12" className="pb-2">
            <div className="form-group report-period-date-picker">
              <Label for="periodSelect">
                <b>Период:</b>
              </Label>{" "}
              <DatePicker
                selected={this.state.startDate}
                selectsStart
                className="form-control"
                dateFormat="dd.MM.yyyy"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                locale="ru"
                onChange={this.handleChangeStart.bind(this)}
              />
              <DatePicker
                selected={this.state.endDate}
                selectsEnd
                locale="ru"
                dateFormat="dd.MM.yyyy"
                className="form-control"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.handleChangeEnd.bind(this)}
              />
            </div>
          </Col>
        </Row>
        <div className="mediaplan-controls-layout">
          <div className="mediaplan-controls-1">
            <div className="mediaplan-params-outer-list">
              <ul className="mediaplan-params-list">
                <li className="label-element">Статус:</li>
                {statusTypeFilters.map(({ id, title }) => {
                  return (
                    <li key={"k" + id}>
                      <FilterToggle id={id} title={title} parentComponent={this} k="statusTypeFilter" />
                    </li>
                  );
                })}
              </ul>
              <ul className="mediaplan-params-list">
                <li className="label-element">Медиа:</li>
                {mediaTypeFilters.map(({ id, title }) => {
                  return (
                    <li key={"k" + id}>
                      <FilterToggle id={id} title={title} parentComponent={this} k="mediaTypeFilter" />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <Row className="pt-2 pb-5">
          <Col md="12">
            <div className="reports-grid the-top-row">
              <div>&nbsp;</div>
              <div>Дата</div>
              <div>Номер договора</div>
              <div>Заказ</div>
              <div>Медиа</div>
              <div className="text-center">Валюта заказа</div>
              <div className="text-right">Стоимость (KZT)</div>
              <div className="text-center">Кол-во выходов</div>
              <div className="text-center">Хронометраж</div>
              <div className="text-right">GRP</div>
              <div className="text-right">Охват</div>
            </div>
            <div className="reports-grid">
              {this.props.mediaplans.result.map((mediaplan, i) => {
                if (!this.state.statusTypeFilter.has(mediaplan.status_type)) {
                  return null;
                }

                if (!this.state.mediaTypeFilter.has(mediaplan.media_type)) {
                  return null;
                }

                const mediaplanCreationDate = moment(mediaplan.get_date, "DD.MM.YYYY").startOf("day");
                if (this.state.startDate != "") {
                  if (!mediaplanCreationDate.isValid()) return null;
                  if (!mediaplanCreationDate.isSameOrAfter(moment(this.state.startDate))) {
                    return null;
                  }
                }

                if (this.state.endDate != "") {
                  if (!mediaplanCreationDate.isValid()) return null;
                  if (!mediaplanCreationDate.isSameOrBefore(moment(this.state.endDate))) {
                    return null;
                  }
                }

                this.state.index += 1;
                price_totals += parseFloat(mediaplan.get_stats.amount_to_pay_kzt);
                counts += parseInt(mediaplan.get_stats.count);
                ratings += parseFloat(mediaplan.get_stats.rating);
                durations += parseInt(mediaplan.get_stats.duration1) + parseInt(mediaplan.get_stats.duration2);

                return (
                  <React.Fragment>
                    <div>
                      <input
                        type="checkbox"
                        checked={this.state.selected.has(mediaplan.id)}
                        onChange={(e) => {
                          const currentSelected = new Set(this.state.selected);
                          const action = e.target.checked ? "add" : "delete";
                          currentSelected[action](mediaplan.id);
                          this.setState({ selected: currentSelected });
                        }}
                      />
                    </div>
                    <div>{mediaplan.get_date}</div>
                    <div>{mediaplan.contract_id}</div>
                    <div>#{mediaplan.id}</div>
                    <div>{mediaplan.get_channel.short_name}</div>
                    <div className="text-center">{mediaplan.get_stats.currency}</div>
                    <div className="text-right">
                      <Currency
                        quantity={mediaplan.get_stats.amount_to_pay_kzt}
                        currency="KZT"
                        symbol=""
                        decimal=","
                        pattern={"#,##0.00\u00a0!"}
                        group={"\u00a0"}
                      />
                    </div>
                    <div className="text-center">
                      {mediaplan.media_type === "outdoor" || mediaplan.media_type === "internet"
                        ? "-"
                        : mediaplan.get_stats.count}
                    </div>
                    <div className="text-center">
                      {mediaplan.media_type === "outdoor" || mediaplan.media_type === "internet"
                        ? "-"
                        : mediaplan.get_stats.duration1 + mediaplan.get_stats.duration2}
                    </div>
                    <div className="text-right">
                      {isNaN(mediaplan.get_stats.rating) ? "-" : mediaplan.get_stats.rating}
                    </div>
                    <div className="text-right">{mediaplan.get_analytics.rch1 || "-"}</div>
                  </React.Fragment>
                );
              }, this)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" class="pt-2 pb-5">
            <Button
              disabled={this.state.index == 0}
              onClick={(e) => {
                let params = {};
                if (ids) {
                  params = {
                    ...params,
                    ids,
                  };
                } else {
                  params = {
                    ...params,
                    start: this.state.startDate != "" ? moment(this.state.startDate).format("DD-MM-YYYY") : "",
                    end: this.state.endDate != "" ? moment(this.state.endDate).format("DD-MM-YYYY") : "",
                    statusTypeFilter: Array.from(this.state.statusTypeFilter).sort().join(","),
                    mediaTypeFilter: Array.from(this.state.mediaTypeFilter).sort().join(","),
                  };
                }
                e.preventDefault();
                this.props.getDownloadToken({
                  path: "/order/reports/pdf/",
                  params,
                  blank: true,
                });
              }}
              color="primary"
            >
              Скачать в PDF
            </Button>{" "}
            <Button
              disabled={this.state.index == 0}
              onClick={(e) => {
                let params = {};
                if (ids) {
                  params = {
                    ...params,
                    ids,
                  };
                } else {
                  params = {
                    ...params,
                    start: this.state.startDate != "" ? moment(this.state.startDate).format("DD-MM-YYYY") : "",
                    end: this.state.endDate != "" ? moment(this.state.endDate).format("DD-MM-YYYY") : "",
                    statusTypeFilter: Array.from(this.state.statusTypeFilter).sort().join(","),
                    mediaTypeFilter: Array.from(this.state.mediaTypeFilter).sort().join(","),
                  };
                }
                e.preventDefault();
                this.props.getDownloadToken({
                  path: "/order/reports/xls/",
                  params,
                });
              }}
              color="primary"
            >
              Скачать в XLS
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
