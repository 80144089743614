import React from "react";
import { Container } from "reactstrap";
import { NotificationContainer } from "react-notifications";
import Header from "../containers/header";
import Playlists from "../containers/playlists";

export default class ResultScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container className="time-result" fluid={true}>
        <NotificationContainer />
        <Header check={true} />
        <Playlists mediaplan={this.props.params.mediaplan} />
      </Container>
    );
  }
}
