import { LIST_CHANNELS_AND_PARAMS, CALC_CROSS, RESET_CROSS } from "./types";

import API from "../api/list";

export const getChannelsAndParams = (callback) => {
  return (dispatch) => {
    API.getChannelsAndParams().then(function (list) {
      if (callback) callback(list);
      dispatch(listChannelsAndParams(list));
    });
  };
};

export const listChannelsAndParams = (list) => ({
  type: LIST_CHANNELS_AND_PARAMS,
  payload: {
    list: list,
  },
});

export const calcCross = (ids) => {
  return (dispatch) => {
    API.calcCross(ids).then(function (list) {
      dispatch(listCalcCross(list));
    });
  };
};

export const listCalcCross = (data) => ({
  type: CALC_CROSS,
  payload: {
    data: data,
  },
});

export const resetCross = () => ({
  type: RESET_CROSS,
  payload: {},
});
