import {
  GET_PLAYLISTS,
  ADD_MEDIAPLAN_MEDIA_START,
  ADD_MEDIAPLAN_MEDIA_PROGRESS,
  ADD_MEDIAPLAN_MEDIA_STOP,
} from "../actions/types";

const initialState = {
  loading: true,
  media_loading: false,
  media_loading_percent: 0,
  list: {
    playlists: [],
    media: [],
    mediaplan: {
      get_analytics: {
        rch: [],
        analytics_limit: 999,
        channel: "",
        region: "",
        mediaplan: 0,
        weeks: 0,
        r_total: 0,
        r_prime: 0,
        percentage_prime: 0,
        rch1: 0,
        rch3: 0,
        rch5: 0,
        ots: 0,
        duration: 5,
        total_duration: 0,
        total_price_kzt: 0,
        grp30: 0,
        cpp: 0,
        cpt: 0,
      },
      default_media: 30,
    },
    groups: [],
    start_date: null,
    end_date: null,
    get_stats: {
      count: 0,
      duration: 0,
      duration1: 0,
      duration2: 0,
      resident: false,
      currency: "KZT",
      pretax_amount: 0,
      total_price_kzt: 0,
      exchange_rate: 0,
      amount_to_pay: 0,
      amount_to_pay_kzt: 0,
      vat_rate: 0,
      vat_amount: 0,
    },
  },
};

export default function playlistsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PLAYLISTS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
      };
    case ADD_MEDIAPLAN_MEDIA_START:
      return {
        ...state,
        media_loading: true,
        media_loading_percent: 0,
      };
    case ADD_MEDIAPLAN_MEDIA_PROGRESS:
      return {
        ...state,
        media_loading_percent: action.payload.progress,
      };
    case ADD_MEDIAPLAN_MEDIA_STOP:
      return {
        ...state,
        media_loading: false,
      };
    default:
      return state;
  }
}
