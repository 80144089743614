import { LIST_REPORTS, GOT_DOWNLOAD_TOKEN } from "./types";

import API from "../api/list";

export const getReports = () => {
  return (dispatch) => {
    API.getReports().then(function (list) {
      dispatch(listReports(list));
    });
  };
};

export const listReports = (list) => ({
  type: LIST_REPORTS,
  payload: {
    list: list,
  },
});

export const getDownloadToken = (params) => {
  return (dispatch) => {
    API.getReportsDownloadToken(params).then(function (result) {
      //dispatch(gotDownloadToken(result));
      const url = new URL(`${window.location.protocol}//${window.location.host}${result.path}`);
      for (const [k, v] of Object.entries(result.params)) {
        url.searchParams.set(k, v);
      }
      const link = document.createElement("a");
      link.href = url.href;
      if (params.blank) link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
};

export const gotDownloadToken = (downloadToken) => ({
  type: GOT_DOWNLOAD_TOKEN,
  payload: {
    downloadToken,
  },
});
