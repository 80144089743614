import React from "react";
import {
  Card,
  Button,
  CardBody,
  CardText,
  ButtonGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import Sponsor from "../modal/sponsor";

const descriptions = {
  minutes: (
    <React.Fragment>
      <PopoverHeader>Размещение по минутам</PopoverHeader>
      <PopoverBody>
        <p>
          <strong>Размещение по минутам</strong> – покупка эфирного времени телеканала в определенных временных
          промежутках, при этом стоимость рекламного ролика зависит от его длительности. Например, 1 минута стоит 30 000
          тенге, хронометраж ролика – 30 сек., следовательно, 1 выход ролика в эфир – 15 000 тенге.
        </p>
      </PopoverBody>
    </React.Fragment>
  ),
  grp: (
    <React.Fragment>
      <PopoverHeader>Размещение по GRP</PopoverHeader>
      <PopoverBody>
        <p>
          Рейтинг рекламного блока – отношение количества телезрителей, посмотревших рекламу, к общему количеству
          человек базовой аудитории (БА).
          <br />
          Например, БА НТК: «все люди в возрасте 6-54 лет» - 6 077 000 человек. 1 GRP30" = 1% от 6 077 000 чел = 60 770
          чел. Значит, при покупке 20 рейтингов (30-секундного ролика) состоится 1 215 400 контактов с рекламным
          сообщением.
        </p>
        <p>
          Нажав на кнопку «Аналитика», можно узнать, какой охват – процент телеаудитории – увидит рекламу не меньше 1,
          3, 5 раз.
        </p>
        <p>
          <strong>Обратите внимание: покупка рейтингов возможна не на всех телеканалах.</strong>
        </p>
      </PopoverBody>
    </React.Fragment>
  ),
};

const descriptionLabels = {
  minutes: "Что это такое?",
  grp: "Что это такое?",
};

const HelperLink = ({ type }) => {
  const description = descriptions[type];
  return description ? (
    <div>
      <small>
        {" "}
        <a
          id={`help-${type}`}
          href={`#help-${type}`}
          style={{
            borderBottom: "1px dotted",
            textDecoration: "none",
          }}
          color="secondary"
          onClick={(e) => e.preventDefault()}
        >
          {descriptionLabels[type] || "Что это такое?"}
        </a>
        <UncontrolledPopover trigger="focus" placement="left" target={`help-${type}`}>
          {description}
        </UncontrolledPopover>
      </small>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default class Format extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsor_type: false,
    };
  }

  render() {
    return (
      <Card className="text-center align-items-center">
        <CardBody>
          <CardText className="mx-auto p-3">
            {!this.state.sponsor_type ? (
              <ul className="nav list-inline">
                <li className="list-inline-item">
                  <Button
                    outline
                    onClick={() => {
                      this.props.setStates({
                        screen: 3,
                        media_type: "tv",
                        tv_pricing_model: "minutes",
                      });
                    }}
                    color="primary"
                  >
                    Размещение по минутам
                  </Button>
                  <HelperLink type="minutes" />
                </li>
                <li className="list-inline-item">
                  <Button
                    outline
                    onClick={() => {
                      this.setState({
                        sponsor_type: true,
                      });
                      this.props.setStates({
                        screen: 3,
                        media_type: "tv",
                        tv_pricing_model: "grp",
                      });
                    }}
                    color="primary"
                  >
                    Размещение по GRP
                  </Button>
                  <HelperLink type="grp" />
                </li>
                <li className="list-inline-item">
                  <Button
                    outline
                    onClick={() => {
                      this.setState({
                        sponsor_type: true,
                      });
                      this.props.setStates({
                        media_type: "sponsor",
                      });
                    }}
                    type="button"
                    color="primary"
                  >
                    Спонсорство
                  </Button>
                  <div>
                    <Sponsor />
                  </div>
                </li>
              </ul>
            ) : (
              <div>
                <ButtonGroup>
                  <Button
                    color="primary"
                    outline
                    onClick={() => {
                      this.props.setStates({
                        screen: 3,
                        type_sponsor: "dyno",
                        media_type: "sponsor",
                      });
                    }}
                  >
                    Динамическая заставка
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={() => {
                      this.props.setStates({
                        screen: 3,
                        type_sponsor: "banner",
                        media_type: "sponsor",
                      });
                    }}
                  >
                    Баннер
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={() => {
                      this.props.setStates({
                        modal: true,
                      });
                    }}
                  >
                    Другие виды спонсорства
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </CardText>
        </CardBody>
      </Card>
    );
  }
}
