import { LIST_EDITOR_DATA, LOADING_START } from "../actions/types";

const initialState = {
  list: {
    playlist: [],
    days: [
      {
        0: "Понедельник",
        1: "Вторник",
        2: "Среда",
        3: "Четверг",
        4: "Пятница",
        5: "Суббота",
        6: "Воскресенье",
      },
    ],
    weekday: [],
    mediaplan: {
      get_channel: null,
      get_stats: {
        resident: false,
        currency: "KZT",
        pretax_amount: 0,
        total_price_kzt: 0,
        exchange_rate: 0,
        amount_to_pay: 0,
        amount_to_pay_kzt: 0,
        vat_rate: 0,
        vat_amount: 0,
      },
    },
    chunks_block: [],
    chunks: [],
    date: "",
    sales_count: 0,
  },
  loading: true,
};

export default function editorReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_EDITOR_DATA:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
      };
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
