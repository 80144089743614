import React from "react";
import update from "immutability-helper";
import { Card, Col, CardHeader, CardBody, Button, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";

// begin

export default class AirtimeReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      months: [], // set of {value: '2022-11-01', label: '2022-11'} objects
      // (value of React Select)
      channels: {}, // set of channel ids with true/false keys
      // (to use with immutability helpers's toggle fn)
    };
  }

  componentDidMount() {
    this.props.onGetMonthsAndChannels();
  }

  render() {
    if (!this.props.monthsAndChannels?.data) return <></>;
    const channelsForMonths = (months) => {
      let result = [];
      const monthsVals = new Set(months.map((m) => m.value));
      for (const { month, channels } of this.props.monthsAndChannels.data) {
        if (monthsVals.has(month)) {
          for (const c of channels) {
            if (!result.map((r) => r.id).includes(c.id)) {
              result.push(c);
            }
          }
        }
      }
      return result;
    };
    const availableChannels = channelsForMonths(this.state.months);
    const mapOfKeys = (arr) => {
      const r = {};
      for (const e of arr) r[e] = true;
      return r;
    };
    return (
      <div className="pt-3">
        {/*<code>{JSON.stringify(this.state.channels)}</code>*/}
        <Card>
          <CardHeader>Эфирные справки</CardHeader>
          <CardBody>
            <div className="font-weight-bold">Об эфирных справках</div>
            <p>
              Этот инструмент позволит вам сформировать эфирные справки по всем вашим медиапланам, которые были помечены
              как <strong>архивные</strong> администратором канала. Выберите из выпадающего списка один или более
              месяцев, и далее пометьте каналы, на которых проводились рекламные камапнии в течение выбранных месяцев.
              По нажатию кнопки вы сможете скачать файл в формате Excel, содержащий по одной эфирной справке на каждый
              месяц и каждый канал.
            </p>
            <FormGroup row>
              <Label sm={2}>Месяцы</Label>
              <Col sm={10}>
                <Select
                  isClearable
                  isMulti
                  className="w-100"
                  placeholder={`Выберите месяцы`}
                  aria-label={`Выберите месяцы`}
                  aria-labelledby={`Выберите месяцы`}
                  noOptionsMessage={(inputValue) => "Нет данных"}
                  value={this.state.months}
                  options={(this.props.monthsAndChannels?.data || []).map(({ month }) => ({
                    value: month,
                    label: month.substr(0, 7),
                  }))}
                  onChange={(months) => {
                    const cIds = new Set(channelsForMonths(months).map((r) => r.id));
                    this.setState(
                      update(this.state, {
                        months: { $set: months },
                        channels: {
                          $apply: (channels) =>
                            mapOfKeys(
                              Object.entries(channels)
                                .filter(([k, v]) => v)
                                .map(([k, v]) => k)
                                .filter((c) => cIds.has(c.id))
                            ),
                        },
                      })
                    );
                  }}
                />
              </Col>
            </FormGroup>
            {!!availableChannels.length ? (
              <FormGroup row>
                <Label sm={2}>Каналы</Label>
                <Col sm={10}>
                  {this.state.months && (
                    <FormGroup check inline>
                      {availableChannels.map(({ id, short_name }) => {
                        return (
                          <React.Fragment key={id}>
                            <Input
                              id={`select-mediaplan-${id}`}
                              type="checkbox"
                              className="mx-3 my-3"
                              checked={!!this.state.channels[id]}
                              onChange={(e) => {
                                this.setState(update(this.state, { channels: { $toggle: [id] } }));
                              }}
                            />{" "}
                            <Label check htmlFor={`select-mediaplan-${id}`}>
                              {short_name}
                            </Label>
                          </React.Fragment>
                        );
                      })}
                    </FormGroup>
                  )}
                </Col>
              </FormGroup>
            ) : null}
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.props.getDownloadToken({
                  path: `/api/v1/playlist/airtime-report/?channels=${Object.entries(this.state.channels)
                    .filter(([k, v]) => v)
                    .map(([k, v]) => k)
                    .join(",")}&months=${this.state.months.map((m) => m.value.substr(0, 7)).join(",")}`,
                  blank: false,
                });
              }}
              disabled={!Object.entries(this.state.channels || {}).filter(([k, v]) => v).length}
              color="primary"
              size="lg"
              block
            >
              Сгенерировать эфирные справки
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }
}
