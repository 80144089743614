import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from "reactstrap";

export default class Sponsor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      close: true,
    };
  }

  toggle() {
    this.setState({
      close: !this.state.close,
    });
  }

  render() {
    return (
      <span>
        <small>
          {" "}
          <a
            href="#help-sponsor"
            style={{
              borderBottom: "1px dotted",
              textDecoration: "none",
            }}
            onClick={this.toggle.bind(this)}
            color="secondary"
          >
            Что это такое?
          </a>
        </small>
        <Modal size="lg" isOpen={!this.state.close}>
          <ModalHeader>Что такое спонсорство?</ModalHeader>
          <ModalBody>
            <Row className="align-items-center">
              <Col md={6}>
                <video
                  style={{
                    width: "100%",
                  }}
                  autobuffer=""
                  autoplay="autoplay"
                  loop="loop"
                  muted="muted"
                >
                  <source src="https://mediaplatform.online/media/channel/sponsor/din.mp4" type="video/mp4" />
                </video>
              </Col>
              <Col md={6} className="mx-auto">
                <h5 className="text-center">DYNAMIC STATION BREAK (ДИНАМИЧЕСКАЯ ЗАСТАВКА)</h5>
                <p>7-ми секундная заставка, размещаемая перед рекламным блоком.</p>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center">
              <Col md={6} className="mx-auto">
                <h5 className="text-center">BANNER (БАННЕР)</h5>
                <p>
                  Рекламно-информационный анимированный модуль, располагающийся в нижней части экрана (до 10 секунд) без
                  прерывания программы.
                </p>
              </Col>
              <Col md={6}>
                <video
                  style={{
                    width: "100%",
                  }}
                  autobuffer=""
                  autoplay="autoplay"
                  loop="loop"
                  muted="muted"
                >
                  <source src="https://mediaplatform.online/media/channel/sponsor/banner.mp4" type="video/mp4" />
                </video>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggle.bind(this)} color="primary">
              Закрыть
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}
