import React from "react";
import { Field, reduxForm } from "redux-form";
import { Card, Col, Row, CardHeader, CardBody, Button } from "reactstrap";

import { passwordChange } from "../../actions/users";
import { renderField } from "../../components/renderField";

class ChangePasswordForm extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      old_password: false,
      new_password1: false,
      new_password2: false,
    };
  }

  initState() {
    this.setState({
      loading: false,
      old_password: false,
      new_password1: false,
      new_password2: false,
    });
  }

  submit(values) {
    passwordChange(values);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="pt-3">
        <Card>
          <div>
            <CardHeader>Смена пароля</CardHeader>
          </div>
          <CardBody>
            <form onSubmit={handleSubmit(this.submit.bind(this))} noValidate>
              <Row>
                <Col>
                  <Field
                    name="old_password"
                    type="password"
                    label="Старый пароль"
                    error={this.state.old_password}
                    component={renderField}
                    placeholder="Ваш старый пароль"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="new_password1"
                    type="password"
                    label="Новый пароль"
                    error={this.state.new_password1}
                    component={renderField}
                    placeholder="Ваш новый пароль"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="new_password2"
                    type="password"
                    label="Новый пароль (повтор)"
                    error={this.state.new_password2}
                    component={renderField}
                    placeholder="Ваш новый пароль (повтор)"
                  />
                </Col>
              </Row>
              <Row className="text-center">
                <Col>
                  <Button color="primary">Обновить</Button>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default reduxForm({
  form: "changePasswordForm",
})(ChangePasswordForm);
