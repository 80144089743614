import React from "react";
import { Field, reduxForm } from "redux-form";
import { Col, Row, Card, CardBody, Button, CardTitle } from "reactstrap";
import { browserHistory } from "react-router";

import API from "../../api/list";
import { renderField } from "../../components/renderField";

class ResetForm extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      new_password1: false,
      new_password2: false,
      errors: [],
    };
    this.initState = this.initState.bind(this);
  }

  initState() {
    this.setState({
      loading: false,
      new_password1: false,
      new_password2: false,
      errors: [],
    });
  }

  changePassword(values) {
    values["uid"] = this.props.params.token.split("-")[0];
    values["token"] = this.props.params.token.replace(values["uid"] + "-", "");
    this.setState({
      loading: true,
    });
    API.resetConfirm(values).then((response) => {
      this.initState();
      switch (response.status) {
        case 200:
          browserHistory.push(`/reset/done/`);
          break;
        case 400:
          response.json().then((data) => {
            Object.keys(data).forEach(function (element) {
              let obj = {};
              obj[element] = true;
              this.setState(obj);
              let errors = this.state.errors;
              errors.push(data[element].toString());
              this.setState({
                errors: errors,
              });
            }, this);
          }, this);
          break;
      }
    }, this);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Card>
        <CardBody>
          <CardTitle>Восстановить пароль</CardTitle>
          <hr />
          <form onSubmit={handleSubmit(this.changePassword.bind(this))} noValidate>
            <Row>
              <Col>
                <Field
                  name="new_password1"
                  type="password"
                  label="Пароль"
                  error={this.state.new_password1}
                  component={renderField}
                  placeholder="Ваш пароль"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="new_password2"
                  type="password"
                  label="Повторить пароль"
                  error={this.state.new_password2}
                  component={renderField}
                  placeholder="Повторить пароль"
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <Button color="primary">Изменить пароль</Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default reduxForm({
  form: "resetForm",
})(ResetForm);
