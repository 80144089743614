import { connect } from "react-redux";
import DownloadButton from "../components/reportDownloadButton";
import { getDownloadToken } from "../actions/reports";

const mapDispatchToProps = (dispatch) => {
  return {
    getDownloadToken: (params) => {
      return dispatch(getDownloadToken(params));
    },
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton);
