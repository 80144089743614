import { connect } from "react-redux";
import Editor from "../components/editor";
import { getEditorData, loadingStart } from "../actions/editor";

const mapDispatchToProps = (dispatch) => {
  return {
    onListEditorData: (mediaplan, weekend, weekdays, search, rate, only_sales) => {
      return dispatch(getEditorData(mediaplan, weekend, weekdays, search, rate, only_sales));
    },
    onLoadingStart: () => {
      return dispatch(loadingStart());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    list: state.editor.list,
    loading: state.editor.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
