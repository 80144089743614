import { LIST_MEDIAPLANS, GET_MEDIAPLAN, START_MEDIAPLAN_CHECK, DID_MEDIAPLAN_CHECK } from "../actions/types";

const initialState = {
  list: [],
  item: {
    contract: "",
    year: "",
    month: "",
    get_channel: {
      name: "",
    },
    mediaplan: {
      order_submitted: false,
      id: 0,
    },
    get_budget: 0,
    get_stats: {
      count: 0,
      duration1: 0,
      duration2: 0,
    },
    get_playlists: [],
  },
  loading: true,
  shim_text: null,
};

export default function mediaplanReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_MEDIAPLANS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
      };
    case GET_MEDIAPLAN:
      return {
        ...state,
        loading: false,
        item: action.payload.item,
      };
    case START_MEDIAPLAN_CHECK:
      return {
        ...state,
        loading: true,
        shim_text: "Подождите, идёт обновление данных...",
      };
    case DID_MEDIAPLAN_CHECK:
      return {
        ...state,
        shim_text: null,
      };
    default:
      return state;
  }
}
