import { connect } from "react-redux";
import SpotModal from "../screens/modal/spot_modal";

import { loadingStop, loadingStart, updatePlaylist, cancelPlaylist } from "../actions/spot_modal";

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadingStart: () => {
      return dispatch(loadingStart());
    },
    onLoadingStop: () => {
      return dispatch(loadingStop());
    },
    onCancelPlaylist: (mediaplan, blocks, weekend, weekdays, search, rate, only_sales) => {
      return dispatch(cancelPlaylist(mediaplan, blocks, weekend, weekdays, search, rate, only_sales));
    },
    onUpdatePlaylist: (mediaplan, block, type, duration, lang, weekend, weekdays, search, rate, only_sales) => {
      return dispatch(
        updatePlaylist(mediaplan, block, type, duration, lang, weekend, weekdays, search, rate, only_sales)
      );
    },
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SpotModal);
