import { LIST_REGIONS } from "./types";

import API from "../api/list";

export const getRegions = (type, pricing, type_sponsor) => {
  return (dispatch) => {
    API.getDashboardList(type, pricing, type_sponsor).then(function (data) {
      dispatch(listRegions(data, type));
    });
  };
};

export const listRegions = (data, type) => {
  let regions = data.regions;
  if (type === "outdoor_advertising") {
    regions = regions.filter((region) => region?.get_channels?.length);
  }
  return {
    type: LIST_REGIONS,
    payload: {
      regions,
    },
  };
};
