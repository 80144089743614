import React from "react";
import { Container, Row, Col } from "reactstrap";
import { NotificationContainer } from "react-notifications";

import RegistrationForm from "./forms/registration";
import Header from "../containers/header";
import Footer from "../components/footer";

import API from "../api/list";

export default class RegistrationScreen extends React.Component {
  render() {
    return (
      <div>
        <Container fluid={true}>
          <Header check={false} />
          <Row>
            <Col md="3" />
            <Col md="6">
              <RegistrationForm />
            </Col>
            <Col md="3" />
          </Row>
          <NotificationContainer />
        </Container>
        <Footer />
      </div>
    );
  }
}
