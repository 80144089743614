import React from "react";
import { IndexRoute, Router, Route, browserHistory } from "react-router";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { syncHistoryWithStore } from "react-router-redux";
import thunk from "redux-thunk";
import mainStore from "./reducers/store";

import App from "./App";
import Internal from "./Internal";

import LoginScreen from "./screens/login";
import RegistrationScreen from "./screens/registration";
import ForgetScreen from "./screens/forget";
import ResetDoneScreen from "./screens/resetDone";
import ResetScreen from "./screens/reset";

import ProfileScreen from "./screens/profile";
import PageScreen from "./screens/page";
import MediaplansScreen from "./screens/mediaplans";
import DashboardScreen from "./screens/dashboard";
import EditorScreen from "./screens/editor";
import ResultScreen from "./screens/result";
import CheckoutScreen from "./screens/checkout";
import MediaScreen from "./screens/media";
import OutdoorScreen from "./screens/outdoor";
import OutdoorSteps from "./containers/outdoorSteps";

// Profile
import Profile from "./containers/profile";
import Contract from "./containers/contract";
import ChangePasswordForm from "./screens/forms/changePassword";
import Transactions from "./containers/transactions";
import Reports from "./containers/reports";
import AirtimeReports from "./containers/airtimeReports";
import Cross from "./containers/cross";
import AdditionalInfo from "./screens/forms/additionalInfo";

import OnBoard from "./containers/onboard";
import CreateInternet from "./containers/createInternet";
import EditInternet from "./containers/editInternet";
import Calculator from "./components/calculator";

const store = createStore(mainStore, composeWithDevTools(applyMiddleware(thunk)));
const history = syncHistoryWithStore(browserHistory, store);

const Dashboard = (_) => (
  <DashboardScreen>
    <OnBoard />
  </DashboardScreen>
);

const OutdoorCreate = (_) => (
  <DashboardScreen>
    <OutdoorSteps />
  </DashboardScreen>
);

const CalculatorScreen = (_) => (
  <DashboardScreen>
    <Calculator />
  </DashboardScreen>
);

export default () => (
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App}>
        <IndexRoute component={Calculator} />
        <Route component={Internal}>
          <Route path="login" component={LoginScreen} />
          <Route path="registration" component={RegistrationScreen} />
          <Route path="forget" component={ForgetScreen} />
          <Route path="outdoor/">
            <Route path="create" component={OutdoorCreate} />
            <Route path=":mediaplan" component={OutdoorScreen} />
          </Route>
          <Route path="calculator" component={CalculatorScreen} />
          <Route path="reset/done" component={ResetDoneScreen} />
          <Route path="reset/:token" component={ResetScreen} />
          <Route path="profile/" component={ProfileScreen}>
            <Route path="edit" component={Profile} />
            <Route path="password" component={() => <ChangePasswordForm />} />
            <Route path="transactions" component={Transactions} />
            <Route path="report" component={Reports} />
            <Route path="airtime-reports" component={AirtimeReports} />
            <Route path="cross" component={Cross} />
            <Route path="contract" component={Contract} />
            <Route path="additional" component={AdditionalInfo} />
          </Route>
          <Route path="page" component={PageScreen} />
          <Route path="mediastock" component={MediaScreen} />
          <Route path="checkout/:mediaplan" component={CheckoutScreen} />
          <Route path="mediaplans" component={MediaplansScreen} />
          <Route path="dashboard" component={Dashboard} />
          <Route path="internet/" component={DashboardScreen}>
            <Route path="create" component={CreateInternet} />
            <Route path=":mediaplan" component={EditInternet} />
          </Route>
          <Route path="editor/:mediaplan" component={EditorScreen} />
          <Route path="result/:mediaplan" component={ResultScreen} />
          <Route path="*" component={LoginScreen} />
        </Route>
      </Route>
    </Router>
  </Provider>
);
