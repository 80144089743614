import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import { Row, Col, Button, Container, Card, CardHeader } from "reactstrap";
import ToggleDisplay from "react-toggle-display";
import Loadable from "react-loading-overlay";
import Currency from "react-currency-formatter";
import Toggle from "react-toggle";
import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: "btn btn-success",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false,
});

const OpenButton = ({ href }) => {
  return (
    <Button size="sm" href={href} color="success" style={{ minWidth: "72.98px", height: "31px" }}>
      Открыть
    </Button>
  );
};

const DeleteButton = ({ mediaplan, deleteMediaplan, ...attrs }) => {
  const titles = {
    1: null,
    2: new Set(["tv", "radio", "sponsor"]).has(mediaplan.media_type)
      ? "Для выполнения отмены со штрафом зайдите в медиаплан"
      : "Нельзя удалить активный медиаплан",
    3: "Нельзя удалить архивный медиаплан",
  };
  return (
    <Button
      disabled={mediaplan.status_type !== 1}
      title={titles[mediaplan.status_type]}
      outline
      size="sm"
      onClick={(_) => deleteMediaplan(mediaplan.id)}
      color="danger"
      {...attrs}
    >
      Удалить
    </Button>
  );
};

const StatsCell = ({ mediaplan }) => {
  const of_tv_type = new Set(["tv", "radio", "sponsor"]).has(mediaplan.media_type);
  return (
    <React.Fragment>
      <div>
        <small>
          <b>
            <span className="text-muted">Стоимость:</span>{" "}
            {of_tv_type || mediaplan.media_type == "outdoor"
              ? mediaplan.get_stats && (
                  <Currency
                    quantity={mediaplan.get_stats.amount_to_pay}
                    currency={mediaplan.get_stats.currency}
                    symbol={mediaplan.get_stats.currency}
                    locale="ru_RU"
                    decimal=","
                    group=" "
                  />
                )
              : ((mediaplan.get_internet_ad || {}).amount || 0) + " KZT"}
          </b>
        </small>
      </div>
      {of_tv_type ? (
        <div>
          <small>
            <span className="text-muted">Кол-во выходов: </span>
            <span>{mediaplan.get_stats && mediaplan.get_stats.count}</span>
          </small>
        </div>
      ) : null}
      {of_tv_type ? (
        <div>
          {mediaplan.media_type != "sponsor" ? (
            <small>
              <span className="text-muted">Бренд: </span>
              <span>{mediaplan.get_brand_1}</span>
            </small>
          ) : null}
        </div>
      ) : null}
      {mediaplan.media_type === "internet" ? (
        <div>
          <small>
            <span className="text-muted">Кол-во дней: </span>
            <span>{((mediaplan.get_internet_ad || {}).selected_days || []).length}</span>
          </small>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default class Mediaplans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      events: [],
      filter: [1, 2, 4],
      types: ["tv", "radio", "internet", "sponsor", "outdoor"],
      modalNotification: false,
    };
    this.toggleNotification = this.toggleNotification.bind(this);
    this.headersTableRef = React.createRef();
    this.rowsTableRef = React.createRef();
    this.updateTableHeadersWidths = this.updateTableHeadersWidths.bind(this);
    this.deleteMediaplan = this.deleteMediaplan.bind(this);
  }

  toggleNotification() {
    this.setState({
      modalNotification: !this.state.modalNotification,
    });
  }

  componentDidMount() {
    this.props.doMediaplanCheck((_) => this.props.onListMediaplans());
  }

  componentDidUpdate() {
    this.updateTableHeadersWidths();
  }

  updateTableHeadersWidths() {
    const widths = [];
    const guide_tr = $(this.headersTableRef.current).find("tr:first").clone();
    var id = "id_" + uuidv4();
    guide_tr.css({ visibility: "hidden" }).attr("id", id);
    $(this.rowsTableRef.current).find("tbody").append(guide_tr);
    $(this.rowsTableRef.current)
      .find("tr:not([id]):first td, tr:not([id]):first th")
      .map((i, el) => {
        widths.push($(el).width());
      });
    $(this.headersTableRef.current)
      .find("tr:not([id]):first td, tr:not([id]):first th")
      .map((i, el) => {
        $(el).width(widths[i]);
      });
    $(this.rowsTableRef.current)
      .find("tr:not([id]):first td, tr:not([id]):first th")
      .map((i, el) => {
        $(el).width(widths[i]);
      });
    setTimeout((_) => {
      $("#" + id).remove();
    }, 100);
  }

  deleteMediaplan(id) {
    swalWithBootstrapButtons
      .fire({
        title: "Вы уверены?",
        text: `Вы действительно хотите удалить медиаплан #${id}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Да!",
        cancelButtonText: "Нет",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          this.props.onDeleteMediaplan(id);
          swalWithBootstrapButtons.fire({
            title: "Подождите",
            text: "Идёт удаление медиаплана",
            allowEscapeKey: false,
            allowOutsideClick: false,
            //timer: 2000,
            onOpen: () => {
              Swal.showLoading();
            },
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire("Операция остановлена", "Ваш медиаплан в безопасности", "error");
        }
      });
  }

  render() {
    const mediaplans = (this.props.mediaplans || []).filter((mediaplan) => {
      if (this.state.filter.indexOf(mediaplan.status_type) == -1) {
        return null;
      }
      if (this.state.types.indexOf(mediaplan.media_type) == -1) {
        return null;
      }
      return true;
    });
    return (
      <Container fluid={true}>
        <div className="mediaplan-controls-layout">
          <div className="mediaplan-controls-2">
            <Button color="primary" href="/dashboard">
              Новый медиаплан
            </Button>
          </div>
          <div className="mediaplan-controls-1">
            <div className="mediaplan-params-outer-list">
              <ul className="mediaplan-params-list">
                <li className="label-element">Статус:</li>
                <li>
                  <label className="react-toggle-item">
                    <Toggle
                      defaultChecked={this.state.filter.indexOf(1) == -1 ? false : true}
                      onChange={() => {
                        var filter = this.state.filter;
                        var index = filter.indexOf(1);
                        if (index != -1) {
                          delete filter[index];
                        } else {
                          filter.push(1);
                        }
                        this.setState({
                          filter: filter,
                        });
                      }}
                    />
                    <span>Черновик</span>
                  </label>
                </li>
                <li>
                  <label className="react-toggle-item">
                    <Toggle
                      defaultChecked={this.state.filter.indexOf(2) == -1 ? false : true}
                      onChange={() => {
                        var filter = this.state.filter;
                        var index = filter.indexOf(2);
                        if (index != -1) {
                          delete filter[index];
                        } else {
                          filter.push(2);
                        }
                        this.setState({
                          filter: filter,
                        });
                      }}
                    />
                    <span>Активные</span>
                  </label>
                </li>
                <li>
                  <label className="react-toggle-item">
                    <Toggle
                      defaultChecked={this.state.filter.indexOf(3) == -1 ? false : true}
                      onChange={() => {
                        var filter = this.state.filter;
                        var index = filter.indexOf(3);
                        if (index != -1) {
                          delete filter[index];
                        } else {
                          filter.push(3);
                        }
                        this.setState({
                          filter: filter,
                        });
                      }}
                    />
                    <span>Архивные</span>
                  </label>
                </li>
              </ul>
              <div className="d-flex align-items-center justify-content-start flex-wrap">
                <ul className="mediaplan-params-list">
                  <li className="label-element">Медиа:</li>
                  <li>
                    <label className="react-toggle-item">
                      <Toggle
                        defaultChecked={this.state.types.indexOf("tv") == -1 ? false : true}
                        onChange={() => {
                          var types = this.state.types;
                          var index = types.indexOf("tv");
                          if (index != -1) {
                            delete types[index];
                          } else {
                            types.push("tv");
                          }
                          this.setState({
                            types: types,
                          });
                        }}
                      />
                      <span>Телевидение</span>
                    </label>
                  </li>
                  <li>
                    <label className="react-toggle-item">
                      <Toggle
                        defaultChecked={this.state.types.indexOf("radio") == -1 ? false : true}
                        onChange={() => {
                          var types = this.state.types;
                          var index = types.indexOf("radio");
                          if (index != -1) {
                            delete types[index];
                          } else {
                            types.push("radio");
                          }
                          this.setState({
                            types: types,
                          });
                        }}
                      />
                      <span>Радио</span>
                    </label>
                  </li>
                  <li>
                    <label className="react-toggle-item">
                      <Toggle
                        defaultChecked={this.state.types.indexOf("sponsor") == -1 ? false : true}
                        onChange={() => {
                          var types = this.state.types;
                          var index = types.indexOf("sponsor");
                          if (index != -1) {
                            delete types[index];
                          } else {
                            types.push("sponsor");
                          }
                          this.setState({
                            types: types,
                          });
                        }}
                      />
                      <span>Спонсорство</span>
                    </label>
                  </li>
                </ul>
                <ul className="mediaplan-params-list">
                  <li>
                    <label className="react-toggle-item">
                      <Toggle
                        defaultChecked={this.state.types.indexOf("outdoor") == -1 ? false : true}
                        onChange={() => {
                          var types = this.state.types;
                          var index = types.indexOf("outdoor");
                          if (index != -1) {
                            delete types[index];
                          } else {
                            types.push("outdoor");
                          }
                          this.setState({
                            types: types,
                          });
                        }}
                      />
                      <span>Наружная реклама</span>
                    </label>
                  </li>
                  <li>
                    <label className="react-toggle-item">
                      <Toggle
                        defaultChecked={this.state.types.indexOf("internet") == -1 ? false : true}
                        onChange={() => {
                          var types = this.state.types;
                          var index = types.indexOf("internet");
                          if (index != -1) {
                            delete types[index];
                          } else {
                            types.push("internet");
                          }
                          this.setState({
                            types: types,
                          });
                        }}
                      />
                      <span>Интернет</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Loadable
          className="pb-5 h-100"
          active={this.props.loading}
          spinner
          text={this.props.shim_text || "Загрузка данных"}
        >
          <div style={{ minHeight: "400px" }}>
            <ToggleDisplay show={this.props.mediaplans.length > 0}>
              <Row>
                <Col md="12">
                  <div className="d-lg-none">
                    {mediaplans.map((mediaplan, i) => {
                      return (
                        <Card key={mediaplan.id} className="mobile-mediaplan">
                          <CardHeader className="d-flex justify-content-between align-items-center">
                            Медиаплан №{mediaplan.id}
                            <div className="d-flex">
                              <DeleteButton
                                deleteMediaplan={this.deleteMediaplan}
                                mediaplan={mediaplan}
                                className="mr-2"
                              />
                              {mediaplan.media_type == "outdoor" ? (
                                <OpenButton href={`/outdoor/${mediaplan.id}`} />
                              ) : mediaplan.media_type == "internet" ? (
                                <OpenButton href={`/internet/${mediaplan.id}`} />
                              ) : (
                                <OpenButton href={`/result/${mediaplan.id}`} />
                              )}
                            </div>
                          </CardHeader>
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <th>Медиаресурс</th>
                                <td>
                                  <div className="the-channel-block">
                                    <div className="the-icon">
                                      <img src={mediaplan.get_channel.logo} width="64px" height="64px" />
                                    </div>
                                    <div className="the-desc">
                                      <div>{mediaplan.get_channel.full_name}</div>
                                      <div>
                                        <small>{mediaplan.get_region.region}</small>
                                      </div>
                                      <div>
                                        <small>{mediaplan.get_stats && mediaplan.get_stats.duration}</small>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>Тип размещения</th>
                                <td>
                                  <div>{mediaplan.get_full_placement_type}</div>
                                </td>
                              </tr>
                              <tr>
                                <th>Параметры заказа</th>
                                <td>
                                  <StatsCell mediaplan={mediaplan} />
                                </td>
                              </tr>
                              <tr>
                                <th>Статус</th>
                                <td>
                                  <small>
                                    <b>{mediaplan.get_status}</b>
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <th>Договор</th>
                                <td>
                                  {mediaplan.contract_id ? (
                                    <small>
                                      {mediaplan.contract_id}
                                      <br />
                                      {mediaplan.contract_date}
                                    </small>
                                  ) : null}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card>
                      );
                    })}
                  </div>
                  <table
                    className="table table-hover d-none d-lg-table"
                    style={{ position: "sticky", top: 0, background: "white", margin: 0 }}
                    ref={this.headersTableRef}
                  >
                    <thead>
                      <tr>
                        <th>Медиаплан №</th>
                        <th>
                          Медиа
                          <wbr />
                          ресурс
                        </th>
                        <th>&nbsp;</th>
                        <th>Тип размещения</th>
                        <th>Параметры заказа</th>
                        <th>Статус</th>
                        <th>Договор</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                  </table>
                  <table className="table table-hover d-none d-lg-table" ref={this.rowsTableRef}>
                    <tbody>
                      {mediaplans.map((mediaplan, i) => {
                        return (
                          <tr key={mediaplan.id}>
                            <th scope="row">
                              <b>#{mediaplan.id}</b>
                            </th>
                            <td>
                              {" "}
                              <img src={mediaplan.get_channel.logo} width="64px" height="64px" />
                            </td>
                            <td>
                              <div>{mediaplan.get_channel.full_name}</div>
                              <div>
                                <small>{mediaplan.get_region.region}</small>
                              </div>
                              <div>
                                <small>{mediaplan.get_stats && mediaplan.get_stats.duration}</small>
                              </div>
                            </td>
                            <td>
                              <div>{mediaplan.get_full_placement_type}</div>
                            </td>
                            <td>
                              <StatsCell mediaplan={mediaplan} />
                            </td>
                            <td>
                              <small>
                                <b>{mediaplan.get_status}</b>
                              </small>
                            </td>
                            <td>
                              {mediaplan.contract_id ? (
                                <small>
                                  {mediaplan.contract_id}
                                  <br />
                                  {mediaplan.contract_date}
                                </small>
                              ) : null}
                            </td>
                            <td>
                              {mediaplan.media_type == "outdoor" ? (
                                <OpenButton href={`/outdoor/${mediaplan.id}`} />
                              ) : mediaplan.media_type == "internet" ? (
                                <OpenButton href={`/internet/${mediaplan.id}`} />
                              ) : (
                                <OpenButton href={`/result/${mediaplan.id}`} />
                              )}
                              <DeleteButton
                                deleteMediaplan={this.deleteMediaplan}
                                mediaplan={mediaplan}
                                className="d-block mt-2"
                                style={{ minWidth: "72.98px" }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {!mediaplans.length && (
                    <div className="text-center my-4">Список пуст. Измените фильтры или создайте новый медиаплан</div>
                  )}
                </Col>
              </Row>
            </ToggleDisplay>
            <ToggleDisplay show={this.props.mediaplans.length == 0 && this.props.loading == false}>
              <Row className="pt-2">
                <Col md="12" className="text-center">
                  <img width="300px" src="/static/images/style/empty.png" className="img-fluid" />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="text-center">
                  <Button color="primary" href="/dashboard">
                    Создать первый медиаплан
                  </Button>
                </Col>
              </Row>
            </ToggleDisplay>
          </div>
        </Loadable>
      </Container>
    );
  }
}
