import React from "react";
import { Container, Row, Col } from "reactstrap";
import { NotificationContainer } from "react-notifications";

import Header from "../containers/header";
import ResetForm from "./forms/reset";

export default class ResetScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Container fluid={true}>
          <NotificationContainer />
          <Header check={false} />
          <Row>
            <Col md="3" />
            <Col md="6">
              <ResetForm params={this.props.params} />
            </Col>
            <Col md="3" />
          </Row>
        </Container>
      </div>
    );
  }
}
