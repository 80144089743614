import React from "react";
import { Container } from "reactstrap";
import { NotificationContainer } from "react-notifications";

import Header from "../containers/header";
import Media from "../containers/media";

export default class MediaScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container fluid={true}>
        <NotificationContainer />
        <Header check={true} />
        <Media mediaplan={this.props.params.mediaplan} preSelectChannel={(this.props.location.query || {}).channel} />
      </Container>
    );
  }
}
