import React from "react";
import {
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip,
  Badge,
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";
import Loadable from "react-loading-overlay";
import Currency from "react-currency-formatter";
import Toggle from "react-toggle";

import TimeTable from "./timeTable";
import TimeTableHeader from "./timeTableHeader";
import SpotModal from "../containers/spot_modal";
import Total from "./total";

export default class Editor extends React.Component {
  constructor() {
    super();
    this.state = {
      time: "",
      block: 0,
      event: [],
      data: [],
      rate: 0,
      notification: true,
      only_sales: false,
      weekend: true,
      weekdays: true,
      search: "",
    };
  }

  toggle() {
    this.setState({
      block: 0,
    });
  }

  componentWillMount() {
    this.props.onLoadingStart();
  }

  componentDidMount() {
    this.props.onListEditorData(
      this.props.mediaplan,
      this.state.weekend,
      this.state.weekdays,
      this.state.search,
      this.state.rate,
      this.state.only_sales
    );
  }

  renderHour(hour, defaultAttributes, styles) {
    return (
      <div {...defaultAttributes} key={hour}>
        {hour}
      </div>
    );
  }

  renderEvent(event, defaultAttributes, styles) {
    var color = "secondary";
    var error = false;
    if (this.props.list.days_count.hasOwnProperty(event.info.key)) {
      this.props.list.days_count[event.info.key].forEach(function (day) {
        var key = event.info.key + "-" + day;
        if (this.props.list.chunks.hasOwnProperty(key)) {
          if (this.props.list.chunks[key]["duration1"] < this.props.list.chunks[key]["duration2"]) {
            if (this.props.list.mediaplan.media_type != "sponsor") {
              error = true;
            }
          }
        }
      }, this);
    }
    if (event.info.status) {
      color = "success";
    }

    return (
      <div
        onClick={() => {
          this.setState({
            block: event.info.id,
          });
        }}
        {...defaultAttributes}
      >
        <div className={"text-nowrap text-center align-middle block-info"}>
          <small className="text-center">
            {error ? <status-indicator id={`tooltip-danger-${event.info.id}`} negative pulse /> : null}{" "}
            <Badge color={color} id={`tooltip-program-${event.info.id}`}>
              {event.info.block_datetime}
            </Badge>{" "}
            {this.props.list.mediaplan.media_type != "sponsor" ? (
              <Badge color={color}>{event.info.block_duration} сек</Badge>
            ) : null}{" "}
            <Badge color={color} id={`tooltip-price-${event.info.id}`}>
              $
            </Badge>{" "}
            {event.info.block_sale ? <status-indicator positive pulse /> : null}
          </small>
          {error ? (
            <UncontrolledTooltip placement="top" target={`tooltip-danger-${event.info.id}`}>
              Хронометраж рекламы на государственном языке меньше, чем хронометраж на других языках. Увеличьте долю
              роликов на государственном языке.
            </UncontrolledTooltip>
          ) : null}
          <UncontrolledTooltip placement="top" target={`tooltip-program-${event.info.id}`}>
            {event.info.program_name}
          </UncontrolledTooltip>
          <UncontrolledTooltip placement="top" target={`tooltip-price-${event.info.id}`}>
            от <Currency quantity={event.info.price} currency="KZT" locale="ru_RU" symbol="KZT" decimal="," group=" " />
          </UncontrolledTooltip>
        </div>
        <SpotModal
          toggle={this.toggle.bind(this)}
          data={event.list}
          event={event}
          chunks={this.props.list.chunks}
          weekend={this.state.weekend}
          rate={this.state.rate}
          weekdays={this.state.weekdays}
          search={this.state.search}
          loading={this.props.loading}
          only_sales={this.state.only_sales}
          mediaplan={this.props.mediaplan}
          status_type={this.props.list.status_type}
          media_type={this.props.list.mediaplan.media_type}
          durations={this.props.list.mediaplan.get_durations}
          days={this.props.list.days}
          modal={event.info.id == this.state.block}
        />
      </div>
    );
  }

  getDayLabel(day) {
    return `${this.props.list.days[day]} (${this.props.list.weekday_count[day]})`;
  }

  render() {
    return (
      <Loadable active={this.props.loading} spinner text="Загрузка данных">
        <Row>
          <Col md="12">
            <Button color="secondary" outline={true} size="sm" href={`/result/${this.props.list.mediaplan.id}/`}>
              &larr; Вернуться в медиаплан
            </Button>
          </Col>
        </Row>
        <hr />
        <Row className="pb-1 pt-1">
          <Col md="2" className="text-center pt-2">
            <Input type="select" size="sm">
              <option>Все жанры</option>
            </Input>
          </Col>
          <Col md="4" className="pt-2">
            <ul className="list-inline">
              <li className="list-inline-item pr-1">
                <label className="react-toggle-item">
                  <Toggle
                    checked={this.state.rate == 0}
                    onChange={() => {
                      var rate = 0;
                      if (this.state.rate == rate) {
                        var rate = 0;
                      }
                      this.props.onLoadingStart();
                      this.props.onListEditorData(
                        this.props.mediaplan,
                        this.state.weekend,
                        this.state.weekdays,
                        this.state.search,
                        rate,
                        this.state.only_sales
                      );
                      this.setState({
                        rate: rate,
                      });
                    }}
                  />
                  <span>Все</span>
                </label>
              </li>
              <li className="list-inline-item pr-1">
                <label className="react-toggle-item">
                  <Toggle
                    checked={this.state.rate == 1}
                    onChange={() => {
                      var rate = 1;
                      if (this.state.rate == rate) {
                        var rate = 0;
                      }
                      this.props.onLoadingStart();
                      this.props.onListEditorData(
                        this.props.mediaplan,
                        this.state.weekend,
                        this.state.weekdays,
                        this.state.search,
                        rate,
                        this.state.only_sales
                      );
                      this.setState({
                        rate: rate,
                      });
                    }}
                  />
                  <span>Утро</span>
                </label>
              </li>
              <li className="list-inline-item pr-1">
                <label className="react-toggle-item">
                  <Toggle
                    checked={this.state.rate == 2}
                    onChange={() => {
                      var rate = 2;
                      if (this.state.rate == rate) {
                        var rate = 0;
                      }
                      this.props.onLoadingStart();
                      this.props.onListEditorData(
                        this.props.mediaplan,
                        this.state.weekend,
                        this.state.weekdays,
                        this.state.search,
                        rate,
                        this.state.only_sales
                      );
                      this.setState({
                        rate: rate,
                      });
                    }}
                  />
                  <span>День</span>
                </label>
              </li>
              <li className="list-inline-item pr-1">
                <label className="react-toggle-item">
                  <Toggle
                    checked={this.state.rate == 3}
                    onChange={() => {
                      var rate = 3;
                      if (this.state.rate == rate) {
                        var rate = 0;
                      }
                      this.props.onLoadingStart();
                      this.props.onListEditorData(
                        this.props.mediaplan,
                        this.state.weekend,
                        this.state.weekdays,
                        this.state.search,
                        rate,
                        this.state.only_sales
                      );
                      this.setState({
                        rate: rate,
                      });
                    }}
                  />
                  <span>Вечер</span>
                </label>
              </li>
              <li className="list-inline-item pr-1">
                <label className="react-toggle-item">
                  <Toggle
                    checked={this.state.rate == 4}
                    onChange={() => {
                      var rate = 4;
                      if (this.state.rate == rate) {
                        var rate = 0;
                      }
                      this.props.onLoadingStart();
                      this.props.onListEditorData(
                        this.props.mediaplan,
                        this.state.weekend,
                        this.state.weekdays,
                        this.state.search,
                        rate,
                        this.state.only_sales
                      );
                      this.setState({
                        rate: rate,
                      });
                    }}
                  />
                  <span>Ночь</span>
                </label>
              </li>
            </ul>
          </Col>
          <Col md="4" className="text-center">
            <ul className="list-inline">
              <li className="list-inline-item pr-3">
                <label className="react-toggle-item">
                  <Toggle
                    defaultChecked={this.state.weekdays}
                    onChange={() => {
                      this.props.onLoadingStart();
                      this.props.onListEditorData(
                        this.props.mediaplan,
                        this.state.weekend,
                        !this.state.weekdays,
                        this.state.search,
                        this.state.rate,
                        this.state.only_sales
                      );
                      this.setState({ weekdays: !this.state.weekdays });
                    }}
                  />
                  <span>Будние дни</span>
                </label>
              </li>
              <li className="list-inline-item pr-3">
                <label className="react-toggle-item">
                  <Toggle
                    defaultChecked={this.state.weekend}
                    onChange={() => {
                      this.props.onLoadingStart();
                      this.props.onListEditorData(
                        this.props.mediaplan,
                        !this.state.weekend,
                        this.state.weekdays,
                        this.state.search,
                        this.state.rate,
                        this.state.only_sales
                      );
                      this.setState({ weekend: !this.state.weekend });
                    }}
                  />
                  <span>Выходные дни</span>
                </label>
              </li>
            </ul>
            {this.props.list.mediaplan.media_type != "sponsor" ? (
              <ul className="list-inline">
                <li className="list-inline-item pr-3 text-center">
                  <span className="react-toggle-item react-toggle-item-left">Показать всё </span>
                  <label className="react-toggle-item mr-2 ml-2">
                    <Toggle
                      checked={this.state.only_sales}
                      defaultChecked={this.state.only_sales}
                      icons={false}
                      onChange={() => {
                        this.props.onLoadingStart();
                        this.props.onListEditorData(
                          this.props.mediaplan,
                          this.state.weekend,
                          this.state.weekdays,
                          this.state.search,
                          this.state.rate,
                          !this.state.only_sales
                        );
                        this.setState({ only_sales: !this.state.only_sales });
                      }}
                    />
                    <span>Только скидки</span>
                  </label>
                </li>
              </ul>
            ) : null}
          </Col>
          <Col md="2" className="text-center p-2">
            <InputGroup size="sm">
              <Input
                onChange={(event) => {
                  this.setState({
                    search: event.target.value,
                  });
                }}
                defaultValue={this.state.search}
                placeholder="Программы"
                size="sm"
              />
              <InputGroupAddon addonType="append">
                <Button
                  onClick={() => {
                    this.props.onLoadingStart();
                    this.props.onListEditorData(
                      this.props.mediaplan,
                      this.state.weekend,
                      this.state.weekdays,
                      this.state.search,
                      this.state.rate,
                      this.state.only_sales
                    );
                  }}
                  color="secondary"
                >
                  <i className="fas fa-search" /> Поиск
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            background: "white",
          }}
        >
          <Row className="pt-1">
            <Col className="pr-2" md="3">
              {this.props.list.mediaplan.get_channel ? (
                <span>
                  <img src={this.props.list.mediaplan.get_channel.logo} height="48px" />{" "}
                  <b>{this.props.list.mediaplan.get_channel.full_name}</b>
                </span>
              ) : null}
            </Col>
            <Col md="6" className="text-center">
              <Total mediaplan={this.props.list.mediaplan} get_stats={this.props.list.get_stats} />
            </Col>
            <Col md="3" className="text-right mt-2 pr-5">
              <h4>{this.props.list.date}</h4>
            </Col>
          </Row>
          <TimeTableHeader
            events={this.props.list.weekday}
            renderHour={this.renderHour.bind(this)}
            renderEvent={this.renderEvent.bind(this)}
            getDayLabel={this.getDayLabel.bind(this)}
            hoursInterval={[0, 24]}
            defaultHours={6}
            widthColumn={190}
          />
        </div>
        <div className="pt-1 pb-5 no-top-padding">
          <TimeTable
            events={this.props.list.weekday}
            renderHour={this.renderHour.bind(this)}
            renderEvent={this.renderEvent.bind(this)}
            getDayLabel={this.getDayLabel.bind(this)}
            hoursInterval={[0, 24]}
            defaultHours={6}
            widthColumn={190}
            heightColumn={80}
          />
        </div>
        {this.props.list.mediaplan.media_type != "sponsor" ? (
          <Modal isOpen={this.state.notification && this.props.list.sales_count > 5}>
            <ModalHeader>У нас {this.props.list.sales_count} блоков со скидками в этом месяце!</ModalHeader>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  this.props.onLoadingStart();
                  this.props.onListEditorData(
                    this.props.mediaplan,
                    this.state.weekend,
                    this.state.weekdays,
                    this.state.search,
                    this.state.rate,
                    false
                  );
                  this.setState({ only_sales: false, notification: false });
                }}
                outline={true}
              >
                Показать все варианты
              </Button>{" "}
              <Button
                color="primary"
                onClick={() => {
                  this.props.onLoadingStart();
                  this.props.onListEditorData(
                    this.props.mediaplan,
                    this.state.weekend,
                    this.state.weekdays,
                    this.state.search,
                    this.state.rate,
                    true
                  );
                  this.setState({ only_sales: true, notification: false });
                }}
                outline={true}
              >
                Показать только скидки
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </Loadable>
    );
  }
}
