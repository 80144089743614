export const playlistsOnlyNationalLangFilled = (playlists) => {
  let result = 0;
  for (const playlist of playlists || []) {
    result += playlist.duration2 || 0;
  }
  return result === 0;
};

export const playlistsOverallDurationZero = (playlists) => {
  let result = 0;
  for (const playlist of playlists || []) {
    result += (playlist.duration1 || 0) + (playlist.duration2 || 0);
  }
  return result === 0;
};

export const playlistsAreEmpty = (playlists) => {
  return !playlists || !playlists.length || playlistsOverallDurationZero(playlists);
};

export const mediaplanOnlyInNationalLang = (mediaplan) => {
  return mediaplan.get_channel.only_national_lang || mediaplan.only_national_lang;
};

export const playlistsLackingOtherLang = (mediaplan, playlists) => {
  return !mediaplanOnlyInNationalLang(mediaplan) && playlistsOnlyNationalLangFilled(playlists);
};

export const mediaplanLackingMedia = (mediaplan) => {
  if (mediaplan.media_type === "sponsor") {
    return false;
  }
  return !mediaplan.media_1 || (!mediaplanOnlyInNationalLang(mediaplan) && !mediaplan.media_2);
};
