import {} from "../actions/types";

const initialState = {
  transactions: [],
  loading: false,
};

export default function transactionReducer(state = initialState, action) {
  return state;
}
