import { LIST_REPORTS, GOT_DOWNLOAD_TOKEN } from "../actions/types";

const initialState = {
  list: {
    result: [],
  },
  downloadToken: null,
  loading: false,
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_REPORTS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
      };
    case GOT_DOWNLOAD_TOKEN:
      return {
        ...state,
        loading: false,
        downloadToken: action.payload.downloadToken,
      };
    default:
      return state;
  }
}
