import React from "react";
import PropTypes from "prop-types";

var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  });
  if (superClass)
    Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass);
};

var createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var TimeTable = (function (Component) {
  inherits(TimeTable, Component);

  function TimeTable() {
    this.state = {
      rowHeight: 0,
    };
    return this;
  }

  createClass(TimeTable, [
    {
      key: "componentWillMount",
      value: function componentWillMount() {
        this.setState({
          rowHeight: this.getRowHeight(),
        });
      },
    },
    {
      key: "getRowHeight",
      value: function getRowHeight() {
        return this.props.heightColumn;
      },
    },
    {
      key: "baseKeys",
      value: function baseKeys(object) {
        var result = [];
        for (var key in Object(object)) {
          if (Object.prototype.hasOwnProperty.call(object, key) && key != "constructor") {
            result.push(key);
          }
        }
        return result;
      },
    },
    {
      key: "range",
      value: function range(start, end) {
        var total = [];

        if (!end) {
          end = start;
          start = 0;
        }

        for (var i = start; i < end; i += 1) {
          total.push(i);
        }

        return total;
      },
    },

    {
      key: "getEventPositionStyles",
      value: function getEventPositionStyles(event) {
        var time = event.split(":");
        var m = parseInt(time[1]) / 15;

        if (parseInt(time[0]) < 6) {
          var h = (parseInt(time[0]) + 18) * 4;
        } else {
          var h = (parseInt(time[0]) - 6) * 4;
        }

        var top = (m + h) * this.props.heightColumn;
        return {
          height: this.props.heightColumn + "px",
          marginTop: top - 44 + "px",
        };
      },
    },
    {
      key: "renderDay",
      value: function renderDay(day, index) {
        return React.createElement(
          "div",
          {
            className: "styles-day",
            style: {
              backgroundSize: "1px " + 2 * this.state.rowHeight + "px",
              width: this.props.widthColumn + "px",
              height: this.props.heightColumn * 96 + "px",
            },
            key: day + "-" + index,
          },
          this.renderDayEvents(day)
        );
      },
    },
    {
      key: "renderDayEvents",
      value: function renderDayEvents(day) {
        return this.baseKeys(this.props.events[day].data).map(function (event) {
          return this.props.renderEvent.apply(this, [
            this.props.events[day].data[event],
            {
              className: "styles-event",
              style: this.getEventPositionStyles(event),
            },
          ]);
        }, this);
      },
    },
    {
      key: "renderHours",
      value: function renderHours() {
        var timeline = [];
        this.range(this.props.defaultHours, this.props.hoursInterval[1]).map((hour) => {
          [":00", ":15", ":30", ":45"].map((minute) => {
            timeline.push(hour + minute);
          });
        });
        this.range(this.props.hoursInterval[0], this.props.defaultHours).map((hour) => {
          [":00", ":15", ":30", ":45"].map((minute) => {
            timeline.push(hour + minute);
          });
        });
        return timeline.map(function (hour) {
          return this.props.renderHour.apply(this, [
            hour,
            {
              className: "styles-hour",
              style: { height: this.state.rowHeight + "px" },
            },
          ]);
        }, this);
      },
    },
    {
      key: "render",
      value: function render() {
        return React.createElement(
          "div",
          { className: "styles-time-table-wrapper" },
          React.createElement("div", { className: "styles-day" }, this.renderHours()),
          this.baseKeys(this.props.events).map(
            function (day, index) {
              return this.renderDay(day, index);
            }.bind(this)
          )
        );
      },
    },
  ]);
  return TimeTable;
})(React.Component);

TimeTable.propTypes = {
  hoursInterval: PropTypes.array,
  events: PropTypes.object.isRequired,
  renderEvent: PropTypes.func,
  getDayLabel: PropTypes.func,
  timeLabel: PropTypes.string,
  width: PropTypes.string,
};

TimeTable.defaultProps = {
  hoursInterval: [0, 24],
  timeLabel: "Время",
  widthColumn: 200,
  heightColumn: 100,
  getDayLabel: function getDayLabel(day) {
    return day;
  },
};

export default TimeTable;
