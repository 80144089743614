import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Badge, UncontrolledTooltip } from "reactstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

export default class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      close: false,
      data: this.props.data,
    };
  }

  toggle() {
    this.setState({
      close: !this.state.close,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
    });
  }
  render() {
    let firstRow = {};
    if (this.props?.data?.rch?.length) firstRow = this.props.data.rch[0];

    return (
      <div>
        <Button size="sm" className="ml-2" onClick={this.toggle.bind(this)} color="primary">
          Аналитика
        </Button>
        <Modal size="xl" isOpen={this.state.close}>
          <ModalHeader>Прогноз эффективности рекламной кампании к медиаплану № {this.props.mediaplan}</ModalHeader>
          <ModalBody>
            <table className="table table-warning table-bordered">
              <tr>
                <td align="center">
                  <small>
                    <b>Телеканал</b>
                  </small>
                </td>
                <td align="center">
                  <small>
                    <b>Количество выходов</b>
                  </small>
                </td>
                <td align="center">
                  <small>
                    <b>
                      Общий <br />
                      хронометраж, сек
                    </b>
                  </small>
                </td>
                <td align="center">
                  <small>
                    <b>
                      Прогнозный <br />
                      объём GRP30
                    </b>
                  </small>
                </td>
                <td align="center">
                  <small>
                    <b>
                      Прогнозный <br />
                      Reach 1+, %
                    </b>
                  </small>
                </td>
                <td align="center">
                  <small>
                    <b>
                      Стоимость, <br />c НДС
                    </b>
                  </small>
                </td>
                <td align="center">
                  <small>
                    <b>CPP</b>{" "}
                    <Badge pill id="tooltip-cpp-title">
                      i
                    </Badge>
                  </small>
                </td>
                <td align="center">
                  <small>
                    <b>CPT</b>{" "}
                    <Badge pill id="tooltip-cpt-title">
                      i
                    </Badge>
                  </small>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <small>{this.props.data.channel}</small>
                </td>
                <td align="center">
                  <small>{this.props.data.count}</small>
                </td>
                <td align="center">
                  <small>{this.props.data.total_duration}</small>
                </td>
                <td align="center">
                  <small>{this.props.data.grp30}</small>
                </td>
                <td align="center">
                  <small>{this.props.data.rch1}</small>
                </td>
                <td align="center">
                  <small>{this.props.data.amount_to_pay} KZT</small>
                </td>
                <td align="center">
                  <small>{this.props.data.cpp} KZT</small>
                </td>
                <td align="center">
                  <small>{this.props.data.cpt} KZT</small>
                </td>
              </tr>
            </table>
            <hr />
            <LineChart
              width={760}
              height={500}
              data={this.props.data.rch}
              margin={{ top: 5, right: 10, bottom: 20, left: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                label={{
                  value: "GRP30",
                  position: "insideBottomRight",
                  offset: -10,
                }}
              />
              <YAxis
                label={{
                  value: "Rch%",
                  angle: -90,
                  position: "insideTopRight",
                  offset: -5,
                }}
              />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Rch 1+" stroke="red" strokeWidth="3" />
              {firstRow.hasOwnProperty("Rch 3+") && (
                <Line type="monotone" dataKey="Rch 3+" stroke="green" strokeWidth="3" />
              )}
              {firstRow.hasOwnProperty("Rch 5+") && (
                <Line type="monotone" dataKey="Rch 5+" stroke="purple" strokeWidth="3" />
              )}
            </LineChart>
            <hr />
            <UncontrolledTooltip placement="top" target="tooltip-cpp-title">
              Стоимость одного пункта рейтинга
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="tooltip-cpt-title">
              Стоимость за тысячу контактов
            </UncontrolledTooltip>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggle.bind(this)} color="primary">
              Закрыть
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
