import React from "react";
import { Field, reduxForm } from "redux-form";
import { Card, Col, Row, CardTitle, CardBody } from "reactstrap";
import { NotificationManager } from "react-notifications";

import API from "../../api/list";
import { renderField } from "../../components/renderField";

class ForgetForm extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      email: false,
    };
    this.initState = this.initState.bind(this);
  }

  initState() {
    this.setState({
      loading: false,
      email: false,
    });
  }

  submit(values) {
    this.setState({
      loading: true,
    });
    API.forget(values).then((response) => {
      this.initState();
      switch (response.status) {
        case 200:
          this.setState({
            loading: false,
          });
          NotificationManager.success("Письмо с новой ссылкой успешно отправлено", "", 15000);
          break;
        case 400:
          response.json().then((data) => {
            Object.keys(data).forEach(function (element) {
              let obj = {};
              obj[element] = true;
              this.setState(obj);
              NotificationManager.error(data[element], "", 15000);
            }, this);
          }, this);
          break;
      }
    }, this);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Card>
        <CardBody>
          <CardTitle>Восстановить пароль</CardTitle>
          <hr />
          <form onSubmit={handleSubmit(this.submit.bind(this))} noValidate>
            <Row>
              <Col className="text-center">
                <img width="300px" src="/static/images/style/forget.png" className="img-fluid" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="email"
                  type="email"
                  label="Email"
                  error={this.state.email}
                  component={renderField}
                  placeholder="Ваш Email"
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <button type="submit" className="btn btn-primary">
                  Восстановить пароль
                </button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default reduxForm({
  form: "forgetForm",
})(ForgetForm);
