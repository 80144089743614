import React from "react";
import Select, { components } from "react-select";
import { Row, Col } from "reactstrap";

function getLength(options) {
  return options.reduce((acc, curr) => {
    if ("options" in curr) return acc + getLength(curr.options);
    return acc + 1;
  }, 0);
}

const menuHeaderStyle = {
  padding: "12px 8px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "baseline",
  borderBottom: "1px solid rgb(204, 204, 204)",
};

// const menuWrapperStyle = {
//   boxShadow: "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
// };

export const Menu = (props) => {
  const optionsLength = getLength(props.options);
  return (
    <components.Menu {...props}>
      <div style={menuHeaderStyle}>
        <button type="button" className="btn btn-sm btn-success" onClick={(_) => props.selectProps.onAdd()}>
          Загрузить новый...
        </button>
        {/*<label className="btn btn-sm btn-success" style={{position: "relative"}}
          onClick={e => {
            console.log('clicked');
            //e.target.value = null;
          }}
        >
          <input
            type="file"
            style={{position: "absolute", top: 0, left: -9999}}
            defaultValue={null}
            onChange={e => {
              e.persist();
              console.log(e);
              if (e.target.files[0]) {
                props.selectProps.onAdd({value: e.target.files[0]});
              }
            }}
          />
          Загрузить новый...
        </label>*/}
        {optionsLength ? <span className="ml-2"> или выберите существующий:</span> : null}
      </div>
      {props.children}
    </components.Menu>
  );
};

export default class SelectMedia extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const MediaClearLink = ({ shown, type }) => {
      if (!shown) return <></>;
      const mediaClearLinkClick = (e) => {
        e.preventDefault();
        this.props.onSetMediaplanMedia({
          mediaplan: this.props.mediaplan.id,
          media: null,
          type,
        });
      };
      return (
        <>
          {" "}
          (
          <a href={"#"} onClick={mediaClearLinkClick}>
            очистить
          </a>
          )
        </>
      );
    };

    const that = this;

    return (
      <div>
        <Row>
          <Col md="6">
            <span>
              <div>
                Ролик на государственном языке
                <MediaClearLink
                  shown={this.props.mediaplan.get_default_media_1 && this.props.mediaplan.status_type === 1}
                  type={1}
                />
                :
              </div>
              <div>
                <Select
                  // TODO
                  onClick={(e) => console.log(e)}
                  placeholder="Выбрать ролик из медиатеки"
                  aria-label="Выбрать ролик из медиатеки"
                  aria-labelledby="Выбрать ролик из медиатеки"
                  noOptionsMessage={(inputValue) => "Нет ролика"}
                  isDisabled={this.props.disabled}
                  value={this.props.mediaplan.get_default_media_1}
                  options={this.props.media.kaz}
                  onAdd={(event) => {
                    const inputElement = document.createElement("input");
                    inputElement.setAttribute("type", "file");
                    inputElement.click();
                    inputElement.addEventListener("change", function (e) {
                      that.props.onAddMediaplanMedia({
                        mediaplan: that.props.mediaplan.id,
                        url: e.target.files[0],
                        type: 1,
                      });
                    });
                  }}
                  onChange={(event) => {
                    this.props.onSetMediaplanMedia({
                      mediaplan: this.props.mediaplan.id,
                      media: event.value,
                      type: 1,
                    });
                  }}
                  components={{ Menu }}
                />
              </div>
            </span>
          </Col>
          <Col md="6">
            {!(this.props.mediaplan.get_channel.only_national_lang || this.props.mediaplan.only_national_lang) ? (
              <span>
                <div>
                  Ролик на другом языке
                  <MediaClearLink
                    shown={this.props.mediaplan.get_default_media_2 && this.props.mediaplan.status_type === 1}
                    type={2}
                  />
                  :
                </div>
                <div />
                {this.props.mediaplan.media_1 ? (
                  <Select
                    placeholder={`Выбранный бренд: ${this.props.mediaplan.get_brand_1}`}
                    aria-label={`Выбранный бренд: ${this.props.mediaplan.get_brand_1}`}
                    aria-labelledby={`Выбранный бренд: ${this.props.mediaplan.get_brand_1}`}
                    noOptionsMessage={(inputValue) => "Нет ролика"}
                    isDisabled={this.props.disabled || !this.props.mediaplan.get_default_media_1}
                    value={this.props.mediaplan.get_default_media_2}
                    options={this.props.media.other}
                    onChange={(event) => {
                      this.props.onSetMediaplanMedia({
                        mediaplan: this.props.mediaplan.id,
                        media: event.value,
                        type: 2,
                      });
                    }}
                    onAdd={(event) => {
                      const inputElement = document.createElement("input");
                      inputElement.setAttribute("type", "file");
                      inputElement.click();
                      inputElement.addEventListener("change", function (e) {
                        that.props.onAddMediaplanMedia({
                          mediaplan: that.props.mediaplan.id,
                          url: e.target.files[0],
                          type: 2,
                        });
                      });
                    }}
                    components={{ Menu }}
                  />
                ) : (
                  <Select
                    placeholder="Выбрать ролик из медиатеки"
                    aria-label="Выбрать ролик из медиатеки"
                    aria-labelledby="Выбрать ролик из медиатеки"
                    noOptionsMessage={(inputValue) => "Нет ролика"}
                    isDisabled={this.props.disabled || !this.props.mediaplan.media_1}
                    value={this.props.mediaplan.get_default_media_2}
                    options={this.props.media.other}
                    onChange={(event) => {
                      this.props.onSetMediaplanMedia({
                        mediaplan: this.props.mediaplan.id,
                        media: event.value,
                        type: 2,
                      });
                    }}
                    onAdd={(event) => {
                      const inputElement = document.createElement("input");
                      inputElement.setAttribute("type", "file");
                      inputElement.click();
                      inputElement.addEventListener("change", function (e) {
                        that.props.onAddMediaplanMedia({
                          mediaplan: that.props.mediaplan.id,
                          url: e.target.files[0],
                          type: 2,
                        });
                      });
                    }}
                    components={{ Menu }}
                  />
                )}
              </span>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}
