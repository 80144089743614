import React from "react";
import { Button } from "reactstrap";
import Currency from "react-currency-formatter";

export default class Total extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (!this.props.get_stats) {
      return null;
    }
    return (
      <Button
        disabled={this.props.get_stats.duration1 == 0 && this.props.get_stats.duration2 == 0}
        href={`/result/${this.props.mediaplan.id}`}
        color="success"
      >
        {this.props.mediaplan.media_type != "sponsor" ? (
          <span>
            ВЫБРАНО: {this.props.get_stats.duration1 + this.props.get_stats.duration2} сек (
            {this.props.get_stats.duration1}/{this.props.get_stats.duration2}) РЕЙТИНГ: {this.props.get_stats.rating}{" "}
            ИТОГО:{" "}
          </span>
        ) : (
          <span>ВЫБРАНО: {this.props.mediaplan.get_playlists.length} выходов ИТОГО: </span>
        )}
        <Currency
          quantity={this.props.get_stats.amount_to_pay}
          currency={this.props.get_stats.currency}
          symbol={this.props.get_stats.currency}
          locale="ru_RU"
          decimal=","
          group=" "
        />
      </Button>
    );
  }
}
