export const verifyRecaptcha = () => {
  const { grecaptcha } = window;
  return new Promise((res, rej) => {
    if (!window.RECAPTCHA_ENABLED) res('');
    grecaptcha.ready(() => {
      grecaptcha.execute(window.RECAPTCHA_SITE_KEY, {action: 'homepage'}).then((token) => {
        return res(token);
      });
    });
  });
};
