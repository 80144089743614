import { LIST_REGIONS, LIST_INTERNET_CHANNELS } from "../actions/types";

const initialState = {
  internetChannels: [],
  regions: [],
};

export default function createInternetReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_INTERNET_CHANNELS:
      return {
        ...state,
        internetChannels: action.payload.internetChannels,
      };
    case LIST_REGIONS:
      return {
        ...state,
        regions: action.payload.regions,
      };
    default:
      return state;
  }
}
