import { GET_ORDER, ORDER_FINALIZED, LIST_COUNTRIES, LIST_CURRENCIES, LOADING_START } from "./types";

import API from "../api/list";

import { NotificationManager } from "react-notifications";

export const updateOrder = (mediaplan, forms) => {
  return (dispatch) => {
    dispatch(loadingStart());
    API.updateOrder(mediaplan, forms).then((response) => {
      switch (response.status) {
        case 200:
          (async () => {
            dispatch(itemOrder(await response.json()));
          })();
          break;
      }
    }, this);
  };
};

export const finalizeOrder = (mediaplan, forms) => {
  return (dispatch) => {
    API.finalizeOrder(mediaplan, forms).then((data) => {
      dispatch(orderFinalized(data));
    }, this);
  };
};

export const updateProfile = (mediaplan, forms) => {
  return (dispatch) => {
    API.updateProfile(forms).then((response) => {
      switch (response.status) {
        case 200:
          NotificationManager.success("Сохранено", "", 15000);
          API.getOrder(mediaplan).then(function (item) {
            dispatch(itemOrder(item));
          });
          break;
        case 400:
          response.json().then((data) => {
            Object.keys(data).forEach(function (element) {
              let obj = {};
              obj[element] = true;
            }, this);
            // FIXME NotificationManager.error(data[element], "", 15000);
          }, this);
          break;
      }
    }, this);
  };
};

export const getOrder = (id) => {
  return (dispatch) => {
    API.getOrder(id).then(function (item) {
      dispatch(itemOrder(item));
    });
  };
};

export const itemOrder = (item) => ({
  type: GET_ORDER,
  payload: {
    item: item,
  },
});

export const getCountries = () => {
  return (dispatch) => {
    API.countries().then(function (countries) {
      dispatch(listCountries(countries));
    });
  };
};

export const listCountries = (countries) => ({
  type: LIST_COUNTRIES,
  payload: {
    countries: countries,
  },
});

export const getCurrencies = () => {
  return (dispatch) => {
    API.currencies().then(function (currencies) {
      dispatch(listCurrencies(currencies));
    });
  };
};

export const listCurrencies = (currencies) => ({
  type: LIST_CURRENCIES,
  payload: {
    currencies: currencies,
  },
});

export const loadingStart = () => ({
  type: LOADING_START,
  payload: {},
});

export const orderFinalized = (data) => ({
  type: ORDER_FINALIZED,
  payload: data,
});
