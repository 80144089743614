import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, FormGroup, CardFooter, Button, UncontrolledTooltip, Input } from "reactstrap";

const AbstractSteps = ({
  regions,
  currentRegion,
  displayChannelPred,
  doChange,
  currentChannel,
  children,
  done,
  media_type,
  selectWhat = "канал",
  whatCount = "Каналов",
  availableWhat = "выходов",
}) => {
  const region = regions.find((r) => r.id === currentRegion);
  const [preSelected, setPreSelected] = useState(false);
  useEffect(
    (_) => {
      if (preSelected) return;
      if (region?.get_channels?.length === 1 && !currentChannel) {
        setPreSelected(true);
        const channel = region?.get_channels?.[0];
        if (channel.get_months.length) {
          doChange({
            region: currentRegion,
            channel: channel.id,
            year: parseInt(channel.get_months[0].key.split(".")[1]),
            month: parseInt(channel.get_months[0].key.split(".")[0]),
          });
        }
      }
    },
    [regions, currentRegion, currentChannel]
  );
  return (
    <Card className="mb-5">
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-md-6" style={{ paddingRight: 0, width: "auto", maxWidth: "none", flexBasis: "auto" }}>
          <FormGroup>
            <CardHeader>Выберите регион:</CardHeader>
            <CardBody>
              {regions.map((item, index) => {
                const channels_count = item.get_channels.filter(displayChannelPred).length;
                if (!channels_count) return null;
                const id = `select_region_${item.id}`;
                return (
                  <div
                    key={item.id}
                    className="form-check"
                    style={{
                      paddingLeft: "15px",
                      cursor: "pointer",
                    }}
                  >
                    <Input
                      id={id}
                      onChange={() => {
                        doChange({
                          region: item.id,
                          channel: null,
                        });
                      }}
                      checked={currentRegion === item.id}
                      type="radio"
                      name="region"
                    />
                    <label className="form-check-label" htmlFor={id}>
                      {item.region} ({whatCount}: {channels_count})
                    </label>
                  </div>
                );
              }, this)}
            </CardBody>
          </FormGroup>
        </div>
        <div
          className="col-md-6"
          style={{ paddingLeft: 0, flex: 1, width: "auto", maxWidth: "none", flexBasis: "auto" }}
        >
          <div className="form-group" style={{ marginBottom: 0 }}>
            <CardHeader className="border-top">Выберите {selectWhat}:</CardHeader>
            <CardBody>
              <div>
                {region?.get_channels?.map((channel, i) => {
                  if (!displayChannelPred(channel)) return null;
                  const isSelected = currentChannel === channel.id;
                  const doesntHaveResources = !channel.get_months.length;
                  return (
                    <label
                      key={`select-channel-${channel.id}`}
                      htmlFor={`select-channel-${channel.id}`}
                      className="channel-in-grid"
                      onClick={() => {
                        if (!doesntHaveResources) {
                          if (channel.id == currentChannel) {
                            doChange({
                              channel: null,
                            });
                          } else {
                            doChange({
                              channel: channel.id,
                              year: parseInt(channel.get_months[0].key.split(".")[1]),
                              month: parseInt(channel.get_months[0].key.split(".")[0]),
                            });
                          }
                        }
                      }}
                    >
                      <input
                        type="radio"
                        id="select-channel-${channel.id}"
                        disabled={doesntHaveResources}
                        checked={isSelected}
                        onChange={(_) => _}
                      />
                      <img
                        id={`tooltip-channel-${channel.id}`}
                        style={{
                          width: "48px",
                          height: "48px",
                          opacity: doesntHaveResources ? 0.5 : 1,
                        }}
                        className={isSelected ? "border border-primary p-1" : "border p-1"}
                        src={channel.logo}
                        alt={channel.full_name}
                      />
                      <div className="pt-1">
                        {channel.full_name}
                        {channel.min_price ? (
                          <>
                            {" "}
                            — <small>{channel.min_price}</small>
                          </>
                        ) : null}
                      </div>
                      {doesntHaveResources ? (
                        <UncontrolledTooltip placement="top" target={`tooltip-channel-${channel.id}`}>
                          Нет доступных {availableWhat}
                        </UncontrolledTooltip>
                      ) : null}
                    </label>
                  );
                })}
              </div>
            </CardBody>
          </div>
        </div>
      </div>
      {children}
      <CardFooter className="text-center">
        <Button color="primary" size="lg" disabled={currentChannel == null} onClick={done}>
          {["sponsor", "outdoor_advertising"].includes(media_type) ? (
            <span>Создать медиаплан</span>
          ) : (
            <span>Создать медиаплан {currentChannel == null || media_type === "outdoor" ? "" : "со скидками"}</span>
          )}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default AbstractSteps;
