import React from "react";
import {
  Badge,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  FormText,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import SelectMedia from "../selectMedia";
import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: "btn btn-success",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false,
});

export default class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: true,
      step1: false,
      default_media: this.props.list.mediaplan.default_media,
    };
  }

  componentDidMount() {
    this.setState({
      default_media: this.props.list.mediaplan.default_media,
      tab1: this.props.tab1,
      step1: this.props.step1,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      default_media: nextProps.list.mediaplan.default_media,
      tab1: nextProps.tab1,
      step1: nextProps.step1,
    });
  }

  render() {
    return (
      <div className="card" style={{ overflow: "visible" }}>
        <div className="card-header" id="heading1">
          <h5 className="mb-0">
            <Badge color={this.state.step1 ? "primary" : "danger"} pill>
              &#10148;
            </Badge>{" "}
            <button
              className={this.state.step1 ? "btn btn-link text-primary" : "btn btn-link text-danger"}
              type="button"
              data-toggle="collapse"
              data-target="#collapse1"
              aria-expanded="true"
              aria-controls="collapse1"
              onClick={() => {
                this.setState({
                  tab1: !this.state.tab1,
                });
              }}
            >
              Загрузить или выбрать ролик
            </button>
          </h5>
        </div>
        <div
          id="collapse1"
          className={!this.state.tab1 ? "collapse show" : "collapse"}
          aria-labelledby="heading1"
          data-parent="#accordion1"
        >
          <div className="card-body">
            <p>
              Загрузите в платформу рекламные ролики. Предоставьте необходимую документацию согласно категории роликов.
              Затем выберите оба ролика (обе языковые версии) для использования в этом медиаплане. Если используется
              только ролик на государственном языке, пометьте его соответствующим флажком.
            </p>
            <p class="pt-2 pb-2">
              <SelectMedia
                disabled={this.props.list.mediaplan.order_submitted || this.props.list.mediaplan.status_type >= 2}
                media={this.props.list.media}
                mediaplan={this.props.list.mediaplan}
                onSetMediaplanMedia={this.props.onSetMediaplanMedia}
                onAddMediaplanMedia={this.props.onAddMediaplanMedia}
              />
            </p>
            <p class="pt-2 pb-2">
              <Row>
                <Col md="12">
                  <FormGroup
                    style={{
                      top: "-30px",
                    }}
                    row
                  >
                    <Col>
                      <FormGroup check>
                        <Label check>
                          <Input
                            onChange={(e) => {
                              e.persist();
                              if (!this.props.list.mediaplan.only_national_lang) {
                                swalWithBootstrapButtons
                                  .fire({
                                    title: "Вы уверены?",
                                    text: "Эта операция необратима. Выходы на других языках будут автоматически заменены на ролик на государственном языке",
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Да!",
                                    cancelButtonText: "Нет",
                                    reverseButtons: true,
                                  })
                                  .then((result) => {
                                    if (result.value) {
                                      var forms = {
                                        only_national_lang: !this.props.list.mediaplan.only_national_lang,
                                      };
                                      this.props.onUpdateMediaplan(this.props.list.mediaplan.id, forms);
                                      swalWithBootstrapButtons.fire(
                                        "Успешное завершение",
                                        "Ваш медиаплан поддерживает только государственный язык",
                                        "success"
                                      );
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      swalWithBootstrapButtons.fire(
                                        "Операция остановлена",
                                        "Ваш медиаплан в безопасности",
                                        "error"
                                      );
                                    }
                                  });
                              } else {
                                var forms = {
                                  only_national_lang: !this.props.list.mediaplan.only_national_lang,
                                };
                                this.props.onUpdateMediaplan(this.props.list.mediaplan.id, forms);
                              }
                            }}
                            disabled={
                              !this.props.list.mediaplan.get_is_checkout ||
                              this.props.list.mediaplan.get_channel.only_national_lang
                            }
                            type="checkbox"
                            checked={
                              this.props.list.mediaplan.only_national_lang ||
                              this.props.list.mediaplan.get_channel.only_national_lang
                            }
                          />{" "}
                          Только государственный язык
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <hr />
                  <p className="pb-2">
                    <InputGroup
                      style={{
                        width: "550px",
                      }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Предполагаемый хронометраж ролика:</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        disabled={this.props.list.mediaplan.media_1}
                        onChange={(e) => {
                          this.setState({
                            default_media: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          var duration = parseInt(this.state.default_media);
                          if (!isNaN(duration)) {
                            if (window.MIN_MEDIA_DURATION <= duration && duration <= window.MAX_MEDIA_DURATION) {
                            } else {
                              duration = 30;
                            }
                          } else {
                            duration = 30;
                          }
                          if (this.props.list.mediaplan.get_duration_is_multiple_of_5) {
                            duration = Math.ceil(duration / 5) * 5;
                          }
                          this.setState({
                            default_media: duration,
                          });
                          var forms = {
                            default_media: duration,
                          };
                          this.props.onUpdateMediaplan(this.props.list.mediaplan.id, forms);
                        }}
                        value={this.state.default_media}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>секунд</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormText color="muted">
                      Предполагаемый хронометраж ролика от {window.MIN_MEDIA_DURATION} до {window.MAX_MEDIA_DURATION}{" "}
                      секунд{this.props.list.mediaplan.get_duration_is_multiple_of_5 && "."}
                      {this.props.list.mediaplan.get_duration_is_multiple_of_5 && (
                        <strong className="mx-1">
                          Внимание: на этом медиаресурсе хронометраж ролика должен быть кратным 5 секундам
                        </strong>
                      )}
                    </FormText>
                  </p>
                </Col>
              </Row>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
