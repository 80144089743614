import { GET_USER, GET_USER_BALANCE, LIST_COUNTRIES } from "./types";
import { NotificationManager } from "react-notifications";

import API from "../api/list";

export const getUser = () => {
  return (dispatch) => {
    API.getProfile().then(function (user) {
      dispatch(infoUser(user));
    });
  };
};

export const getUserBalance = () => {
  return (dispatch) => {
    API.getUserBalance().then(function (userBalance) {
      dispatch(infoUserBalance(userBalance));
    });
  };
};

export const infoUser = (user) => ({
  type: GET_USER,
  payload: {
    user: user,
  },
});

export const infoUserBalance = (userBalance) => ({
  type: GET_USER_BALANCE,
  payload: {
    userBalance: userBalance.userBalance,
  },
});

export const updatePofile = (forms) => {
  return (dispatch) => {
    API.updateProfile(forms).then((response) => {
      switch (response.status) {
        case 200:
          NotificationManager.success("Профайл сохранен", "", 15000);
          break;
        case 400:
          response.json().then((data) => {
            Object.keys(data).forEach(function (element) {
              let obj = {};
              obj[element] = true;
            }, this);
            //FIXME not defined: element
            //NotificationManager.error(data[element], "", 15000);
          }, this);
          break;
      }
    }, this);
  };
};

export const updateCertificate = (forms) => {
  return (dispatch) => {
    API.updateCertificate(forms).then((response) => {
      switch (response.status) {
        case 200:
          NotificationManager.success("Сертификат обновлен", "", 15000);
          response.json().then((data) => {
            dispatch(infoUser(data));
          }, this);
          break;
        case 400:
          response.json().then((data) => {
            Object.keys(data).forEach(function (element) {
              let obj = {};
              obj[element] = true;
            }, this);
            // FIXME element not defined
            //NotificationManager.error(data[element], "", 15000);
          }, this);
          break;
      }
    }, this);
  };
};

export const passwordChange = (forms) => {
  API.passwordChange(forms).then((response) => {
    switch (response.status) {
      case 200:
        NotificationManager.success("Пароль обновлен");
        break;
      case 400:
        response.json().then((data) => {
          Object.keys(data).forEach(function (element) {
            NotificationManager.error(data[element], "", 15000);
          }, this);
        }, this);
        break;
    }
  }, this);
};

export const getCountries = () => {
  return (dispatch) => {
    API.countries().then(function (countries) {
      dispatch(listCountries(countries));
    });
  };
};

export const listCountries = (countries) => ({
  type: LIST_COUNTRIES,
  payload: {
    countries: countries,
  },
});

export const returnCallFormSubmit = (form, callback) => {
  return (dispatch) => {
    API.returnCallFormSubmit(form).then(function (result) {
      callback(result);
    });
  };
};
