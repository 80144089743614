import { connect } from "react-redux";
import Profile from "../components/profile";
import { updatePofile, getCountries, getUserBalance, updateCertificate } from "../actions/users";

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateProfile: (forms) => {
      return dispatch(updatePofile(forms));
    },
    updateCertificate: (forms) => {
      return dispatch(updateCertificate(forms));
    },
    onGetUserBalance: () => {
      return dispatch(getUserBalance());
    },
    onGetCountries: () => {
      return dispatch(getCountries());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    userBalance: state.users.userBalance,
    countries: state.users.countries,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
