import React from "react";
import { Collapse, Row, Col, CardBody, CardHeader, ListGroupItem, ListGroup, Card } from "reactstrap";
import ToggleDisplay from "react-toggle-display";

export default class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: false,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Row className="pt-3 pb-3">
        <Col md="12">
          <Card>
            <CardHeader>
              <a
                href="#history"
                onClick={() => {
                  this.setState({
                    history: !this.state.history,
                  });
                }}
              >
                История ({this.props.list.mediaplan.get_events.length})
              </a>
            </CardHeader>
            <Collapse isOpen={this.state.history}>
              <CardBody>
                <ListGroup>
                  <ToggleDisplay show={this.props.list.mediaplan.get_events.length == 0}>
                    <ListGroupItem>
                      <small>Пусто</small>
                    </ListGroupItem>
                  </ToggleDisplay>
                  <ToggleDisplay show={this.props.list.mediaplan.get_events.length > 0}>
                    {this.props.list.mediaplan.get_events.map((event, i) => {
                      return (
                        <ListGroupItem>
                          [<small>{event.get_date}</small>] <small>{event.description}</small>
                        </ListGroupItem>
                      );
                    })}
                  </ToggleDisplay>
                </ListGroup>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}
