import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink,
  Label,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap";
import { reactLocalStorage } from "reactjs-localstorage";
import { browserHistory } from "react-router";
import Swal from "sweetalert2";

import Cookies from "../screens/modal/cookies";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      returnCallFirstOpen: false,
      username: "",
    };
    this.toggleReturnCallModal = this.toggleReturnCallModal.bind(this);
    this.modalReturnCallSubmit = this.modalReturnCallSubmit.bind(this);
    this.openReturnCallModal = this.openReturnCallModal.bind(this);
  }

  componentWillMount() {
    if (this.props.check) {
      this.props.onGetUser();
    }
  }

  componentDidMount() {
    if (this.props.check) {
      if (reactLocalStorage.get("token") != undefined) {
        this.setState({
          username: reactLocalStorage.get("username"),
        });
      } else {
        reactLocalStorage.clear();
        browserHistory.push("/login");
      }
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  logout() {
    reactLocalStorage.clear();
    browserHistory.push("/login");
    window.location.reload();
  }

  openReturnCallModal() {
    const newState = { modalReturnCall: true };
    if (!this.state.returnCallFirstOpen) {
      newState.returnCallFirstOpen = true;
      newState.returnCallName = this.props.user.first_name;
      newState.returnCallPhone = this.props.user.mobile_phone;
    }
    this.setState(newState);
  }

  toggleReturnCallModal() {
    this.setState({ modalReturnCall: !this.state.modalReturnCall });
  }

  modalReturnCallSubmit(e) {
    e.preventDefault();
    if (!this.state.returnCallName || !this.state.returnCallPhone) return;

      this.setState({ modalReturnCall: false }, () => {
        this.props.returnCallFormSubmit(
          {
            name: this.state.returnCallName,
            phone: this.state.returnCallPhone,
          },
          (resp) => {
            if (resp.status === "ok") { // captcha check fake OK response
              Swal.fire(
                "Ошибка",
                "Пожалуйста, повторите запрос позже!",
                "warning"
              );
            } else {
              Swal.fire("Спасибо!", "Мы свяжемся с вами", "success");
            }
          }
        );
      });
    return false;
  }

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.state.modalReturnCall} toggle={this.toggleReturnCallModal}>
          <ModalHeader toggle={this.toggleReturnCallModal}>Бесплатный звонок</ModalHeader>
          <ModalBody>
            <form action="" method="POST" onSubmit={this.modalReturnCallSubmit}>
              <FormGroup>
                <Label>Ваше имя:</Label>
                <Input
                  type="text"
                  name="name"
                  value={this.state.returnCallName || ""}
                  onChange={(event) => {
                    this.setState({
                      returnCallName: event.target.value,
                    });
                  }}
                  id="returnCallName"
                  placeholder=""
                />
              </FormGroup>
              <FormGroup>
                <Label>Ваш телефон:</Label>
                <Input
                  type="text"
                  name="name"
                  value={this.state.returnCallPhone || ""}
                  onChange={(event) => {
                    this.setState({
                      returnCallPhone: event.target.value,
                    });
                  }}
                  id="returnCallPhone"
                  placeholder=""
                />
              </FormGroup>
              <FormGroup>
                <Button
                  type="submit"
                  disabled={!this.state.returnCallName || !this.state.returnCallPhone}
                  className="btn btn-block"
                >
                  Отправить
                </Button>
              </FormGroup>
            </form>
          </ModalBody>
        </Modal>
        {!(this.props.user || {}).id ? (
          <div>
            <Cookies />
            <Navbar color="light" light expand="md">
              <NavbarBrand href="/">
                <img className="logo" src="/static/images/logo.svg" />
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle.bind(this)} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <ul className="navbar-nav w-100 header-nav">
                  <li className="nav-item return-call">
                    <a
                      className="nav-link return-call-modal-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.openReturnCallModal();
                      }}
                    >
                      +7 (701) 301-7618
                      <br />
                      заказать обратный звонок
                    </a>
                  </li>
                  <NavItem>
                    <NavLink href="/registration">Регистрация</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/login">Войти</NavLink>
                  </NavItem>
                </ul>
              </Collapse>
            </Navbar>
            <br />
          </div>
        ) : (
          <div>
            <Cookies />
            <Navbar color="light" light expand="md">
              <NavbarBrand href="/mediaplans">
                <img className="logo" src="/static/images/logo.svg" />
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle.bind(this)} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <ul className="navbar-nav w-100 header-nav">
                  <li className="nav-item return-call">
                    <a
                      className="nav-link return-call-modal-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.openReturnCallModal();
                      }}
                    >
                      +7 (701) 301-7618
                      <br />
                      заказать обратный звонок
                    </a>
                  </li>
                  <NavItem>
                    <NavLink href="/mediaplans">Медиапланы</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/mediastock">Медиатека</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/profile/edit">Профайл</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#" onClick={this.logout}>
                      (Выход)
                    </NavLink>
                  </NavItem>
                </ul>
              </Collapse>
            </Navbar>
            <br />
          </div>
        )}
      </React.Fragment>
    );
  }
}
