import React from "react";
import update from "immutability-helper";
import {
  Button,
  Row,
  ButtonGroup,
  CardHeader,
  CardFooter,
  CardBody,
  Badge,
  ListGroup,
  ListGroupItem,
  Card,
  Input,
  Label,
  Col,
} from "reactstrap";
import Dropzone from "react-dropzone";
import Loadable from "react-loading-overlay";
import { PercentArc } from "./common";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import Swal from "sweetalert2";
import ToggleDisplay from "react-toggle-display";

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

function withoutKey(o, w) {
  const r = {};
  for (let [k, v] of Object.entries(o)) {
    if (k === w) continue;
    r[k] = v;
  }
  return r;
}

const ChannelsOptions = ({ channels }) =>
  [
    ["tv", "ТВ"],
    ["radio", "Радио"],
    ["sponsor", "Спонсорство"],
    ["internet", "Интернет"],
  ]
    .map(([k, label]) => {
      const sub_channels = (channels || []).filter((x) => x.media_type === k);
      if (!sub_channels.length) return null;
      return (
        <optgroup label={label}>
          {sub_channels.map(({ id, full_name }) => (
            <option key={id} value={id}>
              {full_name}
            </option>
          ))}
        </optgroup>
      );
    })
    .filter((x) => x);

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: "btn btn-success",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false,
});

const MEDIA_TYPES = {
  tv: "Видео",
  radio: "Аудио",
  internet: "Изображение",
};

const TYPES_VIDEO = ".mp4,.mkv,.avi,.mov,.mxf";
const TYPES_AUDIO = ".mp3, .wav";

const MEDIA_ACCEPT = {
  tv: TYPES_VIDEO,
  radio: TYPES_AUDIO,
  internet: TYPES_VIDEO,
};

const validate_url_non_required = (v) => (v && !URL_REGEX.exec(v) ? "Введите правильный URL." : null);

const FIELD_VALIDATORS = {
  product_category: (v) => (!v ? "Укажите товарную категорию ролика" : null),
  target_url: validate_url_non_required,
  pixel_1: validate_url_non_required,
  pixel_2: validate_url_non_required,
};

export default class Media extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type_advert_media: "advert_video",
      media_item: {},
      type: null,
      lang: null,
      product_category: "",
      brand: null,
      target_url: "",
      pixel_1: "",
      pixel_2: "",
      refresh: 0,
      name_extra: "",
      files: [],
      media: [],
      docs: [],
      langs: {},
      modal: false,
      currentBrand: "all",
      currentChannel: "all",
      currentLang: "all",
      currentCategory: "all",
      channelPreSelected: false,
      loading: false,
      errors: {},
    };
    this.toggle = this.toggle.bind(this);
    this.checkOneField = this.checkOneField.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  componentDidMount() {
    this.props.onListChannels();
    this.props.onListMyChannels();
    this.props.onListMedia();
    this.props.onListMyBrands();
    this.props.onListMyCategories();
    this.props.onListCategories();
  }

  componentWillReceiveProps(nextProps) {
    var langs = {};
    for (var i = 0; i < nextProps.media.length; i++) {
      if (!langs.hasOwnProperty(nextProps.media[i]["lang"])) {
        langs[nextProps.media[i]["lang"]] = nextProps.media[i]["get_lang"];
      }
    }
    const channelChange = {};
    if (this.props.channels !== nextProps.channels) {
      if (this.props.preSelectChannel && !this.state.channelPreSelected) {
        channelChange.channel = parseInt(this.props.preSelectChannel);
        channelChange.channelPreSelected = true;
      }
    }
    const brandChange = { media: nextProps.media };
    // "Consume" the lastSelectedBrand when brands are loaded
    if (this.state.lastSelectedBrand && this.props.myBrands !== nextProps.myBrands) {
      for (const brand of nextProps.myBrands) {
        if (brand.label === this.state.lastSelectedBrand) {
          brandChange.lastSelectedBrand = null;
          if (typeof this.state.lastSelectedBrandItem === "number") {
            brandChange.media = update(brandChange.media, {
              [this.state.lastSelectedBrandItem]: { $merge: { brand: brand.value } },
            });
            brandChange.lastSelectedBrandItem = null;
          } else {
            brandChange.brand = brand.value;
          }
          break;
        }
      }
    }
    this.setState({
      currentLang: "all",
      currentCategory: "all",
      currentBrand: "all",
      langs: langs,
      ...brandChange,
      ...channelChange,
    });
  }

  onDropMedia(files) {
    if (files.length > 0) {
      this.setState({
        name_extra: "",
        files,
        errors: withoutKey(this.state.errors, "file"),
      });
    }
  }

  onDropDocs(docs) {
    this.setState({
      docs,
    });
  }

  edit(file) {
    var forms = {
      edit: true,
      lang: file.lang,
      product_category: file.product_category,
      brand: file.brand,
      name_extra: file.name_extra,
      type_advert_media: file.type_advert_media,
      target_url: file.target_url,
      pixel_1: file.pixel_1,
      pixel_2: file.pixel_2,
    };
    this.props.onEditMedia(file.id, forms);
  }

  save(file) {
    var forms = {
      edit: false,
      lang: file.lang,
      product_category: file.product_category,
      brand: file.brand,
      name_extra: file.name_extra,
      type_advert_media: file.type_advert_media,
      target_url: file.target_url,
      pixel_1: file.pixel_1,
      pixel_2: file.pixel_2,
    };
    this.props.onEditMedia(file.id, forms);
  }

  selected(file) {
    this.props.onSetMediaplanMedia({
      type: this.props.type,
      mediaplan: this.props.mediaplan,
      media: file.id,
    });
  }

  newMedia() {
    let selectedChannel = null;

    for (let c of this.props.channels || []) {
      if (c.id === this.state.channel) {
        selectedChannel = c;
      }
    }

    // Show or hide particular fields
    const channelType = this.getChannelType(selectedChannel.media_type);
    const additionalState = {};

    var file = {
      url: this.state.files[0],
      doc1: this.state.docs.length > 0 ? this.state.docs[0] : "",
      doc2: this.state.docs.length > 1 ? this.state.docs[1] : "",
      doc3: this.state.docs.length > 2 ? this.state.docs[2] : "",
      doc4: this.state.docs.length > 3 ? this.state.docs[3] : "",
      doc5: this.state.docs.length > 4 ? this.state.docs[4] : "",
    };

    var errors = {};

    // "onSubmit on form"-only validators
    if (!this.state.files[0]) {
      errors["file"] = "Выберите файл";
    }

    if (channelType.is_tvradio) {
      if (!this.state.lang) {
        errors["lang"] = "Укажите язык ролика";
      }
    } else {
      additionalState.lang = "kaz";
    }

    if (channelType.is_tvradio && !this.state.product_category) {
      errors["product_category"] = "Укажите товарную категорию ролика";
    }

    // Combined "onBlur on field" / "onSubmit on form" validators
    let fields_to_validate = [];

    if (channelType.is_tvradio) {
      fields_to_validate = [...fields_to_validate, "product_category"];
    }

    if (channelType.is_internet) {
      fields_to_validate = [...fields_to_validate, "target_url", "pixel_1", "pixel_2"];
    }

    for (let k of fields_to_validate) {
      let e = this.checkOneField(k, true);
      if (e) errors[k] = e;
    }

    if (Object.keys(errors).length) {
      NotificationManager.error("Пожалуйста, исправьте ошибки ниже", "", 5000);
      this.setState({ errors });
      return false;
    }

    if (selectedChannel.media_type === "radio") {
      additionalState.media_type = "audio";
    }

    file = Object.assign(file, this.state, additionalState);
    if (!file.brand) file.brand = "";
    this.setState({ loading: true });
    this.props.onAddMedia(file).then((errors) => {
      if (errors) {
        this.setState({
          errors,
          loading: false,
        });
      } else {
        this.setState({
          files: [],
          docs: [],
          channel: "alls",
          loading: false,
        });
      }
    });
  }

  deleteMedia(file) {
    swalWithBootstrapButtons
      .fire({
        title: "Вы уверены?",
        text: "Вы уверены, что хотите удалить ролик?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Да, удалить!",
        cancelButtonText: "Нет, отмена",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          this.props.onDeleteMedia(file.id, ({ result, status }) =>
            swalWithBootstrapButtons.fire(
              status === "error" ? "Не выполнено" : "Выполнено!",
              result,
              status || "success"
            )
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire("Отменено", "Ваш ролик в безопасности", "error");
        }
      });
  }

  getChannelType(media_type) {
    const channelTypeInfo = {};
    if (new Set(["internet"]).has(media_type)) {
      channelTypeInfo.is_internet = true;
    }
    if (new Set(["tv", "radio", "sponsor", null]).has(media_type)) {
      channelTypeInfo.is_tvradio = true;
    }
    return channelTypeInfo;
  }

  checkOneField(field, dontSet) {
    const validator = FIELD_VALIDATORS[field];
    if (!validator) return null;
    const error = validator(this.state[field]);
    if (!error) return null;
    if (!dontSet) this.setState({ errors: { ...this.state.errors, [field]: error } });
    return error;
  }

  render() {
    let selectedChannel = null;

    for (let c of this.props.channels || []) {
      if (c.id === this.state.channel) {
        selectedChannel = c;
      }
    }

    // Show or hide particular fields
    let channelType = this.getChannelType(selectedChannel && selectedChannel.media_type);

    const ErrorMsg = ({ k }) => {
      let msg = this.state.errors[k];
      if (Array.isArray(msg)) {
        // Server results are in arrays: {pixel_1: ['Enter a valid URL]}
        msg = msg.filter((x) => x);
        msg = msg.length ? msg[0] : null;
      }
      return msg ? <div className="invalid-feedback d-block">{msg}</div> : null;
    };

    return (
      <Loadable active={this.state.loading} text={<PercentArc percent={this.props.uploadProgress || 0} />}>
        <NotificationContainer />
        <Row>
          <Col md="12">
            <Button
              color="secondary"
              outline={true}
              size="sm"
              onClick={() => {
                window.history.back();
              }}
            >
              &larr; Вернуться назад
            </Button>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md="12"></Col>
        </Row>
        {/*this.state.files.length > 0 && (
          <Row className="my-3">
            <Col md="12">
              <Card>
                <CardBody>
                  Поля загрузки ролика, которые обязательно нужно указать, помечены красной звёздочкой. Можно добавить
                  новый бренд, впечатав его в соответствующее поле.
                </CardBody>
              </Card>
            </Col>
          </Row>
        )*/}
        <Row>
          <Col md="12">
            <Card>
              <div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <Label htmlFor="channelInput">
                      Выберите медиаресурс, для которого загружается ролик <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      onChange={(event) => {
                        this.setState({ channel: parseInt(event.target.value), errors: {} });
                      }}
                      value={this.state.channel}
                      type="select"
                      name="channel"
                    >
                      <option value="alls">Выберите канал</option>
                      <ChannelsOptions channels={this.props.channels} />
                    </Input>
                  </li>
                  {selectedChannel && (
                    <React.Fragment>
                      <li className="list-group-item">
                        <Card className="file-button">
                          <Dropzone
                            accept={MEDIA_ACCEPT[(selectedChannel || {}).media_type] || ""}
                            multiple={false}
                            onDrop={this.onDropMedia.bind(this)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p
                                  className="p-3 mb-0 d-flex align-items-center justify-content-center btn alert-success font-weight-bold"
                                  style={{ color: "#28a745" }}
                                >
                                  <svg
                                    width="44"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="plus"
                                    className="svg-inline--fa fa-plus fa-w-14 mr-2"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                                    />
                                  </svg>
                                  Выберите файл для загрузки
                                </p>
                              </div>
                            )}
                          </Dropzone>
                        </Card>
                        {this.state.files.length ? (
                          <React.Fragment>
                            Будет загружен файл: <strong>{this.state.files[0].name}</strong>
                          </React.Fragment>
                        ) : null}
                        <ErrorMsg k="file" />
                      </li>
                      {channelType.is_tvradio && (
                        <li className="list-group-item">
                          <Label htmlFor="langInput">
                            Язык <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            onChange={(event) => {
                              this.setState({ lang: event.target.value });
                            }}
                            type="select"
                            name="lang"
                          >
                            <option value="alls">Выбрать язык ролика</option>
                            <option value="kaz">Казахский</option>
                            <option value="rus">Русский</option>
                            <option value="krg">Кыргызский</option>
                            <option value="taj">Таджикский</option>
                            <option value="eng">English</option>
                          </Input>
                          <ErrorMsg k="language" />
                        </li>
                      )}
                      <li className="list-group-item">
                        <Label htmlFor="brandInput">Бренд (выберите или впечатайте новый)</Label>
                        <CreatableSelect
                          isClearable
                          placeholder="Выбрать бренд из списка"
                          aria-label="Выбрать бренд из списка"
                          aria-labelledby="Выбрать бренд из списка"
                          noOptionsMessage={(inputValue) => "Нет брендов"}
                          formatCreateLabel={(inputValue) => "Добавить новый"}
                          options={this.props.myBrands}
                          value={[...this.props.myBrands.filter((x) => x.value === this.state.brand), null][0]}
                          onChange={(event) => {
                            this.setState({
                              brand: event && event.value,
                            });
                          }}
                          onCreateOption={(event) => {
                            var forms = {
                              name: event,
                            };
                            this.setState({ lastSelectedBrand: event }, (_) => this.props.onNewBrand(forms));
                          }}
                        />
                        <ErrorMsg k="brand" />
                      </li>
                      {channelType.is_tvradio && (
                        <li className="list-group-item">
                          <Label htmlFor="brandInput">
                            Товарная категория <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Select
                            placeholder="Выбрать товарную категорию"
                            aria-label="Выбрать товарную категорию"
                            aria-labelledby="Выбрать товарную категорию"
                            noOptionsMessage={(inputValue) => "Нет товарной категории"}
                            options={this.props.categories}
                            onChange={(event) => {
                              this.props.categories.forEach(function (item) {
                                if (item.value == event.value) {
                                  this.setState({
                                    type: item.documentation,
                                  });
                                }
                              }, this);
                              this.setState({
                                product_category: event.value,
                                errors: withoutKey(this.state.errors, "product_category"),
                              });
                            }}
                            onBlur={(_) => this.checkOneField("product_category")}
                          />
                          <ErrorMsg k="product_category" />
                        </li>
                      )}
                      {channelType.is_internet && (
                        <li className="list-group-item">
                          <Label htmlFor="urlInput">Введите ссылку перехода</Label>
                          <Input
                            placeholder="Например, https://www.company.kz/product-1/"
                            aria-label="Введите ссылку перехода"
                            aria-labelledby="Введите ссылку перехода"
                            value={this.state.target_url}
                            onChange={(event) => {
                              this.setState({
                                target_url: event.target.value,
                                errors: withoutKey(this.state.errors, "target_url"),
                              });
                            }}
                            onBlur={(_) => this.checkOneField("target_url")}
                          />
                          <ErrorMsg k="target_url" />
                        </li>
                      )}
                      {channelType.is_internet && (
                        <li className="list-group-item">
                          <Label htmlFor="urlInput">Пиксель 1</Label>
                          <Input
                            placeholder="Например, https://www.track.com/pixel-1.gif"
                            aria-label="Пиксель 1"
                            aria-labelledby="Пиксель 1"
                            value={this.state.pixel_1}
                            onChange={(event) => {
                              this.setState({
                                pixel_1: event.target.value,
                                errors: withoutKey(this.state.errors, "pixel_1"),
                              });
                            }}
                            onBlur={(_) => this.checkOneField("pixel_1")}
                          />
                          <ErrorMsg k="pixel_1" />
                        </li>
                      )}
                      {channelType.is_internet && (
                        <li className="list-group-item">
                          <Label htmlFor="urlInput">Пиксель 2</Label>
                          <Input
                            placeholder="Например, https://www.track.com/pixel-2.gif"
                            aria-label="Пиксель 2"
                            aria-labelledby="Пиксель 2"
                            value={this.state.pixel_2}
                            onChange={(event) => {
                              this.setState({
                                pixel_2: event.target.value,
                                errors: withoutKey(this.state.errors, "pixel_2"),
                              });
                            }}
                            onBlur={(_) => this.checkOneField("pixel_2")}
                          />
                          <ErrorMsg k="pixel_2" />
                        </li>
                      )}
                      <li className="list-group-item">
                        <div>
                          {this.state.type ? (
                            <p>
                              Загрузите документацию согласно перечню: <b>{this.state.type}</b>
                            </p>
                          ) : null}
                          {!channelType.is_internet && (
                            <Card className="file-button">
                              <Dropzone accept=".pdf,.doc,.docx" onDrop={this.onDropDocs.bind(this)} multiple={true}>
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p
                                      className="p-3 mb-0 d-flex align-items-center justify-content-center btn alert-success font-weight-bold"
                                      style={{ color: "#28a745" }}
                                    >
                                      Выберите документы (.pdf, .doc, .docx) для загрузки
                                    </p>{" "}
                                  </div>
                                )}
                              </Dropzone>
                            </Card>
                          )}
                          <div className="pt-2">
                            {this.state.docs.map((doc) => (
                              <div>
                                <Badge key={doc.name} color="primary" pill>
                                  {doc.name}
                                </Badge>{" "}
                              </div>
                            ))}
                          </div>
                          <div className="pt-2">
                            {this.state.docs.length > 0 ? (
                              <Button
                                onClick={() => {
                                  this.setState({
                                    docs: [],
                                  });
                                }}
                                size="sm"
                                color="danger"
                              >
                                Удалить документы
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item text-center">
                        <Button className="btn btn-success" onClick={this.newMedia.bind(this)}>
                          Загрузить ролик{channelType.is_internet ? "" : " и документы"}
                        </Button>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </Card>
            {/*this.state.files.length > 0 ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Card>
                <CardBody>
                  Добавьте все видео- или аудиоролики, используя кнопку загрузки выше. Загруженные ролики появятся в
                  списке ниже, где вы можете редактировать их атрибуты и файлы-приложения.
                </CardBody>
              </Card>
            )*/}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="3" />
          <Col md="3">
            <ul className="list-inline">
              <li>Список каналов:</li>
              <li>
                {" "}
                <Input
                  onChange={(event) => {
                    var media = [];
                    if (event.target.value == "all") {
                      media = this.props.media;
                    } else {
                      media = this.props.media.filter(function (item, index) {
                        if (event.target.value === "none") {
                          return !item.channel;
                        }
                        return item.channel == event.target.value;
                      });
                    }
                    this.setState({
                      currentCategory: "all",
                      currentLang: "all",
                      currentBrand: "all",
                      currentChannel: event.target.value,
                      media: media,
                    });
                  }}
                  defaultValue={this.state.currentChannel}
                  value={this.state.currentChannel}
                  type="select"
                  name="currentChannel"
                >
                  <option value="all">Все</option>
                  <option value="none">(нет)</option>
                  <ChannelsOptions channels={this.props.myChannels} />
                </Input>
              </li>
            </ul>
          </Col>
          <Col md="3">
            <ul className="list-inline">
              <li>Список брендов:</li>
              <li>
                {" "}
                <Input
                  onChange={(event) => {
                    var media = [];
                    this.setState({
                      currentCategory: "all",
                      currentLang: "all",
                      currentBrand: event.target.value,
                      media: [],
                    });
                    if (event.target.value == "all") {
                      this.setState({
                        media: this.props.media,
                      });
                    } else {
                      this.props.media.map(function (item, index) {
                        if (item.brand == event.target.value) {
                          media.push(item);
                          this.setState({
                            media: media,
                          });
                        }
                      }, this);
                    }
                  }}
                  defaultValue={this.state.currentBrand}
                  value={this.state.currentBrand}
                  type="select"
                  name="currentBrand"
                >
                  <option value="all">Все</option>
                  {this.props.myBrands.map(function (item, index) {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                  ;
                </Input>
              </li>
            </ul>
          </Col>
          <Col md="3">
            <ul class="list-inline">
              <li>Список языков:</li>
              <li>
                {" "}
                <Input
                  onChange={(event) => {
                    var media = [];
                    this.setState({
                      currentCategory: "all",
                      currentBrand: "all",
                      currentLang: event.target.value,
                      media: [],
                    });
                    if (event.target.value == "all") {
                      this.setState({
                        media: this.props.media,
                      });
                    } else {
                      this.props.media.map(function (item, index) {
                        if (item.lang == event.target.value) {
                          media.push(item);
                          this.setState({
                            media: media,
                          });
                        }
                      }, this);
                    }
                  }}
                  defaultValue={this.state.currentLang}
                  value={this.state.currentLang}
                  type="select"
                  name="currentLang"
                >
                  <option value="all">Все</option>
                  {Object.keys(this.state.langs).map(function (key, index) {
                    return <option value={key}>{this.state.langs[key]}</option>;
                  }, this)}
                </Input>
              </li>
            </ul>
          </Col>
          {/*<Col md="3">
            <ul class="list-inline">
              <li>Товарная категория:</li>
              <li>
                {" "}
                <Input
                  onChange={(event) => {
                    var media = [];
                    this.setState({
                      currentCategory: event.target.value,
                      currentLang: "all",
                      currentBrand: "all",
                      media: [],
                    });
                    if (event.target.value == "all") {
                      this.setState({
                        media: this.props.media,
                      });
                    } else {
                      this.props.media.map(function (item, index) {
                        if (item.product_category == event.target.value) {
                          media.push(item);
                          this.setState({
                            media: media,
                          });
                        }
                      }, this);
                    }
                  }}
                  defaultValue={this.state.currentCategory}
                  value={this.state.currentCategory}
                  type="select"
                  name="currentCategory"
                >
                  <option value="all">Все</option>
                  {this.props.myCategories.map(function (item, index) {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                  ;
                </Input>
              </li>
            </ul>
          </Col>*/}
        </Row>
        {this.state.media.length == 0 && this.state.loading == false ? (
          <Row>
            <Col md="12" className="text-center">
              <img width="300px" src="/static/images/style/empty.png" className="img-fluid" />
            </Col>
          </Row>
        ) : null}
        {this.state.media.length > 0 ? (
          <div>
            {this.state.media.map(function (item, index) {
              const channelType = this.getChannelType(item.get_channel_media_type);
              return (
                <Card className="mb-4">
                  <CardHeader>
                    <span className="float-left">
                      <strong>
                        <a target="_blank" href={item.url}>
                          {item.title}
                        </a>
                      </strong>{" "}
                      ({item.duration} сек)
                    </span>
                    <span className="float-right">
                      <b>{item.get_media_type}</b>
                    </span>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <ListGroup>
                          <ListGroupItem>
                            <div className="d-flex w-100 justify-content-between">
                              <small className="text-muted">Медиаресурс</small>
                            </div>
                            {item.edit ? (
                              <Input disabled type="text" defaultValue={item.get_channel_name} />
                            ) : (
                              <span>{item.get_channel_name}</span>
                            )}
                          </ListGroupItem>
                          <ListGroupItem>
                            <div className="d-flex w-100 justify-content-between">
                              <small className="text-muted">Дополнительные данные</small>
                            </div>

                            {item.edit ? (
                              <Input
                                type="text"
                                name="name_extra"
                                disabled={false}
                                onChange={(event) => {
                                  item.name_extra = event.target.value;
                                }}
                                id="extraInput"
                                defaultValue={item.name_extra}
                              />
                            ) : (
                              <span>{!item.name_extra ? "-" : item.name_extra}</span>
                            )}
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                      <Col md="4">
                        {channelType.is_tvradio && (
                          <ListGroup>
                            <ListGroupItem>
                              <div className="d-flex w-100 justify-content-between">
                                <small className="text-muted">Язык</small>
                              </div>
                              {item.edit ? (
                                <Input
                                  onChange={(event) => {
                                    item.lang = event.target.value;
                                  }}
                                  defaultValue={item.lang}
                                  disabled={item.get_mediaplan_bool}
                                  type="select"
                                  name="lang"
                                >
                                  <option value="kaz">Казахский</option>
                                  <option value="rus">Русский</option>
                                  <option value="krg">Кыргызский</option>
                                  <option value="taj">Таджикский</option>
                                  <option value="eng">English</option>
                                </Input>
                              ) : (
                                item.get_lang
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <div className="d-flex w-100 justify-content-between">
                                <small className="text-muted">Бренд (выберите или впечатайте новый)</small>
                              </div>
                              {item.edit ? (
                                <CreatableSelect
                                  isClearable={true}
                                  placeholder="Выбрать бренд из списка"
                                  aria-label="Выбрать бренд из списка"
                                  aria-labelledby="Выбрать бренд из списка"
                                  isDisabled={false}
                                  noOptionsMessage={(inputValue) => "Нет брендов"}
                                  formatCreateLabel={(inputValue) => "Добавить новый"}
                                  options={this.props.myBrands}
                                  value={[...this.props.myBrands.filter((x) => x.value === item.brand), null][0]}
                                  onChange={(event) => {
                                    this.setState({
                                      media: update(this.state.media, {
                                        [index]: { $merge: { brand: event && event.value } },
                                      }),
                                    });
                                  }}
                                  onCreateOption={(event) => {
                                    var forms = {
                                      name: event,
                                    };
                                    this.setState({ lastSelectedBrand: event, lastSelectedBrandItem: index }, (_) =>
                                      this.props.onNewBrand(forms)
                                    );
                                  }}
                                />
                              ) : (
                                <span>{item.brand ? item.get_brand.label : "-"}</span>
                              )}
                            </ListGroupItem>
                          </ListGroup>
                        )}
                        {channelType.is_internet && (
                          <ListGroup>
                            <ListGroupItem>
                              <div className="d-flex w-100 justify-content-between">
                                <small className="text-muted">Пиксель 1</small>
                              </div>
                              <div className={item.edit ? "input-placeholder" : null}>{item.pixel_1 || "-"}</div>
                            </ListGroupItem>
                            <ListGroupItem>
                              <div className="d-flex w-100 justify-content-between">
                                <small className="text-muted">Пиксель 2</small>
                              </div>
                              <div className={item.edit ? "input-placeholder" : null}>{item.pixel_2 || "-"}</div>
                            </ListGroupItem>
                          </ListGroup>
                        )}
                      </Col>
                      <Col md="4">
                        <ListGroup>
                          {channelType.is_tvradio && (
                            <ListGroupItem>
                              <div className="d-flex w-100 justify-content-between">
                                <small className="text-muted">Категория</small>
                              </div>
                              {item.edit ? (
                                <Select
                                  placeholder="Выбрать товарную категорию"
                                  aria-label="Выбрать товарную категорию"
                                  aria-labelledby="Выбрать товарную категорию"
                                  noOptionsMessage={(inputValue) => "Нет товарной категории"}
                                  isDisabled={false}
                                  defaultValue={item.get_product_category}
                                  options={this.props.categories}
                                  onChange={(event) => {
                                    item.product_category = event.value;
                                  }}
                                />
                              ) : (
                                <span>{item.get_product_category_name}</span>
                              )}
                            </ListGroupItem>
                          )}
                          {channelType.is_internet && (
                            <ListGroupItem>
                              <div className="d-flex w-100 justify-content-between">
                                <small className="text-muted">Ссылка перехода</small>
                              </div>
                              <div className={item.edit ? "input-placeholder" : null}>{item.target_url || "-"}</div>
                            </ListGroupItem>
                          )}
                          <ListGroupItem>
                            <div className="d-flex w-100 justify-content-between">
                              <small className="text-muted">Используется в кампании</small>
                            </div>
                            <div className={item.edit ? "input-placeholder" : null}>{item.get_mediaplan}</div>
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                    </Row>
                    <hr />
                    {item.get_mediaplan_verified ? (
                      <Row>
                        <Col>
                          {item.get_docs.map((doc) => {
                            return doc.is_active ? (
                              <div>
                                <Badge
                                  className="m-1 p-1"
                                  href={doc.url}
                                  target="_blank"
                                  key={doc}
                                  color="primary"
                                  pill
                                >
                                  {doc.name}
                                </Badge>
                              </div>
                            ) : null;
                          })}
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <ToggleDisplay show={item.edit}>
                            {item.get_docs.map((doc) => {
                              return doc.is_active ? (
                                <div className="m-1 p-1">
                                  <Badge
                                    href="#delete"
                                    key={doc}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      var forms = {};
                                      forms[doc.field] = null;
                                      this.props.onEditMedia(item.id, forms);
                                    }}
                                    color="danger"
                                    pill
                                  >
                                    {doc.name} [x]
                                  </Badge>
                                </div>
                              ) : (
                                <div className="m-1 p-1">
                                  <input
                                    id={`doc-${item.id}`}
                                    ref={doc}
                                    placeholder="Выбрать документ (.pdf,.doc,.docx)"
                                    type="file"
                                    style={{
                                      display: "none",
                                    }}
                                    accept=".pdf,.doc,.docx"
                                    onChange={(event) => {
                                      var forms = {};
                                      forms[doc.field] = event.target.files[0];
                                      this.props.onEditMediaByDoc(item.id, forms, doc.field);
                                    }}
                                  />
                                  <label className="badge badge-primary cursor" for={`doc-${item.id}`}>
                                    Выбрать документ (.pdf,.doc,.docx)
                                  </label>
                                </div>
                              );
                            })}
                          </ToggleDisplay>
                          <ToggleDisplay show={!item.edit && item.get_docs.length > 0}>
                            {item.get_docs.map((doc) => {
                              return doc.is_active ? (
                                <div>
                                  <Badge
                                    className="m-1 p-1"
                                    href={doc.url}
                                    target="_blank"
                                    key={doc}
                                    color="primary"
                                    pill
                                  >
                                    {doc.name}
                                  </Badge>
                                </div>
                              ) : null;
                            })}
                          </ToggleDisplay>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                  <CardFooter className="text-right">
                    <ButtonGroup>
                      {!channelType.is_internet &&
                        (item.edit ? (
                          <Button onClick={this.save.bind(this, item)} outline={true} color="primary">
                            Сохранить
                          </Button>
                        ) : (
                          <Button onClick={this.edit.bind(this, item)} outline={true} color="primary">
                            Изменить
                          </Button>
                        ))}
                      <Button
                        onClick={() => this.deleteMedia(item)}
                        color="danger"
                        disabled={item.get_mediaplan_bool}
                        outline={true}
                      >
                        Удалить
                      </Button>
                    </ButtonGroup>
                  </CardFooter>
                </Card>
              );
            }, this)}
          </div>
        ) : null}
        <hr className="pb-5 pt-5" />
      </Loadable>
    );
  }
}
