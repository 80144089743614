import { LIST_REGIONS, LIST_INTERNET_CHANNELS } from "./types";

import API from "../api/list";

export const getInternetChannels = () => {
  return (dispatch) => {
    API.getInternetChannels().then(function (data) {
      dispatch(listInternetChannels(data));
    });
  };
};

export const listInternetChannels = (data) => ({
  type: LIST_INTERNET_CHANNELS,
  payload: {
    internetChannels: data,
  },
});

export const getRegions = () => {
  return (dispatch) => {
    API.getRegions().then(function (data) {
      dispatch(listRegions(data));
    });
  };
};

export const listRegions = (data) => ({
  type: LIST_REGIONS,
  payload: {
    regions: data,
  },
});
