import React from "react";
import { Row, Col } from "reactstrap";

export default class Transactions extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Row className="pt-1 pb-1">
          <Col md="6">
            <div class="btn-group btn-group-sm pt-2" role="group">
              <button type="button" class="btn btn-primary">
                Все
              </button>
              <button type="button" class="btn btn-outline-primary">
                Месяц
              </button>
              <button type="button" class="btn btn-outline-primary">
                Год
              </button>
            </div>
          </Col>
          <Col md="6" className="float-right">
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Поиск по примечанию" />
              <small className="form-text text-muted">Например, введите номер медиаплана</small>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Дата транзакции</th>
                  <th scope="col">Медиаплан</th>
                  <th scope="col">Сумма</th>
                  <th scope="col">Примечание</th>
                  <th scope="col">Статус</th>
                </tr>
              </thead>
              <tbody />
            </table>
          </Col>
        </Row>
        <Row className="pt-3 pb-5">
          <Col md="4">
            <nav>
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">&laquo;</span>
                  </a>
                </li>
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link disabled" href="#">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </Col>
          <Col md="8" className="text-right">
            <div>
              ИТОГО баланc: <b>0,00 KZT</b>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
