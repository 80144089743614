import { connect } from "react-redux";
import { getPoints, getScrollReservation, addToScrollReservation, destroyScrollReservation } from "../actions/outdoor";
import Outdoor from "../components/outdoor";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPoints: () => {
      return dispatch(getPoints());
    },
    onGetScrollReservation: (mediaplan) => {
      return dispatch(getScrollReservation(mediaplan));
    },
    onAddToScrollReservation: (mediaplan, forms) => {
      return dispatch(addToScrollReservation(mediaplan, forms));
    },
    onDestroyScrollReservation: (mediaplan, id) => {
      return dispatch(destroyScrollReservation(mediaplan, id));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    list: state.outdoor.list,
    scroll_reservation: state.outdoor.scroll_reservation,
    loading: state.outdoor.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Outdoor);
