import {
  LIST_CHANNELS_FOR_MEDIA_UPLOAD,
  LIST_MY_CHANNELS_FOR_MEDIA_UPLOAD,
  LIST_MEDIA,
  LIST_MEDIAPLANS,
  LOADING_START,
  LIST_MY_BRANDS,
  LIST_MY_CATEGORIES,
  LIST_CATEGORIES,
  UPLOAD_PROGRESS,
} from "./types";
import { NotificationManager } from "react-notifications";

import API from "../api/list";

export const listMyChannelsForMediaUpload = (list) => ({
  type: LIST_MY_CHANNELS_FOR_MEDIA_UPLOAD,
  payload: {
    list: list,
  },
});

const refetchMyLists = (dispatch) => {
  API.getMediaList().then(function (list) {
    dispatch(listMedia(list));
  });
  API.getMyChannelListForMediaUpload().then(function (list) {
    dispatch(listMyChannelsForMediaUpload(list));
  });
};

export const addMedia = (file) => {
  return (dispatch) => {
    return API.newMedia(file, {
      uploadFn: (uploadProgress) => {
        if (uploadProgress === 0) {
          // first time
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 0);
        }
        dispatch({ type: UPLOAD_PROGRESS, uploadProgress });
      },
    })
      .then((resp) => {
        switch (resp.status) {
          case 201:
            NotificationManager.success("Сохранено", "", 15000);
            break;
          default:
            NotificationManager.error(`Неизвестная ошибка. Статус ошибки: ${resp.status}`, "", 15000);
            break;
        }
        refetchMyLists(dispatch);
      })
      .catch(function (err) {
        const resp = err.response;
        const error_msg = [];
        try {
          for (const v of Object.values(err?.response?.data)) {
            for (const msg of v) {
              error_msg.push(msg);
            }
          }
        } catch (e) {
          // noop
        }
        NotificationManager.error(
          error_msg.length ? "Ошибка: " + error_msg.join("; ") : `Неизвестная ошибка. Статус ошибки: ${resp.status}`,
          "",
          15000
        );
      });
  };
};

export const deleteMedia = (id, callback) => {
  return (dispatch) => {
    API.deleteMedia(id).then(function (resp) {
      callback && resp.json().then(callback);
      refetchMyLists(dispatch);
    });
  };
};

export const editMedia = (id, forms) => {
  return (dispatch) => {
    API.updateMedia(id, forms).then(function () {
      refetchMyLists(dispatch);
    });
  };
};

export const editMediaByDoc = (id, forms, key) => {
  return (dispatch) => {
    API.updateMediaByDoc(id, forms, key).then(function () {});
  };
};

export const getMediaList = (params) => {
  return (dispatch) => {
    API.getMediaList().then(function (list) {
      dispatch(listMedia(list));
    });
  };
};

export const listChannelsForMediaUpload = (list) => ({
  type: LIST_CHANNELS_FOR_MEDIA_UPLOAD,
  payload: {
    list: list,
  },
});

export const getChannelListForMediaUpload = () => {
  return (dispatch) => {
    API.getChannelListForMediaUpload().then(function (list) {
      dispatch(listChannelsForMediaUpload(list));
    });
  };
};

export const getMyChannelListForMediaUpload = () => {
  return (dispatch) => {
    API.getMyChannelListForMediaUpload().then(function (list) {
      dispatch(listMyChannelsForMediaUpload(list));
    });
  };
};

export const listMedia = (list) => ({
  type: LIST_MEDIA,
  payload: {
    list: list,
  },
});

export const listMediaplans = (list) => ({
  type: LIST_MEDIAPLANS,
  payload: {
    list: list,
  },
});

export const loadingStart = () => ({
  type: LOADING_START,
  payload: {},
});

export const newBrand = (forms) => {
  return (dispatch) => {
    API.newBrand(forms).then(function () {
      API.getMyBrands().then(function (brands) {
        NotificationManager.success("Бренд добавлен успешно!", "", 5000);
        dispatch(listMyBrands(brands));
      });
    });
  };
};

export const getMyBrands = () => {
  return (dispatch) => {
    API.getMyBrands().then(function (myBrands) {
      dispatch(listMyBrands(myBrands));
    });
  };
};

export const listMyBrands = (myBrands) => ({
  type: LIST_MY_BRANDS,
  payload: {
    myBrands: myBrands,
  },
});

export const getMyCategories = () => {
  return (dispatch) => {
    API.getMyCategories().then(function (myCategories) {
      dispatch(listMyCategories(myCategories));
    });
  };
};

export const listMyCategories = (myCategories) => ({
  type: LIST_MY_CATEGORIES,
  payload: {
    myCategories: myCategories,
  },
});

export const getCategories = () => {
  return (dispatch) => {
    API.getCategories().then(function (categories) {
      dispatch(listCategories(categories));
    });
  };
};

export const listCategories = (categories) => ({
  type: LIST_CATEGORIES,
  payload: {
    categories: categories,
  },
});
