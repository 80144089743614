import React from "react";
import { Field, reduxForm } from "redux-form";
import { Card, Col, Row, CardHeader, CardBody, Button, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import ToggleDisplay from "react-toggle-display";

import { renderField } from "../../components/renderField";

class ProfileForm extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
    };
  }

  componentWillReceiveProps(props) {
    delete props.data["certificate"];
    this.setState({
      data: props.data,
    });
  }

  submit() {
    this.props.updateProfile(this.state.data);
  }

  render() {
    return (
      <div className="pt-3">
        <Card>
          <CardHeader>Редактирование профиля</CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <Row>
                  <Col>
                    <Field
                      name="first_name"
                      type="text"
                      label="Имя"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.first_name = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                      error={this.state.first_name}
                      defaultValue={this.state.data.first_name}
                      component={renderField}
                      placeholder="Имя"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="last_name"
                      type="text"
                      label="Фамилия"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.last_name = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                      error={this.state.last_name}
                      defaultValue={this.state.data.last_name}
                      component={renderField}
                      placeholder="Фамилия"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="email"
                      type="email"
                      label="Email"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.email = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                      error={this.state.email}
                      component={renderField}
                      defaultValue={this.state.data.email}
                      placeholder="Ваш Email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="pb-3">
                    <Label>Телефон</Label>
                    <MaskedInput
                      mask={[
                        "+",
                        /\d/,
                        " ",
                        " ",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        " ",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      className="form-control"
                      placeholder="Телефон"
                      guide={false}
                      defaultValue={this.state.data.mobile_phone}
                      id="phone-input-id"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.mobile_phone = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="company"
                      type="text"
                      label="Компания (юридическое лицо)"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.company = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                      error={this.state.company}
                      defaultValue={this.state.data.company}
                      component={renderField}
                      placeholder=""
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="contract_date"
                      type="text"
                      label="Дата договора"
                      error={this.state.contract_date}
                      component={renderField}
                      disabled={true}
                      defaultValue={this.state.data.contract_date}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="contract_id"
                      type="text"
                      label="Номер договора"
                      error={this.state.contract_id}
                      component={renderField}
                      disabled={true}
                      defaultValue={this.state.data.contract_id}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="signature"
                      type="text"
                      label="ФИО руководителя"
                      onChange={(e) => {
                        var data = this.state.data;
                        data.signature = e.target.value;
                        this.setState({
                          data: data,
                        });
                      }}
                      error={this.state.signature}
                      defaultValue={this.state.data.signature}
                      component={renderField}
                      placeholder="Фамилия, имя, отчество руководителя"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                {this.state.data.get_country ? (
                  <Row>
                    <Col>
                      <Label>Страна резидентства</Label>
                      <Select
                        className="pb-2"
                        placeholder="Страна резидентства"
                        aria-label="Страна резидентства"
                        aria-labelledby="Страна резидентства"
                        noOptionsMessage={(inputValue) => "Нет результатов"}
                        defaultValue={this.state.data.get_country}
                        options={this.props.countries}
                        onChange={(event) => {
                          var data = this.state.data;
                          data.country = event.value;
                          this.setState({
                            data: data,
                          });
                          this.props.updateProfile(data);
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}
                <Row form>
                  <Col md={5}>
                    <FormGroup>
                      <Field
                        name="city"
                        type="text"
                        label="Город"
                        onChange={(e) => {
                          var data = this.state.data;
                          data.city = e.target.value;
                          this.setState({
                            data: data,
                          });
                        }}
                        error={this.state.city}
                        defaultValue={this.state.data.city}
                        component={renderField}
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Field
                        name="region"
                        type="text"
                        label="Область"
                        onChange={(e) => {
                          var data = this.state.data;
                          data.region = e.target.value;
                          this.setState({
                            data: data,
                          });
                        }}
                        error={this.state.region}
                        defaultValue={this.state.data.region}
                        component={renderField}
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Field
                        name="zip"
                        type="text"
                        label="Индекс"
                        onChange={(e) => {
                          var data = this.state.data;
                          data.zip = e.target.value;
                          this.setState({
                            data: data,
                          });
                        }}
                        error={this.state.zip}
                        defaultValue={this.state.data.zip}
                        component={renderField}
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field
                        name="address"
                        type="text"
                        label="Юридический адрес"
                        onChange={(e) => {
                          var data = this.state.data;
                          data.address = e.target.value;
                          this.setState({
                            data: data,
                          });
                        }}
                        error={this.state.address}
                        defaultValue={this.state.data.address}
                        component={renderField}
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                    {this.props.userBalance !== null && (
                      <div className="pb-3">
                        <b>Мой баланс:</b> {this.props.userBalance} KZT
                      </div>
                    )}
                  </Col>
                </Row>
                <ToggleDisplay show={this.state.data.country > 1}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <div className="pb-3">
                          <b>Cертификация резидентства:</b>
                        </div>
                        <div>
                          <input
                            id={`cert-1`}
                            placeholder="Cертификация резидентства"
                            type="file"
                            style={{
                              display: "none",
                            }}
                            onChange={(event) => {
                              var forms = {};
                              forms["certificate"] = event.target.files[0];
                              this.props.updateCertificate(forms);
                            }}
                          />
                          <label className="badge badge-primary cursor p-2" for={`cert-1`}>
                            Добавить сертификацию резидентства
                          </label>
                        </div>
                        <div>
                          <a target="_blank" href={this.props.data.certificate}>
                            {this.props.data.get_certificate}
                          </a>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </ToggleDisplay>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <hr />
        <Row className="text-center">
          <Col>
            <Button size="lg" onClick={this.submit.bind(this)} color="primary">
              Обновить данные профайла
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default reduxForm({
  form: "profileForm",
})(ProfileForm);
