import { LIST_EDITOR_DATA, LOADING_START } from "./types";

import API from "../api/list";

export const getEditorData = (mediaplan, weekend, weekdays, search, rate, only_sales) => {
  return (dispatch) => {
    API.editorData(mediaplan, weekend, weekdays, search, rate, only_sales).then(function (list) {
      dispatch(listEditorData(list));
    });
  };
};

export const listEditorData = (list) => ({
  type: LIST_EDITOR_DATA,
  payload: {
    list: list,
  },
});

export const loadingStart = () => ({
  type: LOADING_START,
  payload: {},
});
