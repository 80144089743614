import { LIST_MONTHS_AND_CHANNELS } from "../actions/types";

const initialState = {
  loading: false,
  monthsAndChannels: [],
};

export default function airtimeReportsReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_MONTHS_AND_CHANNELS:
      return {
        ...state,
        loading: false,
        monthsAndChannels: action.payload.list,
      };
    default:
      return state;
  }
}
