import { connect } from "react-redux";
import Header from "../components/header";
import { getUser, returnCallFormSubmit } from "../actions/users";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => {
      return dispatch(getUser());
    },
    returnCallFormSubmit: (form, callback) => {
      return dispatch(returnCallFormSubmit(form, callback));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
