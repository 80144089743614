import React from "react";

import ContractForm from "../screens/forms/contract";

export default class Contract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        signature: "",
        company: "",
      },
    };
  }
  s;

  componentDidMount() {
    this.props.onGetCountries();
  }

  updateProfile(forms) {
    this.props.onUpdateProfile(forms);
  }

  render() {
    return (
      <div>
        <ContractForm
          data={this.props.user}
          countries={this.props.countries}
          updateProfile={this.updateProfile.bind(this)}
        />
      </div>
    );
  }
}
