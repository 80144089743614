import React from "react";
import { Card, CardHeader, CardBody, FormGroup, CardFooter, Button, Input } from "reactstrap";

export default class Steps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.onGetInternetChannels();
  }

  componentWillReceiveProps(nextProps) {}

  done() {
    this.props.onAddMediaplan({
      channel: this.state.channel.id,
      region: this.state.channel.region,
      media_type: "internet",
    });
  }

  render() {
    return (
      <Card className="mb-5">
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-md-6" style={{ paddingRight: 0, width: "auto", maxWidth: "none", flexBasis: "auto" }}>
            <FormGroup>
              <CardHeader>Выберите Интернет-площадку:</CardHeader>
              <CardBody>
                {(this.props.internetChannels || []).map((item, index) => {
                  const id = `select_channel_${item.id}`;
                  return (
                    <div
                      className="form-check"
                      style={{
                        paddingLeft: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <Input
                        id={id}
                        onChange={() => {
                          this.setState({
                            channel: item,
                          });
                        }}
                        checked={(this.state.channel || {}).id == item.id}
                        type="radio"
                        name="channel"
                        key={index}
                      />
                      <label className="form-check-label" htmlFor={id}>
                        {item.full_name} {item.get_internet_resources.length === 0 && <em>(нет ресурсов)</em>}
                      </label>
                    </div>
                  );
                }, this)}
              </CardBody>
            </FormGroup>
          </div>
          <div
            className="col-md-6"
            style={{ paddingLeft: 0, flex: 1, width: "auto", maxWidth: "none", flexBasis: "auto" }}
          >
            <div className="form-group" style={{ marginBottom: 0 }}>
              <CardHeader className="border-top">&nbsp;</CardHeader>
              <CardBody>
                <div></div>
              </CardBody>
            </div>
          </div>
        </div>
        <CardFooter className="text-center">
          <Button
            color="primary"
            size="lg"
            disabled={!this.state.channel || !this.state.channel.get_internet_resources.length}
            onClick={this.done.bind(this)}
          >
            <span>Создать медиаплан</span>
          </Button>
        </CardFooter>
      </Card>
    );
  }
}
