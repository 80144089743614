import { connect } from "react-redux";
import Mediaplans from "../components/mediaplans";
import { getMediaPlans, doMediaplanCheck } from "../actions/mediaplans";
import { deleteMediaplan } from "../actions/playlists";

const mapDispatchToProps = (dispatch) => {
  return {
    doMediaplanCheck: (callback) => {
      return dispatch(doMediaplanCheck(callback));
    },
    onListMediaplans: () => {
      return dispatch(getMediaPlans());
    },
    onDeleteMediaplan: (uuid) => {
      return dispatch(deleteMediaplan(uuid));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    mediaplans: state.mediaplans.list,
    loading: state.mediaplans.loading,
    shim_text: state.mediaplans.shim_text,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mediaplans);
