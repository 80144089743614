import { connect } from "react-redux";
import OnBoard from "../screens/steps/onboard";
import { getConfig } from "../actions/onboard";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetConfig: () => {
      return dispatch(getConfig());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    types: state.steps.types,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoard);
