// Notifications which are to be "consumed" after full page reload

import { reactLocalStorage } from "reactjs-localstorage";

export const consumeNotificationStack = () => {
  const items = reactLocalStorage.getObject('notificationStack').items || [];

  reactLocalStorage.remove('notificationStack');
  return items;
};

export const addNotificationToStack = (notification) => {
  const items = reactLocalStorage.getObject('notificationStack').items || [];
  items.push(notification);
  reactLocalStorage.setObject('notificationStack', { items: items });
};
