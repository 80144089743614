import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from "uuid";
import update, { extend } from "immutability-helper";
import axios from "axios";
import moment from "moment";

import Select, { components as reactSelectComponents } from "react-select";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

import classd from "classd";

import { verifyRecaptcha } from "../recaptcha";

export const orderBy = function (arr, selector, desc = false) {
  return [...arr].sort((a, b) => {
    a = selector(a);
    b = selector(b);

    if (a == b) return 0;
    return (desc ? a > b : a < b) ? -1 : 1;
  });
};

export function useApi(url, ...params) {
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      // TODO
      // console.log("will reload");
      setLoading(true);
      setResult(null);
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //Authorization: "Token " + reactLocalStorage.get("token"),
        },
      });
      setResult(await resp.json());
      setLoading(false);
    })();
  }, [url, ...params]);

  return [result, loading, setResult];
}

extend("$auto", function (value, object) {
  return object ? update(object, value) : update({}, value);
});
extend("$autoArray", function (value, object) {
  return object ? update(object, value) : update([], value);
});

const RequiredWrapper = ({ required, children }) =>
  required ? (
    <span style={{ fontWeight: "bold" }}>
      {children}&nbsp;<span className="text-danger">*</span>
    </span>
  ) : (
    children || null
  );

// GENERIC FORM START

const NON_HORIZONTAL_FIELDS = ["TextareaField"];

let formComponents, FormComponent;

const FieldLabel = ({ definition, id, context, children }) => {
  const label = <RequiredWrapper required={definition.required}>{definition.label}</RequiredWrapper>;
  const labelPlacement = context?.labelPlacement;
  const LabelTag = context?.labelTag || definition.labelTag || "label";
  const forceLabelWidth = context?.forceLabelWidth;
  let labelProps = { style: definition?.labelProps || {} };
  if (forceLabelWidth) labelProps.style.width = forceLabelWidth;
  if (labelPlacement === "omit") {
    return (
      <LabelTag htmlFor={id} className={classd`text-dark`}>
        {children}
      </LabelTag>
    );
  }
  let actualLabelPlacement = labelPlacement;
  if (labelPlacement === "horizontalPlus") {
    if (NON_HORIZONTAL_FIELDS.includes(definition.type)) {
      actualLabelPlacement = null;
    } else {
      actualLabelPlacement = "horizontal";
    }
  }
  if (context.labelStyle) {
    labelProps = update(labelProps, { style: { $auto: { $merge: context.labelStyle } } });
  }
  if (actualLabelPlacement === "horizontal") {
    return (
      <LabelTag
        {...labelProps}
        htmlFor={id}
        className={classd`text-dark`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span style={{ lineHeight: "calc(1.5em + 0.75rem + 2px)", alignSelf: "flex-start" }}>
          {definition.remove_on_label ? <>{definition.remove_on_label} </> : null}
          {label}
        </span>
        <div style={{ flex: 1, marginLeft: 10 }}>{children}</div>
      </LabelTag>
    );
  }
  return (
    <LabelTag {...labelProps} htmlFor={id} className={classd`text-dark`}>
      <span>
        {definition.remove_on_label ? <>{definition.remove_on_label} </> : null}
        {label}
      </span>
      <div>{children}</div>
    </LabelTag>
  );
};

const HiddenField = ({ value, onChange, error, definition, context, onReset, path }) => {
  return <></>;
};

const BooleanField = ({ value, onChange, error, definition, context, onReset, path, disabled }) => {
  const id = "id_" + uuidv4();
  const { label } = definition;
  if (definition?.variant === "CheckButton") {
    return (
      <div className="w-100">
        <button
          type="button"
          className={classd`btn ${{
            "btn-sm mt-2": context.is_column,
          }} w-100 btn-${!value ? "outline-" : ""}secondary`}
          onClick={(e) => {
            onChange(!value);
            onReset(path);
          }}
          id={id}
        >
          <i className={classd`fa${value ? "" : "r"} fa-${value ? "check-" : ""}square`} /> {definition.label}
        </button>
        {error && <div className="invalid-feedback d-block">{error}</div>}
      </div>
    );
  }
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={!!value}
        onChange={(e) => {
          onChange(e.target.checked);
          onReset(path);
        }}
        id={id}
        disabled={!!disabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {definition.label}
      </label>
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
};

const TextField = ({ value, onChange, error, definition, context, onReset, path, disabled }) => {
  const id = "id_" + uuidv4();
  const { label } = definition;
  return (
    <FieldLabel definition={definition} id={id} context={context}>
      <input
        id={id}
        type={definition.subtype || "text"}
        className={classd`form-control ${{ "is-invalid": error }}`}
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value);
          onReset(path);
        }}
        placeholder={definition.placeholder || ""}
        style={context.style}
        disabled={!!disabled}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FieldLabel>
  );
};

const TextareaField = ({ value, onChange, error, definition, context, onReset, path }) => {
  const id = "id_" + uuidv4();
  const { label } = definition;
  return (
    <FieldLabel definition={definition} id={id} context={context}>
      <textarea
        id={id}
        type="text"
        className={classd`form-control ${{ "is-invalid": error }}`}
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value);
          onReset(path);
        }}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FieldLabel>
  );
};

const NumberField = ({ value, onChange, error, definition, context, onReset, path, topValue, activeConstraints }) => {
  const id = "id_" + uuidv4();
  const { label } = definition;
  const labelStyle = {};
  if (context.labelColor) {
    labelStyle.style = { color: context.labelColor };
  }
  const actualConstraints = {
    ...activeConstraints,
  };
  for (let k of ["min", "max"]) {
    if (typeof definition[k] === "number") {
      actualConstraints[k] = definition[k];
    }
  }
  return (
    <FieldLabel definition={definition} id={id} context={context}>
      <input
        id={id}
        type="number"
        className={classd`form-control ${{ "is-invalid": error }}`}
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value);
          onReset(path);
        }}
        {...actualConstraints}
        onBlur={(e) => {
          if (typeof actualConstraints?.max === "number" && value > actualConstraints?.max) {
            onChange(actualConstraints?.max);
            onReset(path);
          }
          if (typeof actualConstraints?.min === "number" && value < actualConstraints?.min) {
            onChange(actualConstraints?.min);
            onReset(path);
          }
        }}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FieldLabel>
  );
};

const DefinedField = ({ topValue, value, onChange, error, definition, context, onReset, path, current }) => {
  const id = "id_" + uuidv4();
  const { label } = definition;
  const labelStyle = {};
  if (context.labelColor) {
    labelStyle.style = { color: context.labelColor };
  }
  // TODO
  let newCurrent = definition.current;
  return (
    <>
      <FieldLabel definition={definition} context={context} />
      {definition.label && (
        <>
          <hr style={{ marginBottom: "1em" }} />
        </>
      )}
      <FormComponent
        topValue={topValue}
        context={{ ...context, ...definition.context }}
        value={value}
        onChange={(x) => {
          onChange(x);
          onReset(path);
        }}
        onReset={(_) => {}}
        path={[]}
        error={null}
        definition={{
          ...newCurrent,
          layout: newCurrent.layout || definition.layout,
          itemWrapper: definition.itemWrapper,
        }}
      />
      {error && <div className="invalid-feedback d-block">{error + ""}</div>}
    </>
  );
};

const DecimalField = ({ value, onChange, error, definition, context, onReset, path }) => {
  const id = "id_" + uuidv4();
  const { label } = definition;
  const labelStyle = {};
  if (context.labelColor) {
    labelStyle.style = { color: context.labelColor };
  }
  return (
    <FieldLabel definition={definition} id={id} context={context}>
      <input
        id={id}
        type="number"
        step="0.01"
        className={classd`form-control ${{ "is-invalid": error }}`}
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value);
          onReset(path);
        }}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FieldLabel>
  );
};

const parseDate = (x) => {
  if (!x) return null;
  const [d, m, y] = x.split(".").map((y) => parseInt(y));
  const result = new Date(y, m - 1, d);
  //console.log(`parse: Got ${x} ret ${result}`);
  return result;
};

const unparseDate = (x) => {
  if (!x) return null;
  const oneLeadingZero = (y) => (y <= 9 ? "0" + y : "" + y);
  const parts = [x.getDate(), x.getMonth() + 1, x.getFullYear()].map(oneLeadingZero);
  const result = parts.join(".");
  //console.log(`unparse: Got ${x} ret ${result}`);
  return result;
};

const parseMonth = (x) => {
  if (!x) return null;
  const [m, y] = x.split(".").map((y) => parseInt(y));
  const result = new Date(y, m - 1, 1);
  //console.log(`parse: Got ${x} ret ${result}`);
  return result;
};

const unparseMonth = (x) => {
  if (!x) return null;
  const oneLeadingZero = (y) => (y <= 9 ? "0" + y : "" + y);
  const parts = [x.getMonth() + 1, x.getFullYear()].map(oneLeadingZero);
  const result = parts.join(".");
  //console.log(`unparse: Got ${x} ret ${result}`);
  return result;
};

const selectCustomStyles = {};

const selectCustomComponents = {};

const SelectField = ({ value, onChange, error, definition, context, onReset, path }) => {
  const id = "id_" + uuidv4();
  const { label } = definition;
  let labelStyle = {};
  const inputStyle = {};
  if (context.labelColor) {
    labelStyle.style = { color: context.labelColor };
  }
  const applySortValue = (x) => {
    if (definition.sort_value_by) return orderBy(x, (i) => i[definition.sort_value_by]);
    return x;
  };
  const additionalProps = {
    styles: {
      control: (provided, state) => {
        const result = { ...provided };
        if (error) result.borderColor = "red";
        return result;
      },
      menu: (provided, state) => {
        const result = { ...provided };
        result.zIndex = 2; // intlTelInput glitch
        return result;
      },
    },
  };
  if (context.select_custom_styles) {
    additionalProps.styles = {
      ...additionalProps.styles,
      ...selectCustomStyles[context.select_custom_styles],
    };
    additionalProps.components = selectCustomComponents[context.select_custom_styles];
  }
  return (
    <FieldLabel definition={definition} id={id} context={context}>
      <div
        style={{
          ...(context.selectWidth ? { width: context.selectWidth } : {}),
        }}
      >
        <Select
          id={id}
          isClearable={!definition.multiple && !definition.required && !definition.nonCleanable}
          isMulti={!!definition.multiple}
          type="text"
          className={classd`${{ "is-invalid": error }}`}
          value={value || null}
          onChange={(x) => {
            onChange(applySortValue(x));
            onReset(path);
          }}
          placeholder={definition.placeholder}
          options={definition.options}
          style={inputStyle}
          isOptionDisabled={(option) => option.disabled}
          noOptionsMessage={() => "Нет вариантов"}
          {...additionalProps}
        />
      </div>
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FieldLabel>
  );
};

const FieldsBasicLayout = ({ definition, renderedFields }) => {
  return <>{renderedFields}</>;
};

let fieldsLayouts;

const IntoARow = ({ definition, renderedFields }) => {
  // TODO
  // console.log("renderedFields", renderedFields);
  return (
    <div className="row">
      {renderedFields.map((item, i) => {
        return (
          <div key={i} className="col" {...(definition?.extraProps?.[i] || {})}>
            {item}
          </div>
        );
      })}
    </div>
  );
};

const IntoARow2 = ({ children }) => {
  return <div style={{ display: "flex" }}>{children}</div>;
};

const FormRow = ({ definition, renderedFields, context }) => {
  return (
    <div className={classd`row`}>
      {renderedFields.map((f, i) => (
        <div key={i} className="col" style={{ flex: definition?.flex?.[i] }}>
          {f}
        </div>
      ))}
    </div>
  );
};

const RowCol = ({ definition, renderedFields, context }) => {
  return (
    <div className={classd`row`}>
      {renderedFields.map((f, i) => (
        <div key={i} className="col-md-6" style={{ flex: definition?.flex?.[i] }}>
          {f}
        </div>
      ))}
    </div>
  );
};

const Div = ({ children }) => {
  return <div style={{ margin: "1em 0" }}>{children}</div>;
};

fieldsLayouts = {
  IntoARow,
  IntoARow2,
  FormRow,
  RowCol,
  Div,
  JustChildren: ({ children }) => children,
};

const formConstraints = {
  from_to: (oldValue, newValue, { behaviour }) => {
    const { date_from, date_to } = { ...newValue };
    if (
      date_from &&
      date_to &&
      date_from.length === 10 &&
      date_to.length === 10 &&
      parseDate(date_to) < parseDate(date_from)
    ) {
      if (behaviour === "flip") {
        return { date_from: date_to, date_to: date_from };
      }
    }
    return newValue;
  },
};

const applyConstrants = (oldValue, newValue, constraints) => {
  let v = newValue;
  for (const constraint of constraints) {
    const f = formConstraints[constraint["type"]];
    v = f(oldValue, v, constraint);
  }
  return v;
};

const Fields = ({ topValue, definition, value, onChange, error, onReset, path, context, disabled, interceptor }) => {
  let Layout = FieldsBasicLayout;
  if (definition.layout) {
    Layout = fieldsLayouts[definition.layout];
  }
  let ItemWrapper = React.Fragment;
  if (definition.itemWrapper) {
    ItemWrapper = fieldsLayouts[definition.itemWrapper];
  }
  const renderedFields = definition.fields.map((child, i) => {
    const vvalue = !!child.k ? value?.[child.k] : value;
    const additionalProps = {};
    let imposed_value = void 0; // TODO use undefined
    if (child.type === "DefinedField" && child.master_field) {
      //console.log('trying to define', child.definitions, (value?.[child?.master_field] || {}).value);
      additionalProps.current = child.definitions[(value?.[child?.master_field] || {}).value] || {
        type: "Fields",
        fields: [],
      }; // TODO assumption
    } else if (child.impositions) {
      imposed_value = child?.impositions?.[(value?.[child.master_field] || {}).value]; // TODO assumption
    }
    const childOnChange =
      imposed_value !== void 0
        ? (_) => null
        : (v) => {
            const newValue = applyConstrants(
              value,
              { ...(value || {}), ...(!!child.k ? { [child.k]: v } : v) },
              definition.constraints || []
            );
            const wrapper = definition.dependencies || ((_, v) => v);
            return onChange(wrapper(value, newValue));
          };

    const childPath = [...path, ...(!!child.k ? [child.k] : [])];

    if (definition.optionsFromParent?.[child?.k]) {
      additionalProps.options = definition.optionsFromParent[child.k](value);
      const oldValue = value[child?.k];
      let newValue = null;
      for (const option of additionalProps.options) {
        if (definition?.optionsFromParentSelectAppropriate && !newValue) newValue = option;
        if (oldValue?.value === option?.value) newValue = option;
      }
      if (oldValue !== newValue && oldValue?.value !== newValue?.value) {
        childOnChange(newValue);
        onReset(childPath);
      }
    }

    return (
      <ItemWrapper key={i}>
        <FormComponent
          topValue={topValue}
          definition={{
            index: definition.index,
            parent: definition.parent,
            onChangeParent: definition.onChangeParent,
            ...child,
            ...additionalProps,
          }}
          value={imposed_value !== void 0 ? imposed_value : vvalue}
          error={!!child.k ? error?.[child.k] : error}
          context={{ ...context, ...definition.context, ...child.context }}
          onChange={childOnChange}
          onReset={onReset}
          path={childPath}
          disabled={imposed_value !== void 0 ? true : !!disabled}
        />
      </ItemWrapper>
    );
  });
  return <Layout {...{ definition, value, onChange, error, onReset, path, context }} renderedFields={renderedFields} />;
};

const FilterOfRelated = (props) => Fields({ ...props, type: "Fields" });

const DefaultWrapper = ({ children, addButton }) => (
  <div>
    {children}
    {addButton}
  </div>
);

const ForeignKeyListField = ({ definition, value, onChange, error, onReset, path, context }) => {
  const id = "id_" + uuidv4();
  const { label } = definition;
  const vvalue = value || [];
  const Wrapper = fieldsLayouts[definition.wrapper] || DefaultWrapper;
  return (
    <div>
      <Wrapper
        {...{ definition, value, onChange, error, onReset, path, context }}
        addButton={
          <button
            style={definition?.addButtonStyle || {}}
            type="button"
            className="btn-yellow"
            onClick={(_) => onChange([...vvalue, {}])}
          >
            <i className="fa fa-plus" /> Add {definition?.addWhat}
          </button>
        }
      >
        {vvalue.map((item, i) => {
          return (
            <FormComponent
              key={i}
              definition={{
                ...definition,
                type: "Fields",
                index: i,
                parent: vvalue,
                onChangeParent: onChange,
              }}
              value={item}
              error={(error || [])[i]}
              onChange={($set) => onChange(update(vvalue, { [i]: { $set } }))}
              context={{ ...context, ...definition.context }}
              onReset={onReset}
              path={[...path, i]}
            />
          );
        })}
      </Wrapper>
    </div>
  );
};

const FilterOptionsField = ({ definition, value, onChange, error, onReset, path, context }) => {
  const id = "id_" + uuidv4();
  const { label, filters_k } = definition;
  const Wrapper = fieldsLayouts[definition.wrapper] || React.Fragment;
  const options = definition.fields.map((f) => ({
    value: f.k,
    label: f.label,
  }));
  const filters_value = value[filters_k] || [];
  const fields_map = {};
  for (let field of definition.fields) {
    fields_map[field.k] = field;
  }
  const renderedFields = [];

  const cleanOfNonSelected = (d, newValue) => {
    const r = {};
    for (const [k, v] of Object.entries(d)) {
      if (options.map((f) => f.value).includes(k) && !newValue.map((f) => f.value).includes(k)) {
        // TODO
        // console.log("cleaning", k);
        r[k] = void 0;
      } else {
        // TODO
        // console.log("keeping", k);
        r[k] = v;
      }
    }
    return r;
  };

  let ItemWrapper = React.Fragment;
  if (definition.itemWrapper) {
    ItemWrapper = fieldsLayouts[definition.itemWrapper];
  }

  return (
    <div>
      <Wrapper {...{ definition, value, onChange, error, onReset, path, context }}>
        <label className={classd`text-dark`}>
          <span>{label || <>&nbsp;</>}</span>
          <div
            style={{
              margin: "0.6em 0",
              //...(context.selectWidth ? { width: context.selectWidth } : {}),
            }}
          >
            <ItemWrapper>
              <Select
                isMulti
                isClearable={false}
                options={options}
                value={filters_value}
                error={null}
                onChange={(newValue) =>
                  onChange({
                    ...cleanOfNonSelected(value, newValue),
                    [filters_k]: newValue,
                  })
                }
                context={null}
                onReset={(_) => _}
                path={[]}
                components={{
                  IndicatorSeparator: (_) => null,
                  ValueContainer: (_) => null,
                }}
                styles={{
                  menu: (provided, state) => {
                    const result = { ...provided };
                    result.width = 150;
                    return result;
                  },
                }}
                isDisabled={options.length === filters_value.length}
              />
            </ItemWrapper>
          </div>
        </label>
        {filters_value.map((selected, i) => {
          const item = fields_map[selected.value];
          return (
            <ItemWrapper key={item.k}>
              <FormComponent
                definition={{
                  ...item,
                  remove_on_label: (
                    <a
                      className="text-red"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        const newValue = filters_value.filter((f) => f.value !== item.k);
                        onChange({ ...cleanOfNonSelected(value, newValue), [filters_k]: newValue });
                      }}
                    >
                      ×
                    </a>
                  ),
                }}
                value={value[item.k]}
                error={error}
                onChange={($set) => onChange({ ...value, [item.k]: $set })}
                context={{ ...context, ...definition.context }}
                onReset={onReset}
                path={path}
                context={context}
              />
            </ItemWrapper>
          );
        })}
      </Wrapper>
    </div>
  );
};

let formDisplays;

formDisplays = {};

const TextDisplay = ({ definition, value }) => {
  if (definition.list_type?.value) {
    return (
      <FieldLabel {...{ definition }}>
        <ol style={{ listStyleType: definition.list_type.value }}>
          {definition.content.split("\n").map((line, i) => (
            <li style={{ padding: "5px 0" }} key={i}>
              {line}
            </li>
          ))}
        </ol>
      </FieldLabel>
    );
  }
  return (
    <FieldLabel {...{ definition }}>
      <div style={{ margin: "10px 0" }}>
        {definition.content.split("\n").map((line, i) => (
          <p style={{ padding: "5px 0" }} key={i}>
            {line}
          </p>
        ))}
      </div>
    </FieldLabel>
  );
};

const CustomDisplay = ({ definition, value }) => {
  let Widget = definition.widget;
  if (typeof Widget === "string") Widget = formDisplays[Widget];
  return <Widget definition={definition} value={value} />;
};

formComponents = {
  Fields,
  HiddenField,
  BooleanField,
  TextField,
  TextareaField,
  NumberField,
  DefinedField,
  DecimalField,
  SelectField,
  ForeignKeyListField,
  FilterOptionsField,
  FilterOfRelated,
  TextDisplay,
  CustomDisplay,
};
const formEmptyPred = {
  HiddenField: (_) => false,
  BooleanField: (x) => !x,
  SignatureField: (x) => !x,
  TextField: (x) => !x,
  CurrencyField: (x) => !x,
  PhoneField: (x) => !x,
  TextareaField: (x) => !x || !x.replace("\n", ""),
  DecimalField: (x) => !x,
  DateField: (x) => !x,
  NumberField: (x) => !x && x !== 0,
  SingleChoiceField: (x) => false, // TODO
  MultipleChoiceField: (x) => false, // TODO
  ContactField: (x) => false, // TODO
  FieldsChoiceField: (x) => false, // TODO
  DefinedField: (x) => false, // TODO
  SelectField: (x, d) => !x || (d.multiple && !x.length),
  FromToField: (x) => [void 0, null, ""].includes(x?.from) || [void 0, null, ""].includes(x?.to),
  ForeignKeyListField: (x) => !x || !x.length,
  AttachmentsField: (x) => false, // TODO
  ImageField: (x) => !x?.data,
  Image2Field: (x) => !x?.data,
  TextDisplay: (_) => false,
  ConfirmationField: (x) => !x,
  CustomDisplay: (_) => false,
  SwitchField: (_) => false,
  DateRangeField: (_) => false, // TODO
};
const formValidators = {
  maxLength: (s, { value }) =>
    (s + "").length >= value && `Length shouldn\'t exceed ${value} characters (now: ${(s + "").length})`,
  fromTo: (s, { value }, definition) => {
    const f = parseInt(s.from);
    const t = parseInt(s.to);
    if (f != s.from || t != s.to) return "Incorrect values";
    if (f > t) return "«From» can't be greater than «to»";
    if (typeof definition.min === "number") {
      if (f < definition.min) return `Minimal value — ${definition.min}`;
    }
    if (typeof definition.max === "number") {
      if (f < definition.max) return `Maximum value — ${definition.max}`;
    }
  },
  minNumber: (s, { value }, definition) => {
    const f = parseFloat(s);
    if (f < value) return `Минимальное значение — ${value}`;
  },
  maxNumber: (s, { value }, definition) => {
    const f = parseFloat(s);
    if (f > value) return `Максимальное значение — ${value}`;
  },
};

FormComponent = ({ topValue, definition, error, value, onChange, path, onReset, context = {}, disabled }) => {
  const Component = formComponents[definition.type];
  let activeConstraints = {};
  if (definition.getOwnConstraints) {
    activeConstraints = definition.getOwnConstraints(topValue);
  }
  if (!Component) throw new Error(`No field type ${JSON.stringify(definition)}`);
  return (
    <Component
      {...{
        activeConstraints,
        value,
        topValue,
        onChange,
        error,
        definition,
        context,
        path,
        onReset,
        disabled,
      }}
    />
  );
};

let validateDefinition, definitionIsInvalid;

// runners
const validatorsValidatorRunner = (definition, value) => {
  const { type, required, validators } = definition;
  if (required && formEmptyPred[type](value, definition)) {
    return "Это поле обязательно";
  } else {
    for (const validator of validators || []) {
      const validatorError = formValidators[validator.type](value, validator, definition);
      if (validatorError) {
        return validatorError;
      }
    }
  }
};

const formValidatorRunners = {
  Fields: (definition, value) => {
    let result = {};
    for (const f of definition.fields) {
      if (f.k) {
        result[f.k] = validateDefinition(f, value?.[f.k]);
      } else {
        result = { ...result, ...validateDefinition(f, value) };
      }
    }
    return result;
  },
  ForeignKeyListField: (definition, value) => {
    if (definition.required && !value?.length) {
      return "Это поле обязательно";
    }
    return (value || []).map((v) => validateDefinition({ ...definition, type: "Fields" }, v));
  },
};

validateDefinition = (definition, state) => {
  const validatorRunner = formValidatorRunners[definition.type] || validatorsValidatorRunner;
  return validatorRunner(definition, state);
};

// checkers
const validatorsValidatorChecker = (definition, state) => !!state;

const formValidatorsCheckers = {
  Fields: (definition, error) => {
    for (const f of definition.fields) {
      if (definitionIsInvalid(f, !!f.k ? error?.[f.k] : error)) {
        return true;
      }
    }
  },
  ForeignKeyListField: (definition, error) => {
    if (typeof error === "string") return true;
    for (const e of error) {
      if (definitionIsInvalid({ ...definition, type: "Fields" }, e)) {
        return true;
      }
    }
  },
  TextDisplay: (definition, error) => {
    return false;
  },
  CustomDisplay: (definition, error) => {
    return false;
  },
  FieldsChoiceField: (definition, error) => {
    return false; // TODO
  },
  ContactField: (definition, error) => {
    return false; // TODO
  },
  DefinedField: (definition, error) => {
    return false; // TODO
  },
};

definitionIsInvalid = (definition, error) => {
  const validatorChecker = formValidatorsCheckers[definition.type] || validatorsValidatorChecker;
  return validatorChecker(definition, error);
};

const pathToUpdate = (path, value) => {
  let v = {},
    p,
    ee,
    vv_key;
  let r = v;
  for (const e of path) {
    const v_key = typeof e === "number" ? "$autoArray" : "$auto";
    v[v_key] = { [e]: {} };
    p = v;
    vv_key = v_key;
    ee = e;
    v = v[v_key][e];
  }
  p[vv_key][ee] = value;
  return r;
};

//const { moment } = window;

const serializeFilterValueForURLParmas = (value, { subtype, multiple }) => {
  if (subtype === "select") {
    let valueArray = multiple ? value || [] : value ? [value] : [];
    return valueArray.length && valueArray.map((v) => v.value + "").join(",");
  } else if (subtype === "date-range") {
    return value && value.map((x) => moment(x).format("YYYY-MM-DD")).join("--");
  }
};

const submitButtonWidgets = {};

const GenericForm = (props) => {
  const { fields, serverErrors, value, onChange, resetOnChange } = props;
  const [state, setState] = useState(value || {});
  useEffect(() => {
    // TODO
    // console.log("value changed from outside");
    if (resetOnChange && value) {
      setState(value);
      setErrors(null);
    }
  }, [value]);
  const onInternalChange = (newState) => {
    if (resetOnChange) {
      onChange(null);
    }
    setState(newState);
  };
  const [errors, setErrors] = useState(serverErrors || {});
  useEffect(() => {
    if (Object.keys(serverErrors || {}).length) {
      setErrors(serverErrors);
    }
  }, [serverErrors]);
  const onReset = (path) => {
    setErrors(update(errors, pathToUpdate(path, { $set: null })));
  };
  let SubmitButtonWidget = null;
  if (props.submitButtonWidget) SubmitButtonWidget = submitButtonWidgets[props.submitButtonWidget];
  const onSubmit = (onChange) => {
    const errors = validateDefinition(fields, state);
    setErrors(errors);
    if (!definitionIsInvalid(fields, errors)) {
      // ok
      onChange(state);
    } else {
      NotificationManager.error("Пожалуйста, исправьте ошибки ниже", "Ошибка");
      setTimeout(() => {
        try {
          document.getElementsByClassName("invalid-feedback d-block")[0].parentNode.scrollIntoViewIfNeeded();
        } catch (e) {
          console.warn(e);
        }
      }, 50);
    }
  };
  return (
    <div>
      {props.title && <h1>{props.title}</h1>}
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          if (props?.actionDisabled) return;
          onSubmit(onChange);
          return false;
        }}
      >
        <FormComponent
          definition={fields}
          value={state}
          topValue={state}
          onChange={onInternalChange}
          error={errors}
          onReset={onReset}
          path={[]}
        />
        {!props.noStandardSubmitButton && !props.submitButtonWidget && (
          <div>
            <div className="submit-button-container">
              <button disabled={!!props?.actionDisabled} className="btn btn-primary" type="submit">
                <i className="fas fa-arrow-circle-right" /> {props?.actionName || "Save"}
              </button>
            </div>
          </div>
        )}
        {SubmitButtonWidget && (
          <SubmitButtonWidget {...props} value={state} onChange={setState} onChangeTop={onChange} onSubmit={onSubmit} />
        )}
      </form>
    </div>
  );
};

const groupBy = (x, f) => x?.reduce((a, b) => update(a, { $auto: { [f(b)]: { $autoArray: { $push: [b] } } } }), {});

/*const useStepByStepForms = ({ steps }) => {
  const [state, setState] = useState(steps);
  const completed = false;

  return [state, setState, completed];
};*/

const findOption = (options, value) => {
  let result = null;

  const walk = (options) => {
    for (const option of options) {
      if (option.value && option.value === value) {
        result = option;
      } else {
        if (option.options) walk(option.options);
      }
    }
  };

  walk(options);
  return result;
};

const form2value = (definition, value) => {
  if (definition.type === "Fields") {
    let result = {};
    for (const field of definition.fields) {
      let current = field;
      if (field.type === "DefinedField" && field.master_field) {
        current = field.definitions[(value?.[field?.master_field] || {}).value] || { type: "Fields", fields: [] }; // TODO assumption
      }
      if (field.k) {
        result = { ...result, [field.k]: form2value(current, value[field.k]) };
      } else {
        result = { ...result, ...form2value(current, value) };
      }
    }
    return result;
  } else if (definition.type === "SelectField") {
    return value?.value || null;
  } else {
    return value;
  }
};

const MAX_DAYS_TO_SELECT = 31;

const Calculator = () => {
  const [channelsFromAPI, loading, setChannelsFromAPI] = useApi("/api/v1/grid/channels/");

  const spots_per_day_options = [
    { value: "3", label: "3" },
    { value: "5", label: "5" },
    { value: "7", label: "7" },
  ];

  const labels = { tv: "ТВ", radio: "Радио" };

  const channelsOptions = [
    ...["tv", "radio"].map((k) => ({
      k,
      label: labels[k],
      filter: (c) => c?.get_months?.length,
      definition: {
        type: "Fields",
        fields: [
          {
            type: "Fields",
            layout: "RowCol",
            fields: [
              {
                type: "NumberField",
                k: "day_count",
                label: "Кол-во дней",
                placeholder: "",
                required: true,
                getOwnConstraints: function (topValue) {
                  // TODO
                  // console.log("calc using topValue", topValue);
                  return { max: value2maxDayCount(topValue), min: 1 };
                },
              },
              {
                type: "SelectField",
                k: "spots_per_day",
                label: "Выходов в день",
                options: spots_per_day_options,
                placeholder: "",
                required: true,
                context: { selectWidth: null },
              },
            ],
          },
          {
            type: "NumberField",
            k: "duration",
            label: "Хронометраж (сек.)",
            placeholder: "",
            required: true,
            min: window.MIN_MEDIA_DURATION,
            max: window.MAX_MEDIA_DURATION,
            validators: [
              { type: "minNumber", value: window.MIN_MEDIA_DURATION },
              { type: "maxNumber", value: window.MAX_MEDIA_DURATION },
            ],
          },
        ],
      },
    })),
    /*{
      k: 'internet',
      label: 'Интернет',
      definition: {
        type: 'Fields',
        fields: [
          {
            type: 'NumberField',
            k: 'duration',
            label: 'Число баннеров',
            placeholder: '',
          },
        ]
      }
    },
    {
      k: 'outdoor_advertising',
      label: 'Наружная реклама',
      definition: {
        type: 'Fields',
        fields: [
          {
            type: 'NumberField',
            k: 'duration',
            label: 'Число скроллов',
            placeholder: '',
          },
        ]
      }
    },*/
  ];
  const definitions = {};
  for (const option of channelsOptions) {
    if (!option.options) option.options = [];
    option.options =
      channelsFromAPI
        ?.filter((c) => c.media_type === option.k)
        .filter(option.filter || ((x) => true))
        .map((c) => ({ ...c, value: c.id, label: c.short_name })) || [];
    for (const c of option.options) {
      definitions[c.value] = option.definition;
    }
  }

  const getCalculationResultFromServer = async (calculatorParams) => {
    const resp = await axios.post("/api/v1/feedback/calculator/", {
      recaptchaToken: await verifyRecaptcha(),
      ...form2value(definition, calculatorParams)
    });
    return resp.data;
  };

  const normalizeMMYYYY = (x) => {
    return x.length == 6 ? "0" + x : x;
  };

  const value2maxDayCount = (newValue) => {
    if (!newValue?.month?.value) return MAX_DAYS_TO_SELECT;
    const endOfMonth = moment(normalizeMMYYYY(newValue.month.value), "MM.YYYY").endOf("month");
    const current = moment(window.current_datetime);
    const days_left = endOfMonth.diff(current, "days");
    // TODO
    // console.log(
    //   `compare ${endOfMonth.endOf("month").format("YYYY-MM-DD HH:mm")} and decide ${current.format(
    //     "YYYY-MM-DD HH:mm"
    //   )} = ${days_left}. dl=`,
    //   newValue.month
    // );
    return Math.min(days_left, MAX_DAYS_TO_SELECT, newValue.month.days_left);
  };

  const dayCountDependencyFromMonth = (oldValue, newValue) => {
    if (
      (oldValue?.channel !== newValue?.channel || oldValue?.month !== newValue?.month) &&
      newValue?.channel &&
      newValue?.month
    ) {
      return update(newValue, {
        params: { $auto: { day_count: { $set: value2maxDayCount(newValue) } } },
      });
    }
    return newValue;
  };

  const definition = {
    type: "Fields",
    fields: [
      {
        type: "Fields",
        fields: [
          {
            type: "SelectField",
            k: "channel",
            label: "Канал",
            options: channelsOptions,
            placeholder: "",
            required: true,
          },
          {
            type: "SelectField",
            k: "month",
            label: "Месяц",
            options: [],
            placeholder: "",
            required: true,
          },
        ],
        optionsFromParent: {
          month: ({ channel }) => channel?.get_months?.map((m) => ({ ...m, label: m.value, value: m.key })) || [],
        },
        optionsFromParentSelectAppropriate: true,
      },
      {
        type: "DefinedField",
        k: "params",
        master_field: "channel",
        definitions,
      },
    ],
    dependencies: dayCountDependencyFromMonth,
    context: { labelPlacement: "horizontal" },
  };
  // data, onChange. fields -> specific definition
  // + extra params "action"
  const [exampleValue, setExampleValue] = useState("abc");
  const exampleChange = (newValue) => {
    setExampleValue(newValue.toUpperCase());
  };

  useEffect(() => {
    // TODO
    console.log("EFFECT");
  }, []);

  /*
    internalOnChange={(newValue) => {
      if (internalState.channel != newValue.channel) {
        setMonthsOptions(newValue.channel.get_months.map(m => ({label: m.value, value: m.key})));
      }
      return {month: null};
    }}
  */

  const [show, setShow] = useState(false);

  const initialCalculatorParamsDefaults = {
    params: {
      spots_per_day: findOption(spots_per_day_options, "5"),
      day_count: 5,
      duration: 15,
    },
  };
  const [initialCalculatorParams, setInitialCalculatorParams] = useState({});
  const [calculatorParams, setCalculatorParams] = useState(null);

  const [calculationResult, setCalculationResult] = useState(null);
  const [userData, setUserData] = useState(null);
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    const newParams = show
      ? {
          ...initialCalculatorParamsDefaults,
          channel: findOption(channelsOptions, show),
        }
      : {};
    setInitialCalculatorParams(newParams);
    setCalculatorParams(null);
  }, [show]);

  const handleCalculationResult = (resp) => {
    if (resp?.status === 'ok') { // captcha check fake OK response
      handleClose();
      window.Swal.fire(
        "Ошибка",
        "Пожалуйста, повторите запрос позже!",
        "warning"
      );
    } else {
      setCalculationResult(resp);
    }
  }

  useEffect(() => {
    if (calculatorParams) {
      // when form was submitted
      if (calculatorParams.channel && calculatorParams.month) {
        getCalculationResultFromServer(calculatorParams).then(handleCalculationResult);
      }
      setUserData({});
    } else {
      setCalculationResult(null);
    }
  }, [calculatorParams]);

  const handleClose = () => setShow(false);
  const handleShow = (channelId) => setShow(channelId || true);

  window.showCalculator = handleShow;

  useEffect(() => {
    (async () => {
      if (!Object.keys(userData || {}).length) return;
      const fullParams = form2value(definition, calculatorParams);
      // TODO
      // console.log("calculatorParams", calculatorParams, form2value(definition, calculatorParams));
      const params = { ...fullParams };
      delete params.channel;
      const resp = await axios.post("/api/v1/feedback/calculator-create/", {
        month: calculatorParams.month.value,
        ...userData,
        params,
        channel: fullParams.channel,
        recaptchaToken: await verifyRecaptcha(),
      });
      if (resp?.data?.errors) {
        setServerErrors(resp.data.errors);
      } else if (resp?.data?.status === 'ok') { // captcha check fake OK response
        handleClose();
        window.Swal.fire(
          "Ошибка",
          "Пожалуйста, повторите запрос позже!",
          "warning"
        );
      } else {
        // TODO
        // console.log("Response", resp);
        handleClose();
        window.Swal.fire(
          "Спасибо!",
          "Мы свяжемся с вами по указанному адресу электронной почты и отправим на него результат обработки вашего запроса.",
          "success"
        );
      }
    })();
  }, [userData]);

  return (
    <>
      <div>
        {/*<Button
    color="danger"
    onClick={_ => setShow(true)}
  >
    Click Me
  </Button>*/}
        <Modal isOpen={!!show} toggle={(_) => setShow(!show)} wrapClassName="bootstrap">
          <ModalHeader toggle={(_) => setShow(!show)}>Калькулятор стоимости рекламы</ModalHeader>
          <ModalBody>
            Используйте калькулятор для оценки стоимости
            <GenericForm
              fields={definition}
              value={initialCalculatorParams}
              onChange={(v) => {
                setCalculatorParams({ ...v });
              }}
              actionName="Рассчитать стоимость"
              resetOnChange
            />
            {calculationResult && !calculationResult.error && (
              <div className="container mt-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold", borderTop: "0 none" }}>Параметр</th>
                      <th style={{ fontWeight: "bold", borderTop: "0 none" }}>Значение</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Стоимость, KZT</th>
                      <th>{calculationResult.cost}</th>
                    </tr>
                    {calculationResult.grp && (
                      <tr>
                        <th>GRP</th>
                        <th>{calculationResult.grp}</th>
                      </tr>
                    )}
                    <tr>
                      <th>Средняя цена за минуту, KZT</th>
                      <th>{calculationResult.avgPricePerMinute}</th>
                    </tr>
                    {parseInt(calculatorParams?.params?.day_count) &&
                    parseInt(calculatorParams?.params?.day_count) !== calculationResult?.resulting.day_count ? (
                      <tr>
                        <th>Подобрано:</th>
                        <th>
                          {calculationResult?.resulting?.day_count} дней, {calculationResult.resulting?.spot_count}{" "}
                          выходов
                        </th>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            )}
            {calculationResult && calculationResult.error && (
              <div className="container mt-3">
                <table className="table text-danger text-center">
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "bold", borderTop: "0 none" }}>Ошибка</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>{calculationResult.error}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {!(calculationResult && calculationResult.error) && userData && (
              <>
                <div className="container">
                  <GenericForm
                    fields={{
                      type: "Fields",
                      fields: [
                        {
                          type: "Fields",
                          fields: [
                            {
                              k: "name",
                              label: "Имя",
                              type: "TextField",
                              required: true,
                            },
                          ],
                          layout: "FormRow",
                        },
                        {
                          type: "Fields",
                          fields: [
                            {
                              k: "email",
                              label: "E-mail",
                              type: "TextField",
                              required: true,
                            },
                          ],
                          layout: "FormRow",
                        },
                        {
                          type: "Fields",
                          fields: [
                            {
                              k: "phone",
                              label: "Телефон",
                              type: "TextField",
                              required: true,
                            },
                          ],
                          layout: "FormRow",
                        },
                      ],
                      context: {
                        labelStyle: {
                          width: "100%",
                        },
                        labelPlacement: "horizontal",
                      },
                    }}
                    serverErrors={serverErrors}
                    value={initialCalculatorParams}
                    onChange={(v) => {
                      setUserData({ ...v });
                    }}
                    actionName="Создать медиаплан с выбранными параметрами"
                    actionDisabled={!calculationResult}
                  />
                </div>
                <div className="d-lg-none" style={{ height: "65px" }} />
              </>
            )}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Calculator;
