import React from "react";
import { Container } from "reactstrap";
import { NotificationContainer } from "react-notifications";

import Header from "../containers/header";
import Outdoor from "../containers/outdoor";

export default class OutdoorScreen extends React.Component {
  render() {
    return (
      <div>
        <Container fluid={true}>
          <NotificationContainer />
          <Header check={true} />
          <Outdoor mediaplan={this.props.params.mediaplan} />
        </Container>
      </div>
    );
  }
}
