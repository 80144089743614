import React from "react";
import {
  UncontrolledTooltip,
  Badge,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ModalFooter,
  ListGroupItemHeading,
  ListGroupItem,
} from "reactstrap";
import Timeline from "react-calendar-timeline";
import Analytics from "../../screens/modal/analytics";
import Currency from "react-currency-formatter";
import moment from "moment";
import "moment/locale/ru";

import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: "btn btn-success",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false,
});

export default class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab2: false,
      step2: false,
      modal: false,
      item: {
        blocks: [],
      },
    };
  }

  componentDidMount() {
    this.setState({
      tab2: this.props.tab2,
      step2: this.props.step2,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tab2: nextProps.tab2,
      step2: nextProps.step2,
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    return (
      <div {...getItemProps(item.itemProps)}>
        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
          id={`tooltip-${item.id}`}
        >
          {itemContext.title}
        </div>
        <UncontrolledTooltip placement="right" target={`tooltip-${item.id}`}>
          {item.program_name}
          {this.props.list.mediaplan.media_type != "sponsorX" ? (
            <span>
              , <Currency quantity={item.price} currency="KZT" symbol="KZT" locale="ru_RU" decimal="," group=" " />
            </span>
          ) : null}
        </UncontrolledTooltip>
      </div>
    );
  };

  autoGenerateMediaplan(mediaplan, params) {
    const { spotsPerDay } = params;
    if (mediaplan.status_type !== 1) return;
    var mediaplan_not_empty = !!this.props.list.playlists.length;
    swalWithBootstrapButtons
      .fire({
        title: mediaplan_not_empty
          ? `Вы действительно хотите заместить медиаплан автоматическим (выходов в день: ${spotsPerDay})?`
          : `Автоматически будет сформирован медиаплан с заданным количеством выходов (выходов в день: ${spotsPerDay})`,
        text: "Эта операция необратима",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Да!",
        cancelButtonText: "Нет",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          this.props.autoGenerateMediaplan(mediaplan.id, params);
          swalWithBootstrapButtons.fire({
            title: "Подождите",
            text: "Идёт генерация медиаплана",
            allowEscapeKey: false,
            allowOutsideClick: false,
            //timer: 2000,
            onOpen: () => {
              Swal.showLoading();
            },
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire("Операция остановлена", "Изменения не были внесены", "error");
        }
      });
  }

  render() {
    return (
      <div className="card">
        <div className="card-header" id="heading1">
          <h5 className="mb-0">
            <Badge color={this.state.step2 ? "primary" : "danger"} pill>
              &#10148;
            </Badge>{" "}
            <button
              className={this.state.step2 ? "btn btn-link text-primary" : "btn btn-link text-danger"}
              type="button"
              data-toggle="collapse"
              data-target="#collapse2"
              aria-expanded="true"
              aria-controls="collapse2"
              onClick={() => {
                this.setState({
                  tab2: !this.state.tab2,
                });
              }}
            >
              {this.props.list.mediaplan.media_type != "sponsor" ? (
                <span>Создать медиаплан</span>
              ) : (
                <span>Создать спонсорский медиаплан</span>
              )}
            </button>
          </h5>
        </div>
        <div
          id="collapse2"
          className={!this.state.tab2 ? "collapse show" : "collapse"}
          aria-labelledby="heading2"
          data-parent="#accordion2"
        >
          <div className="card-body">
            <p>
              Используя редактор выходов, укажите день и время выходов роликов.
              {this.props.list.mediaplan.media_type != "sponsor" ? (
                <span>
                  &nbsp; Как минимум 50% хронометража в каждом 6-часовом периоде должно быть на государственном языке
                  (например, казахский язык в Республике Казахстан).
                </span>
              ) : null}
            </p>
            <p>
              {this.props.list.mediaplan.status_type === 1 && (
                <div className="d-flex">
                  <a className="btn btn-sm btn-success" href={`/editor/${this.props.mediaplan}/`}>
                    Запустить редактор выходов
                  </a>
                </div>
              )}
            </p>
            {this.props.list.mediaplan.media_type != "sponsor" ? (
              <div>
                <p>
                  <strong>ИЛИ</strong> просто выберите количество выходов в день, используя готовый медиаплан.
                </p>
                <p>
                  {this.props.list.mediaplan.status_type === 1 && (
                    <div className="d-flex align-items-center">
                      <div className="mr-2 text-sm">Автомедиаплан:</div>
                      {/* <ButtonGroup> */}
                      <Button
                        color="danger"
                        size="sm"
                        outline={true}
                        href="#"
                        onClick={this.autoGenerateMediaplan.bind(this, this.props.list.mediaplan, { spotsPerDay: 3 })}
                      >
                        3 выхода в день
                      </Button>{" "}
                      <Button
                        color="danger"
                        size="sm"
                        outline={true}
                        href="#"
                        onClick={this.autoGenerateMediaplan.bind(this, this.props.list.mediaplan, { spotsPerDay: 5 })}
                      >
                        5 выходов в день
                      </Button>{" "}
                      <Button
                        color="danger"
                        size="sm"
                        outline={true}
                        href="#"
                        onClick={this.autoGenerateMediaplan.bind(this, this.props.list.mediaplan, { spotsPerDay: 7 })}
                      >
                        7 выходов в день
                      </Button>
                      {/* </ButtonGroup> */}
                    </div>
                  )}
                </p>
                <p>
                  <strong>Внимание!</strong> При использовании функции "Автомедиаплан" ниже ваше текущее расписание
                  выходов <strong>затирается</strong>, и вместо него формируется автомедиаплан. Убедитесь, что ваше
                  текущее расписание выходов вам не нужно.
                </p>
              </div>
            ) : null}
            <div className="pt-2 pb-1 timeline">
              <Timeline
                groups={this.props.list.groups}
                items={this.props.list.playlists}
                visibleTimeStart={moment(this.props.list.start_date)}
                visibleTimeEnd={moment(this.props.list.end_date)}
                sidebarWidth={60}
                itemRenderer={this.itemRenderer.bind(this)}
                sidebarContent={<p className="text-center"> Время</p>}
                onItemSelect={(item) => {
                  {
                    this.props.list.playlists.forEach((obj) => {
                      if (item == obj.id) {
                        this.setState({
                          item: obj,
                          modal: !this.state.modal,
                        });
                      }
                    }, this);
                  }
                }}
                onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
                  if (
                    visibleTimeStart < moment(this.props.list.start_date) &&
                    visibleTimeEnd > moment(this.props.list.end_date)
                  ) {
                    updateScrollCanvas(moment(this.props.list.start_date), moment(this.props.list.end_date));
                  } else if (visibleTimeStart < moment(this.props.list.start_date)) {
                    updateScrollCanvas(
                      moment(this.props.list.start_date),
                      moment(this.props.list.start_date) + (visibleTimeEnd - visibleTimeStart)
                    );
                  } else if (visibleTimeEnd > moment(this.props.list.end_date)) {
                    updateScrollCanvas(
                      moment(this.props.list.end_date) - (visibleTimeEnd - visibleTimeStart),
                      moment(this.props.list.end_date)
                    );
                  } else {
                    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
                  }
                }}
              />
            </div>
            <table class="table table-bordered">
              <tbody>
                <tr>
                  {this.props.list.mediaplan.media_type != "sponsor" ? (
                    <td>
                      <small>
                        <b>Общий хронометраж:</b>{" "}
                        {this.props.list.get_stats.duration1 + this.props.list.get_stats.duration2} сек
                      </small>
                    </td>
                  ) : null}
                  <td>
                    <small>
                      <b>Кол-во выходов:</b> {this.props.list.get_stats.count}
                    </small>
                  </td>
                  <td>
                    <small>
                      <b>Валюта/курс валюты:</b> {this.props.list.get_stats.currency}
                      {this.props.list.get_stats.exchange_rate != 1
                        ? `/${this.props.list.get_stats.exchange_rate}`
                        : ""}
                    </small>
                  </td>
                  {this.props.list.mediaplan.media_type != "sponsor" ? (
                    <td>
                      <small>
                        <b>GRP:</b> {this.props.list.get_stats.rating}
                      </small>
                    </td>
                  ) : null}
                  <td>
                    <b>ИТОГО:</b>{" "}
                    <Currency
                      quantity={this.props.list.get_stats.amount_to_pay}
                      currency={this.props.list.get_stats.currency}
                      symbol={this.props.list.get_stats.currency}
                      locale="ru_RU"
                      decimal=","
                      group=" "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <ButtonGroup>
              <Button
                color="primary"
                size="sm"
                target="_blank"
                outline={true}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.getDownloadToken({
                    path: `/order/invoice/${this.props.mediaplan}/`,
                    blank: true,
                  });
                }}
              >
                Скачать PDF файл
              </Button>
              {this.props.list.mediaplan.get_analytics.show_button > 0 ? (
                <Analytics mediaplan={this.props.mediaplan} data={this.props.list.mediaplan.get_analytics} />
              ) : null}
              {this.props.list.mediaplan.postcampaign_report && (
                <Button
                  color="primary"
                  size="sm"
                  target="_blank"
                  outline={true}
                  href={this.props.list.mediaplan.postcampaign_report}
                >
                  Эфирная справка
                </Button>
              )}
              {this.props.list.mediaplan.act_of_acceptance && (
                <Button
                  color="primary"
                  size="sm"
                  target="_blank"
                  outline={true}
                  href={this.props.list.mediaplan.act_of_acceptance}
                >
                  Акт выполненных работ
                </Button>
              )}
            </ButtonGroup>
          </div>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle.bind(this)}>
          <ModalHeader toggle={this.toggle.bind(this)}>Выходы</ModalHeader>
          <ModalBody>
            {this.state.item.blocks.map((item) => {
              return (
                <ListGroup className="mb-2">
                  <ListGroupItemHeading className="text-right">{item.date}</ListGroupItemHeading>
                  <ListGroupItem>
                    <b>Программа:</b> {item.program_name}
                  </ListGroupItem>
                  <ListGroupItem>
                    <b>Рейтинг выхода:</b> {item.rating}
                  </ListGroupItem>
                  {this.props.list.mediaplan.media_type != "sponsor" ? (
                    <ListGroupItem>
                      <b>Язык:</b> {item.duration1 > 0 ? "государственный" : ""} {item.duration2 > 0 ? "другой" : ""}
                    </ListGroupItem>
                  ) : null}
                  {this.props.list.mediaplan.media_type != "sponsor" ? (
                    <ListGroupItem>
                      <b>Стоимость:</b>{" "}
                      <Currency
                        quantity={item.price}
                        currency="KZT"
                        symbol="KZT"
                        locale="ru_RU"
                        decimal=","
                        group=" "
                      />
                    </ListGroupItem>
                  ) : null}
                  {this.props.list.mediaplan.media_type != "sponsor" ? (
                    <ListGroupItem>
                      <b>Хронометраж:</b> {item.duration1 + item.duration2} сек
                    </ListGroupItem>
                  ) : null}
                </ListGroup>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button outline onClick={this.toggle.bind(this)} color="primary">
              Закрыть
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
