import { connect } from "react-redux";
import EditInternet from "../components/editInternet";
import { getMediaPlan } from "../actions/mediaplans";
import { getMediaList } from "../actions/media";
import { getRegions } from "../actions/createInternet";
import {
  saveInternetMediaPlan,
  calendarClick,
  targetingChange,
  setInternetMediaPlanMedia,
  addInternetMediaPlanMedia,
} from "../actions/internet";
import { getDownloadToken } from "../actions/reports";

const mapDispatchToProps = (dispatch) => {
  return {
    onGetMediaPlan: (mediaplan, callback) => {
      return dispatch(getMediaPlan(mediaplan, callback));
    },
    onGetRegions: () => {
      return dispatch(getRegions());
    },
    onGetMediaList: (params) => {
      return dispatch(getMediaList(params));
    },
    onSaveInternetMediaPlan: (mediaplan, data, callback, reFetch) => {
      return dispatch(saveInternetMediaPlan(mediaplan, data, callback, reFetch));
    },
    onCalendarClick: (mediaplan, day, callback) => {
      return dispatch(calendarClick(mediaplan, day, callback));
    },
    onTargetingChange: (mediaplan, targeting, callback) => {
      return dispatch(targetingChange(mediaplan, targeting, callback));
    },
    onSetMedia: (mediaplan, data, callback) => {
      return dispatch(setInternetMediaPlanMedia(mediaplan, data, callback));
    },
    onAddMedia: (data, callback) => {
      return dispatch(addInternetMediaPlanMedia(data, callback));
    },
    getDownloadToken: (params) => {
      return dispatch(getDownloadToken(params));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    mediaplans: state.mediaplans,
    all_media: state.media.list,
    regions: state.createInternet.regions,
    media_loading: state.editInternet.media_loading,
    media_loading_percent: state.editInternet.media_loading_percent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInternet);
