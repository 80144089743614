import React from "react";
import { NotificationManager } from "react-notifications";
import { consumeNotificationStack } from "./notificationStack";

window.NotificationManager = NotificationManager;

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const notifications = consumeNotificationStack();
    // console.log("Notifications read:", notifications);
    for (const notification of notifications) {
      NotificationManager[notification.type].apply(NotificationManager, notification.params);
    }
  }

  render() {
    return this.props.children;
  }
}
